import phone45deg from './src/img/phone45deg.png'
import React from "react"; 

import wpLogo from './src/img/wpLogo.png'
function FixedWpNum () {

   const wpstyle={
            position:"fixed",
            width:"60px",
            height:"60px",
            bottom:"40px",
            right:"30px",
            backgroundColor:"#25d366",
            color:"#FFF",
            borderRadius:"50px",
            textAlign:"center",
          fontSize:"30px",
            boxShadow: "2px 2px 3px #999",
            zIndex:"100"
    }
    const numstyle={
        
        position:"fixed",
        width:"60px",
        height:"60px",
        bottom:"116px",
        right:"30px",
        backgroundColor:"#1FA9FF",
        color:"#FFF",
        borderRadius:"50px",
        textAlign:"center",
      fontSize:"30px",
        boxShadow: "2px 2px 3px #999",
        zIndex:"100"
    
}
    return(
        <>
 <a href="#" onClick={()=>window.open("tel:*2121")} style={numstyle} target="_blank">
<img src={phone45deg} className=" mt-2" alt='phone' style={{width:"20px",height:"20px"}}></img>
</a>

<a href='https://wa.me/+994554142121' style={wpstyle} target="_blank">
<img src={wpLogo} className="  mt-1" alt='wp'></img>
</a>

        </>
    )
}

export default FixedWpNum