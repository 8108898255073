import axios from 'axios';
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import MainPage from '../MainPage'
import ChangePassword from '../Modals/ChangePassword'
import Authservice from "../../api/auth/index"
function ResetPassMainPage() {
  const [status,setStatus] = useState(false)
  const pathname = window.location.href;
  const token = pathname.split('?')[1].split('=')[1];
  Authservice.resetUserPass(token)
  .then(r=>setStatus(r.data))
  .catch(e=>setStatus(false))

  return (
   <>
    {
      status !==false ?  <><ChangePassword token={token}/><MainPage/></> : <><h1>Link Yanlisdir</h1><Link to={'/'}>Ana Sehife</Link></>
    }
   </>
  )
}

export default ResetPassMainPage