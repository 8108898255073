import React from 'react';
import { MainContext, useContext } from '../../Context';
import Login from '../../Modals/Login';
import MyAddress from './MyAddress';
import Signup from './../../Modals/Signup';
import ForgotEmail from './../../Modals/ForgotEmail';
import ChangePassword from '../../Modals/ChangePassword';
import EmailSent from '../../Modals/EmailSent';
import BookReceived from './../../Modals/BookRecieved';
import EditAddress from '../../Modals/EditAddress';
function MA_index() {
  const { register, login, forgotEmail, changePassword, emailSent, bookReceived, editAddressModal } = useContext(MainContext)
  return (
    <>
      {editAddressModal ? <><EditAddress /><MyAddress /></> : bookReceived ? <><BookReceived /><MyAddress /></> : emailSent ? <><EmailSent /><MyAddress /></> : changePassword ? <><ChangePassword /><MyAddress /></> : forgotEmail ? <><ForgotEmail /><MyAddress /></> : login ? <> <Login /><MyAddress /></> : register ? <><Signup /><MyAddress /></> : <MyAddress />}
    </>
  )
}

export default MA_index;
