import redMountain from './src/img/redMountain.png'
import React from "react";

import redPhone from './src/img/redPhone.png'
import redTruck from './src/img/redTruck.png'
import './src/css/redcard.css'
import { MainContext, useContext } from '../../Context';

const Card3 = () => {
    const { pageLangData } = useContext(MainContext)
    return (
        <div className='container'>
            <div className='row'>
                <div className=" d-flex flex-md-row flex-column  justify-content-center align-items-center my-5">
                    <div className="card-content col-md-4 d-flex flex-column justify-content-center align-items-center p-5">
                        <img src={redMountain} className='pb-3 red-img' alt='foto' />
                        <p className='card-head'>{pageLangData !== {} ? pageLangData["from-gedebey-mountains"] : "Gədəbəy dağlarından"}</p>
                        <p className='carddesc'>Gədəbəy dağlarından gələn saf və təbii suyu süfrənizlə bölüşürük.</p>
                    </div>
                    <div className="card-content col-md-4 d-flex flex-column justify-content-center align-items-center p-5 mx-md-4 my-4 my-md-0">
                        <img src={redPhone} className='pb-3 red-img' alt='foto' />
                        <p className='card-head'>{pageLangData !== {} ? pageLangData["call-center"] : "Çağrı mərkəzi"}</p>
                        <p className='carddesc'>*2121 Çağrı Mərkəmiz həftə içi 08:00-dan 18:30-a qədər, şənbə günləri 08:00-dan 18:00-a qədər, bazar günləri isə 09:30-dan 18:00-a qədər müraciətlərinizi qəbul etməyə hazırdır.</p>
                    </div>
                    <div className="card-content col-md-4 d-flex flex-column justify-content-center align-items-center  p-5">
                        <img src={redTruck} className='pb-3 red-img' alt='foto' />
                        <p className='card-head'>{pageLangData !== {} ? pageLangData["free-delivery"] : "Pulsuz çatdırılma"}</p>
                        <p className='carddesc'>Siz sifariş edin, biz pulsuz çatdıraq! Bir zənglə 24 saat ərzində suyunuzu qapınıza gətirək!</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Card3
