import React from 'react';
import { MainContext,useContext } from '../../Context';
import Login from '../../Modals/Login';
import Signup from './../../Modals/Signup';
import MyOldBooks from './MyOldBooks';
import ForgotEmail from './../../Modals/ForgotEmail';
import ChangePassword from '../../Modals/ChangePassword';
import EmailSent from '../../Modals/EmailSent';
import BookReceived from './../../Modals/BookRecieved';
function MOB_index() {
  const  {register,login,forgotEmail,changePassword,emailSent,bookReceived} =  useContext(MainContext)
  return (
      <>
        { bookReceived ? <><BookReceived/><MyOldBooks/></>:emailSent ? <><EmailSent/><MyOldBooks/></> :changePassword ? <><ChangePassword/><MyOldBooks/></> : forgotEmail ? <><ForgotEmail/><MyOldBooks/></>:login ? <> <Login/><MyOldBooks/></> : register ? <><Signup/><MyOldBooks/></> : <MyOldBooks/>}
      </>
  )
}

export default MOB_index;