
const MODALS = {
  BookReceived:require('../../Components/Modals/BookRecieved').default,
  ChangePassword:require('../../Components/Modals/ChangePassword').default,
  CompleteBook:require('../../Components/Modals/CompleteBook').default,
  EditAddress:require('../../Components/Modals/EditAddress').default,
  EditCreditCard:require('../../Components/Modals/EditCreditCard').default,
  EmailSent:require('../../Components/Modals/EmailSent').default,
  Login:require('../../Components/Modals/Login').default,
  NewAddress:require('../../Components/Modals/NewAddress').default,
  NewCreditCard:require('../../Components/Modals/NewCreditCard').default,
  Order:require('../../Components/Modals/Order').default,
  Signup:require('../../Components/Modals/Signup').default,
  AddBasketModal:require('../../Components/Modals/AddBasketModal').default,
  ForgotEmail:require('../../Components/Modals/ForgotEmail').default,
  MpBasketModal:require('../../Components/Modals/mpBasketModal/MpBasketModal').default
}
export default MODALS