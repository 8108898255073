import whitearr from './whitearr.png'
import React from "react"; 

import Pl from './PhoneLight.svg'
import Hand from './Hand.png'
import './src/style.css'
import { Link, useNavigate } from 'react-router-dom';
import { MainContext ,useContext} from '../../Context';

const MainPgPhone = () => {
    const navigate = useNavigate()
    const {pageLangData} = useContext(MainContext)
    return(
        <div className="text-light my-3 aaaa-17 ">
            <div className="bg-primary    rounded  container-lg mx-auto row" style={{minHeight:"500px",background:" linear-gradient(159.43deg, #0061B4 -5.17%, #80C4FF 111.29%)"}}>
               <div className='col-md col-12' style={{padding:"80px"}}>
                    <h3 className="my-5 pt-5">Evdə, ofisdə, həyatın hər anında!</h3>
                    <p className="my-5">{pageLangData !== {} ? pageLangData["order-slavyanka-we-will-deliver-to-your-address-in-just-24-hours"] : "Slavyanka sifariş edin, biz cəmi 24 saat ərzində ünvanınıza çatdırırıq."}</p>
                    <div className="d-flex mb-2">
                        <div className="d-flex   align-items-center px-2 " onClick={()=>{
                            window.open("tel:*2121")
                        }}>
                            <img src={Pl} className=' px-3 py-2 mr-2 ' style={{ background:" rgba(255, 255, 255, 0.1)"}} alt='phonefoto' />
                            <div className="font-weight-bold ml-2 text-light">*2121</div>
                        </div>
                        <div onClick={()=>navigate('/products')} className="d-flex ml-3 align-items-center  px-2 py-2 btn" style={{ background:" rgba(255, 255, 255, 0.1)"}}>
                           <div className='mr-4 text-light' >{pageLangData.products !== undefined ? pageLangData.products : "Məhsullar"}</div>
                            <img className='d-flex  justify-content-center' src={whitearr} height={12}  alt='fotod'/>
                        </div>
                    
                    
                    </div>
               </div>

                <div className='   rel-17 col-md col-12' >
                    <img src={Hand} height={"600px"} className='abs-17' alt='fotohtn' ></img>
                    
                </div>
            </div>

          
        </div>
    )
}

export default MainPgPhone