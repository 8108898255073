import React, {  useRef, useState } from 'react'
import { MainContext,useContext } from '../../Context'
import AuthService from '../../../api/auth/index'
function MyInfo() {
    const [mf, setMF] = useState('')
    const name = useRef()
    const surname = useRef()
    const email = useRef()
    const phoneNumber = useRef()
    const dateTime = useRef()
    const old_pass = useRef()
    const setErOrSccs= (msg,state)=>{
        setGlobalMsg(msg)
        setGlobalState(state)
        setDirectGlobalMsg(true)
    }
    const new_pass = useRef()
    const {setGlobalMsg,setDirectGlobalMsg,setGlobalState,cookie,pageLangData} = useContext(MainContext)
    const re_new_pass = useRef()
    const saveUserInfo = () => {
        const userInfo = {
            gender: mf,
            name: name.current.value,
            surname: surname.current.value,
            email: email.current.value,
            phone: `994${phoneNumber.current.value}`,
        }
        const changeConfigDATAS = {
            old_pass: old_pass.current.value,
            new_pass: new_pass.current.value,
            re_new_pass: re_new_pass.current.value,
        }
       
        if(changeConfigDATAS.new_pass !== changeConfigDATAS.re_new_pass){
            setErOrSccs(`Yeni şifrə'lər eyni deyil!`,0)
            return
        }else if(changeConfigDATAS.new_pass === changeConfigDATAS.old_pass){
            setErOrSccs(`'Köhnə şifrə' və 'Yeni şifrə' eyni ola bilməz!`,0)
            return
        }
        if(userInfo.gender!=='' && userInfo.name !== '' && userInfo.surname !== '' && userInfo.email !== '' && userInfo.phone !== '994'){
            AuthService.saveChanges(cookie,userInfo).then(response=>{
                
                AuthService.resetPass(cookie,{
                    'oldPassword':changeConfigDATAS.old_pass,
                    "newPassword":changeConfigDATAS.new_pass
                }).then(response=>setErOrSccs("Dəyişikliklər uğurla başa çatdı!",1))
                .catch(err=>err)
                

            })
            .catch(err=>(err))
        }else{
            setErOrSccs('Zəhmət olmazsa bütün məlumatları daxil etdiyinizden əmin olun.',0)
            return
        }
    }
    return (

        <div className='col-lg-7   col-md-7 offset-md-1 offset-sm-0 offset-lg-1 col-sm-12 col-12 mt-5 mt-sm-3 mt-lg-0 mt-md-0 --ci-m-content'>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <input autoComplete='off' type="text" disabled value={pageLangData['account-information']} className="col-12" />
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={name} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">{pageLangData.name}</span>
                </label>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={surname} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">{pageLangData.surname}</span>
                </label>
            </div>
            <div className="d-flex col-12 mt-3 justify-content-center">
                <div className="d-flex mf-input col-12">
                    <div className={mf === 'male' ? 'col-6 male mf-active' : 'col-6 male'}>
                        <label htmlFor="male" onClick={() => { setMF('0') }} >{pageLangData.male}</label>
                        <input autoComplete='off' className='' onClick={() => { setMF('1') }} type="radio" name="male-female" id="male" />
                    </div>
                    <div className={mf === 'female' ? 'col ml-3 female mf-active' : 'col ml-3 female'}>
                        <label onClick={() => { setMF('1') }} htmlFor="female">{pageLangData.female}</label>
                        <input autoComplete='off' onClick={() => { setMF('1') }} className='' type="radio" name="male-female" id="female" />
                    </div>
                </div>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={email} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">Email</span>
                </label>
            </div>
                <div className='col-12 d-flex justify-content-center pt-3 align-items-center pb-2'>
                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>
                        <span className='const' style={{ marginTop: 1 }}>+994</span>
                        <input ref={phoneNumber } onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}  maxLength={9} type='tel' placeholder='xx xxx xx xx' style={{ outline: 'none', border: 'none', height: 65, width: '100%', padding: 0, paddingLeft: 5, margin: 0 }}></input>

                    </div>

                </div>
            <div className='d-flex col-12 pt-3 justify-content-center'>
                <select ref={dateTime} name="" className='col-12' id="arra" >Nahardan əvvəl, 09:00-13:00
                    <option value="09-13">{pageLangData["after-dinner"]}, 09:00-13:00</option>
                    <option value="13-17">{pageLangData["before-dinner"]}, 13:00-17:00</option>
                </select></div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <input autoComplete='off' type="text" disabled value={pageLangData.password} className="col-12" />
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={old_pass} type="password" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">{pageLangData["previous-pasword"]}</span>
                </label>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={new_pass} type="password" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">{pageLangData.password2}</span>
                </label>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={re_new_pass} type="password" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">{pageLangData["repeat-the-new-password"]}</span>
                </label>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <button className="--contact-save-btn btn  btn-success col-12" onClick={saveUserInfo}>{pageLangData.save}</button>
            </div>
        </div>
    )
}

export default MyInfo
