import React, { useEffect, useState } from 'react';
import helpers  from '../../helpers/middleware/formatValues';

function NewCreditCard() {
    const [cardNum, setCardNum] = useState('')
    const format = helpers.format
    const [expiryDate, setExpiryDate] = useState('')
    const [cvv, setCvv] = useState('')
    return (
        <form className='mt-3'>
            <div className='na-text p-3'>Yeni kart əlavə edin</div>
            <div className='na-sub-text p-3'>Müvafiq xanalara məlumatları daxil edin</div>
            <div className='mt-3'>
                <div className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                    <input  onChange={(e) => {
                        format(e.target,4)
                        //  setCardNum(e.target.value) 
                         }} type="text" maxLength={19} id="inp" className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                    <span className="label">Kartın 16 rəqəmi</span>
                </label></div>
                <div className='d-sm-flex d-md-flex d-lg-flex d-block justify-content-between'>
                    <div className='col-md-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                        <input type="text"  onChange={(e) => {

                            format(e.target,2)

                        }} id="inp" maxLength={5} className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                        <span className="label">Son istifadə tarixi</span>
                    </label></div>
                    <div className='col-md-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                        <input value={cvv} type="text" id="inp" onChange={(e) => {

                            setCvv(e.target.value)

                        }} maxLength={3} className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                        <span className="label">CVV</span>
                    </label></div>
                </div>
                <div className="d-flex mt-3 pb-5 col-12 justify-content-center">
                    <button className="--naddress-edit-btn col-10 btn btn-success">Təsdiqlə</button>
                </div>
            </div>
        </form>
    )

}

export default NewCreditCard;
