import React, { useRef } from "react";
import { MainContext, useContext } from "../Context";
import Authservice from "../../api/auth";
import close from "../../assets/img/close.svg";
function Login() {
  const {
    login,
    register,
    forgotEmail,
    loginError,
    setLoginError,
    setForgotEmail,
    setLogin,
    setRegister,
    sessionId,
    setSessionId,
  } = useContext(MainContext);
  const access_email = useRef();
  const access_pass = useRef();
  const userLogin = async (e) => {
    // e.preventDefault()
    const loginPostData = {
      username: access_email.current.value,
      password: access_pass.current.value,
    };
    await Authservice.logIn(loginPostData)
      .then((response) => {
        Authservice.createCoookie(
          response.data.refreshToken,
          response.data.accessToken
        );
        Authservice.setUserSession({
          sessionId: Authservice.getSessionId(),
        });
        window.history.go("/dashboard");
      })
      .catch((err) => setLoginError(true));
  };
  return (
    <div className="modal">
      <section className="col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads">
        <div
          onClick={() => {
            setLogin(!login);
            setLoginError(false);
          }}
          className="close d-flex justify-content-center mt-3"
        >
          <div>
            <img alt="close" src={close} />
          </div>
        </div>
        {loginError ? (
          <div
            className="na-sub-text col-12 pt-3  d-flex justify-content-center pt-2"
            style={{ fontSize: 18, color: "#ed2939" }}
          >
            İstifadəçi adı və ya şifrə yanlışdır.
          </div>
        ) : (
          ""
        )}
        <div className="na-text pt-5  p-3">Şəxsi kabinetə giriş</div>
        <div className="d-flex col-12 pt-3 justify-content-center">
          <label htmlFor="inp" className="inp inp-ci">
            <input
              autoComplete="off"
              ref={access_email}
              type="text"
              id="inp"
              className="form-input col-12"
              placeholder="&nbsp;"
            />
            <span className="label">Email</span>
          </label>
        </div>
        <div className="d-flex col-12 pt-3 justify-content-center">
          <label htmlFor="inp" className="inp inp-ci">
            <input
              autoComplete="off"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  userLogin();
                }
              }}
              ref={access_pass}
              type="password"
              id="inp"
              className="form-input col-12"
              placeholder="&nbsp;"
            />
            <span className="label">Şifrəniz</span>
          </label>
        </div>
        <div className="d-flex mt-3  col-12 justify-content-center">
          <button
            className="--naddress-edit-btn col-10 btn btn-success"
            onClick={(e) => {
              userLogin(e);
            }}
          >
            Daxil Ol
          </button>
        </div>
        <div
          className="d-flex mt-5 pt-1 pb-2 col-12 justify-content-center fp-text"
          onClick={() => {
            // setRegister(!register)
            setForgotEmail(!forgotEmail);
          }}
        >
          Şifrəmi unutdum
        </div>
        <div className="d-flex col-12 pt-3 justify-content-center align-items-center pb-5">
          <div
            className="reg-text bord d-block d-sm-flex col-9  justify-content-center align-items-center"
            onClick={() => {
              setRegister(!register);
              setLogin(!login);
            }}
          >
            Hesabınız yoxdur?{" "}
            <span className="rgnote ml-sm-1">Qeydiyyatdan keçin.</span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
