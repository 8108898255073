
const MAINPAGECOMPONENTS = {
    Navbar:require('../../Components/MainPage/Navbar/Navbar').default,
    NavbarWhite:require('../../Components/MainPage/NavbarWhite/NavbarWhite').default,
    MainPgPhone:require('../../Components/MainPage/mainPgPhone/MainPgPhone').default,
    Card3:require('../../Components/MainPage/main3card/Card3').default,
    FixedWpNum:require('../../Components/MainPage/FixedWpNum/FixedWpNum').default,
    Slider:require('../../Components/MainPage/Slider/Slider').default,
    TopOrderSlider:require('../../Components/MainPage/TopOrderSlider/TopOrderSlider').default,
}
export default MAINPAGECOMPONENTS