import React from "react";
import Footer from "../../Partials/Footer";
import MODALS from "../../helpers/modals/Modals.js";
import MAINPAGECOMPONENTS from "../../helpers/mainpagecomponents/mainpagecomponents";
import { MainContext, useContext } from "../Context";
import MPProducts from "./MPProducts";
function MainPage() {
    const {Navbar,Card3,MainPgPhone,FixedWpNum,Slider,TopOrderSlider} = MAINPAGECOMPONENTS
    const {Order,AddBasketModal,Login,Signup,ForgotEmail,ChangePassword,EmailSent,BookReceived,MpBasketModal}= MODALS 
    const { emailSent, login, register, forgotEmail, changePassword, bookReceived,giveOrder,addBasketModal,basketModal } = useContext(MainContext)
    return (
        <div>
            {/* <NavbarWhite/> */}
            <div style={{
                background:
                    "linear-gradient(326.09deg, #E1ECF6 -0.1%, #043F90 97.67%)"
            }}>
                <Navbar />
                 {basketModal ? <MpBasketModal></MpBasketModal>  :""}
                <Slider />
            </div>
            {
               addBasketModal ? <AddBasketModal/> : giveOrder? <Order/> :   bookReceived ? <BookReceived /> : emailSent ? <EmailSent /> : changePassword ? <ChangePassword /> : forgotEmail ? <ForgotEmail /> : login ? <Login /> : register ? <Signup /> : ""
            }
            <Card3 />
            <TopOrderSlider />
            <MainPgPhone />
            <MPProducts/>
            <FixedWpNum />

            <Footer />
        </div>
    )
}
export default MainPage
