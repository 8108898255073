import React from 'react'
import banner0 from '../../assets/img/nbanner.png'
import banner from '../../assets/img/banner.jpg'
import './css/style.css'
import banner2 from "../../assets/img/lifestyle.png"
import banner3 from "../../assets/img/lemonade.png"
import Footer from '../../Partials/Footer'
import { MainContext, useContext } from '../Context'
import Card3 from '../MainPage/main3card/Card3'
import NavbarWhite from '../MainPage/NavbarWhite/NavbarWhite'
function About() {
    const { pageLangData, pageLanguage } = useContext(MainContext)
    const contentDifLangs = {
        ENG: {
            headers: {
                Aboutus: "Aboutus",
                Waters: "Waters",
                Benefits: "Benefits",
                Lemonades: "Lemonades",
                Delivery: "Delivery",
            },
            aboutus: `In the north of Gadabay region, in the heart of a number of mountains, there are well known famous springs. Since 2004, Slavyanka is bringing pure and natural water to our tables.
            The initial source of the water we provide was Novlu spring located in Slavyanka village of Gadabay. This is where the history of our name comes from. Over the years, the growing demand for pure and natural water has increased, and in 2010 we commissioned the Gadabay Mineral Waters Factory in the Zahmat village of Gadabay region, equipped with the latest technology aligned with world standards. Currently, the Slavyanka waters come from the water of 12 springs located in the Gadabay Mountains at an altitude of 1,500 meters above sea level. Water from springs collected in the factory, goes through purification procedures while preserving the mineral content and delivers to all regions of the country.`,
            water: "Slavyanka is pure and natural spring water. The physical and chemical composition of this water fully complies with the norms of drinking water. Low hardness is an advantage of this water, so this is light water. It does not contain any nitrite and nitrates. Our water is produced with ISO certification and meets all standards. One of the reasons why Gadabay waters differ in the market today is that they are rich in minerals. All products are made fully automated, without human intervention.",
            benefits: "The mineral content of spring water also determines its use for therapeutic purposes. Metaselic acid in Slavyanka and Gadabay waters is good for the kidneys and bile ducts. Due to its pH of 8.2, this water prevents obesity in humans and has an antioxidant effect.",
            lemonades: "Slavyanka produces delicious lemonades in 5 different varieties.What distinguishes these lemonades from others is that they are made from pure, natural spring water and use 100% natural sugar instead of chemical additives. Our lemonades are produced at our factory in Gadabay and delivered to all regions of the country.You can get our lemonades both in the markets and order them at home by calling * 2121.",
            delivery: "You can get rich with minerals, pure and natural Slavyanka water in all local markets. You can also order 19 liters of Slavyanka water for your home or office by calling *2121. There is no minimum order limit and it is delivered to the whole territory of Baku within 24 hours by big delivery team."
        },
        AZE: {
            headers: {
                Aboutus: "Haqqımızda",
                Waters: "Sular",
                Benefits: "Faydaları:",
                Lemonades: "Limonadlar",
                Delivery: "Çatdırılma"
            },
            aboutus: `Gədəbəy rayonunun şimalında, sıra dağların qoynunda dadı dillər əzbəri olan bulaqlar axır. “Slavyanka” 2004-cü ildən etibarən o saf və təbii bulaqların suyunu süfrələrinizə gətirir. İstehlakçılara təqdim etdiyimiz ilk suyun mənbəyi Gədəbəyin Slavyanka kəndində yerləşən “Novlu” bulağıdır. Adımızın tarixi məhz buradan gəlir. İllər keçdikcə saf və təbii suya artan tələbat artdı və biz 2010-cu ildə Gədəbəy rayonunun Zəhmət kəndində dünya standartlarına uyğun yeni texnologiyalarla təchiz olunmuş “Gədəbəy Mineral Suları” fabrikini istifadəyə verdik. Hal-hazırda “Slavyanka” suları dəniz səviyyəsindən 1500 metr yüksəklikdə Gədəbəy dağlarında yerləşən 12 bulağın suyundan formalaşır. Bulaqlardan gələn su fabrikdə toplanaraq, mineral tərkibi qorumaqla təmizləmə prosedurlarından keçir və ölkəmizin bütün regionlarına çatdırılır.`,
            water: `“Slavyanka” suyu saf və təbii bulaq suyudur. Bu suyun fiziki və kimyəvi tərkibi içməli suyun normalarına tam uyğundur. Üstünlüyü onun codluq göstəricilərinin aşağı olması, yəni yüngül su olmasıdır. Tərkibində nitrit və nitratlar yoxdur. "Slavyanka" suyunun üstünlüyü onun codluq göstəricilərinin aşağı olması, yəni yüngül su olmasıdır. Sularımız İSO sertifikatı ilə istehsal olunur və bütün standartlara cavab verir. Bu gün Gədəbəy sularının bazarda fərqlənməsinin bir səbəbi də tam minerallarla zəngin olmasıdır. Bütün məhsullar tam avtomatlaşdırılmış şəkildə, insan əli dəymədən istehsal olunur.`,
            benefits: "Bulaq suyunun minerallarla zəngin olması həm də onun müalicəvi məqsədlərlə istifadəsini təyin edir. “Slavyanka” və “Gədəbəy” sularının tərkibindəki metaselikat turşusu böyrək və öd yolları üçün faydalıdır. pH-ı 8,2 olduğuna görə bu su insanlarda piylənmənin qarşısını alır, antioksidant təsirə malikdir.",
            lemonades: "Slavyanka produces delicious lemonades in 5 different varieties.What distinguishes these lemonades from others is that they are made from pure, natural spring water and use 100% natural sugar instead of chemical additives. Our lemonades are produced at our factory in Gadabay and delivered to all regions of the country.You can get our lemonades both in the markets and order them at home by calling * 2121.",
            delivery: "You can get rich with minerals, pure and natural Slavyanka water in all local markets. You can also order 19 liters of Slavyanka water for your home or office by calling *2121. There is no minimum order limit and it is delivered to the whole territory of Baku within 24 hours by big delivery team."
        },
        RUS: {
            headers: {
                Aboutus: "О нас",
                Waters: "Воды",
                Benefits: "Преимущества",
                Lemonades: "Лимонады",
                Delivery: "Доставка"
            },
            aboutus: `На севере Кедабекского района, в сердце ряда гор, есть родники, знаменитые свои вкусом. «Славянка» поставляет на ваш стол эту чистую природную родниковую воду с 2004 года.
            Первым источником воды, которую мы предоставляем потребителям, является родник Новлу, расположенный в селе Славянка Гедабекского района. Отсюда и история нашего имени. С годами увеличивался спрос на чистую и природную воду, и в 2010 году мы сдали в эксплуатацию Кедабекский завод минеральных вод в селе Захмат Гедабекского района, оснащенный техникой, соответствующей мировым стандартам. В настоящее время воды Славянки формируются из воды 12 родников, расположенных в Гедабекских горах на высоте 1500 метров над уровнем моря. Вода из родников собирается на заводе, проходит процедуры очистки с сохранением минерального состава и доставляется во все регионы страны.`,
            water: "Вода «Славянка» — чистая и природная родниковая вода. Физико-химический состав этой воды полностью соответствует нормам питьевой воды. Ее преимуществом является низкая жесткость, т.е. легкая вода. Не содержит нитритов и нитратов. Наша вода производится с сертификатом ISO и соответствует всем стандартам. Одной из причин, по которой кадабекские воды отличаются сегодня на рынке, является то, что они богаты минералами. Все продукты производятся полностью автоматизированым путём, без прикосновения человека",
            benefits: `Тот факт, что родниковая вода богата минералами, также определяет ее использование в лечебных целях. Метацелиевая кислота, которая содержится в составе вод” Славянка “и” Кедабек " полезна для почек и желчевыводящих путей. Благодаря своему рН 8,2, эта вода предотвращает ожирение у людей, обладает антиоксидантным действием
            `,
            lemonades: `Лимонады”Славянка " выпускаются в 5 различных вариациях, вкусно дополняющих друг друга.
            Что отличает эти лимонады от других, так это то, что они сделаны из чистой, натуральной родниковой воды и в их производстве используются 100% натуральные сахара, а не химические подсластители. Наши лимонады производятся на нашем заводе в Кедабеке и поставляются во все регионы нашей страны.
            Вы можете купить наши лимонады как на рынках, так и заказать их на дом, позвонив по номеру *2121`,
            delivery: `Приобрести чистую, природную воду «Славянка» , обогощенную минералами, возможно во всех маркетах.  Также вы можете заказать 19 литров воды “Славянка” для дома или работы, позвонив по номеру *2121. Нет лимита на минимальное количество заказа и доставка осуществляется в течение 24 часов по всему Баку при помощи большой команды доставки.`
        }
    }
    return (
        <div>
            <NavbarWhite />
            <img src={banner} alt="banner" width={"100%"} className="mx-auto d-block  mb-5 pb-3" />
            <div className='container'>
                <div className="d-flex flex-column justify-content-center   ">

                    <h3 className="font-weight-bold mt-3" style={{ "fontSize:": "35px" }}>{pageLangData["about-us"]}:</h3>
                    <p style={{ "fontSize:": "20px" }}>{contentDifLangs[pageLanguage].aboutus}</p>
                </div>
                <img src={banner2} alt="banner" width={"100%"} className="mx-auto d-block my-3 mb-5 pb-3" />
                <div className="d-flex flex-lg-row  flex-column  p-0  justify-content-center my-4">
                    <div className='d-flex align-items-lg-end align-items-center'>
                        <img src={banner0} alt="banner" width={"100%"} className="mb-md-0 mb-3" />
                    </div>
                    <div className="mx-lg-3 mx-0" style={{ maxHeight: 300, overflowY: "auto" }}>
                        <h1 className="text-danger">{contentDifLangs[pageLanguage].headers.Waters}:</h1>
                        <p style={{ "fontSize:": "20px" }}>{contentDifLangs[pageLanguage].water}</p>
                    </div>
                </div>

                <div className="d-flex flex-lg-row  flex-column-reverse  p-0  justify-content-center my-4">

                    <div className="mx-lg-3 mx-0">
                        <h1 className="text-danger">{contentDifLangs[pageLanguage].headers.Benefits} </h1>
                        <p style={{ "fontSize:": "20px" }}>{contentDifLangs[pageLanguage].benefits}</p>
                    </div>
                    <div>
                        <img src={banner3} alt="banner" width={"100%"} className="mb-md-0 mb-3" />
                    </div>
                </div>
            </div>

            <Card3></Card3>
            <Footer></Footer>
        </div>
    )
}

export default About
