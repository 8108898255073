import React from 'react';
import { MainContext,useContext } from '../../Context';
import Login from '../../Modals/Login';
import Signup from './../../Modals/Signup';
import MyCardInfo from './MyCardInfo';
import ForgotEmail from './../../Modals/ForgotEmail';
import ChangePassword from '../../Modals/ChangePassword';
import EmailSent from '../../Modals/EmailSent';
import BookReceived from './../../Modals/BookRecieved';
import EditCreditCard from './../../Modals/EditCreditCard';
function MC_index() {
  const  {register,login,forgotEmail,changePassword,emailSent,bookReceived,editCreditCardModal,editAddressModal} =  useContext(MainContext)

  
  return (
    <>
    {  editCreditCardModal ? <><EditCreditCard/><MyCardInfo/></>: bookReceived ? <><BookReceived/><MyCardInfo/></>:emailSent ? <><EmailSent/><MyCardInfo/></> :changePassword ? <><ChangePassword/><MyCardInfo/></> : forgotEmail ? <><ForgotEmail/><MyCardInfo/></>:login ? <> <Login/><MyCardInfo/></> : register ? <><Signup/><MyCardInfo/></> : <MyCardInfo/>}
  </>
  )
}

export default MC_index;