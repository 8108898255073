const helpers = {
    format:function(ele,n){
        const x = this.format_Values(ele,n)
        if(ele.value.length<19 && n ===4){
            ele.value= ele.value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
            return true;
          }
          else if(ele.value.length<5 && n===2){
            ele.value= ele.value.replace(/\W/gi, '').replace(/(.{2})/g, '$1/');
            return true
          }
          else{
            return false;
          }
    },
    format_Values : function  (ele,n){
        let symbols = ["",'0','1','2','3','4','5','6','7','8','9']
        let max =''
        if(n === 4 ){
            symbols[0] = ' '
        }else if( n===2){
            symbols[0]='/'
        }
        for(let value of ele.value){
            if(symbols.indexOf(value)===-1){
                ele.value =max
                return false
            }
            max += value
        }
        return true
    }
}
export default helpers