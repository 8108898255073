import React, { useRef, useState } from 'react'
import { MainContext, useContext } from '../Context';
import Authservice from '../../api/auth'
import close from '../../assets/img/close.svg'
function PhoneInp() {
    const { phoneInp, setPhoneInp, registerPostData } = useContext(MainContext)
    let [counter,setCounter] = useState(150)
    
    const code = useRef()
    setInterval(()=>{
        if(counter !== 0){
            setCounter(counter-=1)
        }else{
            return setErr("Vaxt Bitdi")
        }
    },1000)
    const sendCode = async (e) => {
        if(counter === 0) {
            setErr("Vaxt bitdi.");
            
        }
            e.preventDefault()
            if(code.current.value.trim() === '' ){
                return false
            }
            registerPostData["otpCode"] = code.current.value

            await Authservice.signUp(registerPostData)
                .then(response => {
                    Authservice.createCoookie(response.data.refreshToken, response.data.accessToken)
                    window.history.go('/dashboard')
                })
                .catch(err => {
                    return err
                })
    }
    const [err, setErr] = useState("")
    return <div className='modal'>
        <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
            <div className='close d-flex justify-content-center mt-3' onClick={() => { setPhoneInp(!phoneInp) }}><div>
                <img alt='close' src={close}  /></div></div>
           
            <div className='na-text p-3'>Telefonunuza gələn kodu daxil edin.</div>
            {err ? <div className='na-sub-text col-12 d-flex justify-content-center' style={{ fontSize: 18, color: '#ed2939' }}>{err}</div> : ""}
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input onKeyDown={(e)=>{
                    if(e.key === 'Enter'){
                        return sendCode(e)
                    }
                }} autoComplete='off' ref={code} maxLength={6} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">xxxxxx</span>
                </label>
            </div>
            {counter > 0 ?   (
                <div className="d-flex mt-3 pb-3  col-12 justify-content-center">
                <button  className="--naddress-edit-btn  col-10 btn btn-success" onClick={(e) => {
                    sendCode(e)
                }}>Təsdiqlə</button>
            </div>
            ): ""}
                <div className='text-center pb-3' style={{textAlign:"center",color:"#ed2939",fontWeight:'bold'}}>Qalan vaxt : {counter}</div>
        </section>
    </div>;
}

export default PhoneInp