import React, { useEffect } from "react";
import Footer from "../../Partials/Footer";
import NavbarWhite from "./../MainPage/NavbarWhite/NavbarWhite";
import MA_index from "./MyAddress/MA_index";
import MI_index from "./MyInfo/MI_index";
import MB_index from "./MyBooks/MB_index";
import MOB_index from "./MyOldBooks/MOB_index";
import MC_index from "./MyCardInfo/MC_index";
import GuideArrow from "../../assets/img/GuideArrow.svg";
import AuthService from "../../api/auth/index";
import Dropdown from "../Custom/Dropdown";
import { MainContext, useContext } from "../Context";
function CabinetInfo() {
  const { cabinetAction, pageLangData, setCabinetAction, setDash } =
    useContext(MainContext);
  const logout = () => {
    AuthService.deleteCookie();
    AuthService.deleteSessionId();

    window.location.href = "/";
  };

  useEffect(() => {
    const cookieResp = AuthService.checkCookie();
    if (!cookieResp) {
      logout();
      setDash(null);
      window.location.href = "/";
      return false;
    }
    const cookies = AuthService.getCookie();

    if (cookies[0] === undefined || cookies[1] === undefined) {
      window.location.href = "/";
      setDash(null);
      return false;
    }
  }, []);

  const guideStates = [
    {
      slug: 1,
      name: "MyInfo",
      text: pageLangData["my-information"],
    },
    {
      slug: 2,
      name: "MyAddress",
      text: pageLangData["my-addresses"],
    },

    {
      slug: 3,
      name: "MyBooks",
      text: pageLangData["current-orders"],
    },
    {
      slug: 4,
      name: "OldBooks",
      text: pageLangData["previous-orders"],
    },
    // {
    //     slug: 5,
    //     name: 'CardInfo',
    //     text: 'Kart məlumatlarım',

    // },
    {
      slug: 6,
      name: "Logout",
      text: pageLangData["sign-out"],
    },
  ];
  return (
    <>
      <NavbarWhite />

      <div style={{ width: "100%", backgroundColor: "#fafafa" }}>
        <div className="--ci-main container  mt-5">
          {/* sm */}

          <div className="d-sm-block d-md-flex d-lg-flex d-block ">
            <aside className="guide col-12 col-sm-12 col-md-4 col-lg-3 pt-2 pb-3  d-flex justify-content-center align-items-center">
              <ul className="col-12 p-0 m-0 ">
                {guideStates.map((element) => {
                  return (
                    <li
                      key={element.slug}
                      onClick={() => {
                        element.slug === 6
                          ? logout()
                          : setCabinetAction(`${element.name}`);
                      }}
                      className={
                        cabinetAction === element.name
                          ? "guide-actions d-flex  justify-content-between guide-actions-active"
                          : element.name === "Logout"
                          ? "guide-actions d-flex justify-content-between l-out"
                          : "guide-actions d-flex justify-content-between"
                      }
                    >
                      {element.text}{" "}
                      <div className="guide-action-arrow">
                        {" "}
                        <img
                          alt=""
                          className="guideArrow"
                          src={GuideArrow}
                        />{" "}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </aside>
            <Dropdown pageAction={guideStates} page="Cabin
            
            etInfo" />

            {cabinetAction === "OldBooks" ? (
              <MOB_index />
            ) : cabinetAction === "MyInfo" ? (
              <MI_index />
            ) : cabinetAction === "MyAddress" ? (
              <MA_index />
            ) : cabinetAction === "MyBooks" ? (
              <MB_index />
            ) : cabinetAction === "CardInfo" ? (
              <MC_index />
            ) : (
              logout()
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CabinetInfo;
