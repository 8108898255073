import React,{useRef,useEffect,useState} from 'react'
import DropdownImg from '../../assets/img/DropDownArrow.svg'
import { MainContext,useContext } from '../Context'
function Dropdown({pageAction,page}) {
    const open = useRef(null)
    const {productAction,setProductAction,cabinetAction,setCabinetAction} = useContext(MainContext)
    const [stateName,setStateName ] = useState("Məlumatlarım")
    let [pageState,setPageState] = useState(pageAction[0].name)
    if(page === 'Products'){
        pageState = productAction
        setPageState = setProductAction
    }else {
        pageState = cabinetAction
        setPageState = setCabinetAction
    }
    const closeDropDown = () => {
        const sub_content = document.querySelectorAll('.dropdown-custom-sub-item')
        for (let x = 0; x < sub_content.length; x++) {
            sub_content[x].classList.toggle('sub-content')
        }
    }
    useEffect(() => {
        open.current.addEventListener('click', () => {
            closeDropDown()


        })
    }, [open])
  return (
    <div className='sub-ul'>
                            <div className="box">
                                <section className="col-12 mt-5 p-0">
                                    <div ref={open} className="dropdown-custom-item p-0 m-0 col-12">
                                        <span className="dropdown-custom-text">{stateName}</span>
                                        <img className="dropdown-custom-img" src={DropdownImg} alt="Drop" />
                                    </div>
                                    <div className="dropdown-sub-item col-12">
                                        {pageAction.map(item => {
                                            return (
                                                <div onClick={() => {
                                                    closeDropDown()
                                                    setPageState(item.name)
                                                    setStateName(item.text)
                                                }} key={item.slug} className='dropdown-custom-sub-item'>{item.text}</div>
                                            )
                                        })}

                                    </div>
                                </section>
                            </div>
                        </div>
  )
}

export default Dropdown