import React from 'react'
import {useContext, MainContext} from '../Context/index'
import BookReceived from '../Modals/BookRecieved'
import Payment from './Payment'
function PI_index() {
  const {bookReceived} = useContext(MainContext)
  return (
    <>
    {bookReceived ? <><BookReceived signed={false}/><Payment/></> : <Payment/>}
    </>
  )
}

export default PI_index