import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './Components/MainPage/index';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import CabinetInfo from './Components/CabinetInfo/CabinetInfo';
import Products from './Components/Products/Products';
import OneProduct from './Components/OneProductPage/OneProductPage'
import PI_index from './Components/Payment';
import Basket from './Components/Basket/Basket';
import İMAGES from './helpers/images/images';
import DashBoardModal from './Components/Modals/DashboardModals/DashBoardModal';
import { MainContext,useContext } from './Components/Context';
import ResetPassMainPage from './Components/ResetPass/ResetPassMainPage';
import Bill from './Components/Bill/Bill';
import ProductsAll from './Components/ProductsAll/ProductsAll';
function Pages() {
    const {dash} = useContext(MainContext)
    return (
        <BrowserRouter>

        <Routes>
            
            <Route path='/reset-password' element={<ResetPassMainPage/>} />
            <Route path='/p/payment-approve' element={<><MainPage/><DashBoardModal msj={"Sifariş qəbul olundu!"} imgX={İMAGES.close} img={İMAGES.success}/></>}></Route>
            <Route path='/p/payment-decline' element={<><MainPage/><DashBoardModal msj={"Sifariş qəbul olunmadı!"} imgX={İMAGES.close} img={İMAGES.Cancel}/></>}></Route>
            <Route path='/p/payment-cancel' element={<><MainPage/><DashBoardModal msj={"Sifariş ləğv edildi!"} imgX={İMAGES.close} img={İMAGES.Cancel}/></>}></Route>
            <Route exact path='/' element={<MainPage/>}></Route>
            <Route exact path='/bill/:cookie/:id' element={<Bill/>}/>
            <Route exact path='/home' element={<MainPage/>}></Route>
            <Route exact path='/contact' element={<Contact/>}></Route>
            <Route exact path='/dashboard' element={dash ? <CabinetInfo/> : <MainPage/>}></Route>
            <Route exact path='/about' element={<About/>}></Route>
            <Route exact path='/products' element={<Products/>}></Route>
            <Route exact path='/products/all' element={<ProductsAll/>}></Route>
            <Route exact path='/oneProduct/:id' element={<OneProduct/>}></Route>
            <Route exact path='/payment' element={<PI_index/>}></Route>
            <Route exact path ='/basket' element={<Basket/> }></Route>
        </Routes>
    </BrowserRouter>
    )
}

export default Pages
