import React, { useRef } from 'react'
import Footer from '../../Partials/Footer';
import NavbarWhite from '../MainPage/NavbarWhite/NavbarWhite';
import IMAGES from '../../helpers/images/images';
import MainPgPhone from '../MainPage/mainPgPhone/MainPgPhone';
import { MainContext, useContext } from '../Context';
import Authservice from '../../api/auth/index';
import "./contact.css"
import Swal from 'sweetalert2';

function Contact() {
    const { setGlobalSuccessMessage, setGlobalMsg, setDirectGlobalMsg, setGlobalState, pageLangData } = useContext(MainContext)
    const { Phone, Letter, Location } = IMAGES
    const c_name = useRef()
    const c_sname = useRef()
    const c_mobile = useRef ()
    const c_suggest = useRef()

    const sendSuggests = (e) => {
        e.preventDefault();
        const data =
        {
            "subject_id": 1,
            "name": c_name.current.value,
            "surname": c_sname.current.value,
            "mobile": `+994${c_mobile.current.value}`,
            "comment": c_suggest.current.value
        }
     
        if (data.name.trim() !== ""  && data.surname.trim() !== "" && data.mobile.trim() !== "+994" && data.mobile.length === 13 && data.comment.trim() !== "") {
           
             Authservice.addsuggest(data).then(resp => {
                if (resp.status === 200) {
                    c_name.current.value = '';
                    c_sname.current.value = '';
                    c_mobile.current.value = '';
                    c_suggest.current.value = '';
                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Mesajınız uğurla göndərildi',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    
                }                
            })
                .catch(Err => {
                    setGlobalState(0)
                    Swal.fire({
                        icon: 'error',
                        title: 'Server xətası.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    
                }
                )
        }
        
        else{
            setGlobalState(0)
                    Swal.fire({
                        icon: 'error',
                        title: 'Bütün xanaların doldurulduğundan əmin olun.',
                        showConfirmButton: false,
                        timer: 2000
                    })
        }

    }

    const contactTagArray = [
        {
            label: pageLangData.address,
            text: 'Bakı şəhəri, N.Nərimanov rayonu Ziya Bünyadov prospekti 20/61',
            img: Location,
            alt: 'Location'
        },
        {
            label: pageLangData.telephone,
            text: '+994 12 567 79 66  /  +994 55 515 21 21',
            img: Phone,
            alt: 'Phone'
        },
        {
            label: 'E-mail',
            text: 'info@slavyanka.az',
            img: Letter,
            alt: 'E-mail'
        }
    ]
    return (

        <>
            <div style={{ backgroundColor: '#Fff' }}>
                <NavbarWhite />
            </div>
            <section className='contact-info-container el-center'>
                <div>
                    <span className='contact-hd-text p-3 p-sm-0'>Bizimlə əlaqə</span>
                    <div className='d-sm-flex d-block mt-4' style={{ justifyContent: 'space-between' }}>
                        <div className='d-block d-sm-none p-2'>
                            <MainPgPhone />
                        </div>
                        <div className='contact-info p-0 m-0'>
                            {contactTagArray.map(tags => {
                                return (
                                    <div key={tags.text} className='d-sm-flex d-block'>
                                        <div className='info-img el-center'>
                                            <img src={tags.img} alt={tags.alt} />
                                        </div>
                                        <div className='info-text'>
                                            <label className='text-center'>{tags.label}</label>
                                            <div>
                                                {tags.text}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='col-sm-5 col-12'>
                            <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.155177061248!2d49.89738941539622!3d40.42756267936392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc177557e97e463e5!2zNDDCsDI1JzM5LjIiTiA0OcKwNTMnNTguNSJF!5e0!3m2!1str!2str!4v1650958637728!5m2!1str!2str" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>

                        </div>

                    </div>

                </div>

            </section>


            <main className='-sw'>
                <div className="sw-h-ts pt-5">
                    <p className='text-center -sw-h-text'>{pageLangData["suggestions-and-comments"]} </p>
                    <p className='text-center -sw-h-s-text'>{pageLangData["your-feedback-is-important-to-us"]} </p>
                </div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <label htmlFor="inp" className="inp ">
                        <input autoComplete='off' type="text" ref={c_name} id="inp" className="form-input" placeholder="&nbsp;" />
                        <span className="label">{pageLangData.name}</span>
                    </label>
                </div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <label htmlFor="inp" className="inp ">
                        <input autoComplete='off' type="text" id="inp" ref={c_sname} className="form-input" placeholder="&nbsp;" />
                        <span className="label">{pageLangData.surname}</span>
                    </label>
                </div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <div className='d-flex inp justify-content-center align-items-center' style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 60, borderRadius: 8, background: "white",}}>
                    <span className='const' style={{ marginTop: 1, marginLeft: 10}}>+994</span>
                        <input  maxLength={9} type='tel' placeholder='xxxxxxxxx'  ref={c_mobile} className="form-input"  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                        }}  style={{ outline: 'none', border: 'none', height: 58, padding: 0, paddingLeft: 5,}}></input>
                    </div>
                </div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <label style={{ height: 150,backgroundColor:'#fff' }} htmlFor="inp" className="inp ">
                        <input  autoComplete='off' name="suggests" type="text" id="inp" ref={c_suggest} className="form-input" placeholder="&nbsp;" />
                        <span className="label">{pageLangData["your-feedback-is-important-to-us"]}</span>
                    </label>
                </div>

                <div className="d-flex col-12 mt-3 justify-content-center" onClick={(e) => {
                    sendSuggests(e)
                }}>
                    <button className="btn text-center contact-send-btn">{pageLangData.send}</button>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Contact




