import { useRef, useState } from 'react';
import { MainContext, useContext } from '../Context';
import close from '../../assets/img/close.svg'
import Authservice from '../../api/auth'
function Signup() {
    const { register, setRegister, setRegisterPostData, phoneInp, setPhoneInp } = useContext(MainContext)
    const [signupErr, setSignupErr] = useState("")
    const register_email = useRef()
    const register_repass = useRef()
    const register_pass = useRef()
    const register_name = useRef()
    const register_surname = useRef()
    const register_phone = useRef()
    const signupTagData = [
        {
            slug: 1,
            ref: register_name,
            label: 'Ad',
            type: 'text',
            len: 20
        },
        {
            slug: 2,
            ref: register_surname,
            label: 'Soyad',
            type: 'text',
            len: 20
        },
        {
            slug: 3,
            ref: register_phone,
            label: 'Mobil nömrə',
            type: 'tel',
            len: 9
        },
        {
            slug: 4,
            ref: register_email,
            label: 'Email',
            type: 'email',
            len: 100
        },
        {
            slug: 5,
            ref: register_pass,
            label: 'Şifrəniz',
            type: 'password',
            len: 20
        },
        {
            slug: 6,
            ref: register_repass,
            label: 'Şifrəniz yenidən',
            type: 'password',
            len: 20
        },
    ]
    const userSignUP = async e => {
        if (e !== "sbmt") {
            e.preventDefault()

        }

        if (register_name.current.value.trim() !== "" && register_surname.current.value.trim() !== "" && register_phone.current.value.trim() !== "" && register_email.current.value.trim() !== "" && register_pass.current.value.trim() !== "" && register_repass.current.value.trim() !== "") {
            const num_symbols = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
            for (let c of register_phone.current.value.trim()) {
                if (num_symbols.indexOf(c) === -1) {
                    setSignupErr("Telefon nömrəsi sadəcə rəqəmlərdən ibarət olmalıdır.");
                    return false
                }
            }
            if (register_pass.current.value.trim().length < 8) {
                setSignupErr("Şifrə minimum 8 simvoldan ibarət olmalıdır.");
                return false
            }

            if (register_pass.current.value.trim() !== register_repass.current.value.trim()) {
                setSignupErr("Şifrələr eyni deyil!")
                return false
            }


            const registerPostData = {
                "name": register_name.current.value.trim(),
                "surname": register_surname.current.value.trim(),
                "mobileNumber": `994${register_phone.current.value.trim()}`,
                "username": register_email.current.value.trim(),
                "password": register_pass.current.value.trim(),
                "confirmPassword": register_repass.current.value.trim(),
                "gender": 1
            }

            if (registerPostData.name.length > 20) {
                setSignupErr("Ad maksimum 20 simvoldan ibarət olmalıdır")
                return false
            } else if (registerPostData.surname.length > 20) {
                setSignupErr("Soyad maksimum 20 simvoldan ibarət olmalıdır")
                return false
            }
            setRegisterPostData(registerPostData)
            await Authservice.signUp(registerPostData)
                .then(response => {

                })
                .catch(
                    async err => {
                        const errCode = err.response.data.errorCode
                        if (errCode === 103) {
                            setSignupErr("Email artıq alınmışdır.")
                        }
                        else if (errCode === 101) {
                            setSignupErr("Email doğru deyil!")
                        }

                        else if (errCode === 137 || errCode === 136) {
                            setRegister(!register)
                            setPhoneInp(!phoneInp)
                        }

                    }
                )
        }
        else {
            setSignupErr("Bütün xanaların doldurulduğundan əmin olun.")
        }


    }
    return <div className='modal'>
        <section className='col-md-8 col-sm-8 col-lg-5  col-11 mt-4  col-sm-9 --nsads'>

            <div className='close d-flex justify-content-center mt-3' onClick={() => { setRegister(!register); setSignupErr("") }}><div ><img alt='' src={close} /></div></div>
            <div className='na-text pt-5  p-2'>Yeni hesab yaradın</div>
            <div className='na-sub-text p-2 '>Müvafiq xanalara məlumatları daxil edin</div>
            {signupErr ? <div className='na-sub-text col-12 pt-3  d-flex justify-content-center pt-2' style={{ fontSize: 18, color: '#ed2939' }}>{signupErr}</div> : ""}
            <form onSubmit={userSignUP}>
                {signupTagData.map(element =>
                    element.type == 'tel' ?
                        <div className='col-12 d-flex justify-content-center align-items-center mt-1'>
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 59, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>
                                <span className='const' style={{ marginTop: 1 }}>+994</span>
                                <input ref={register_phone} maxLength={element.len} type='tel' className=' tinped' onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} placeholder='xxxx xxx xxx' style={{ outline: 'none', border: 'none', width: '100%', padding: 0, paddingLeft: 5, margin: 0 }}></input>

                            </div>

                        </div> :
                        (
                            <div key={element.slug} className="d-flex col-12 pt-3 justify-content-center">
                                <label htmlFor="inp" className="inp inp-ci">
                                    <input onKeyUp={element.slug === 6 ? (e) => {
                                        if (e.key === 'Enter') {
                                            userSignUP("sbmt")
                                        }
                                    } : ""} autoComplete='off' ref={element.ref} type={element.type} id="inp" className="form-input col-12" maxLength={element.len} placeholder="&nbsp;" />
                                    <span className="label">{element.label}</span>
                                </label>
                            </div>
                        )
                )}
                <div className="d-flex mt-1 pb-3 mb-2   col-12 justify-content-center">
                    <button className="--naddress-edit-btn m-3 col-10 btn btn-success" onClick={(e) => {
                        userSignUP(e)
                    }}>Daxil Ol</button>
                </div>
            </form>


        </section>


    </div>;
}

export default Signup;
