import React, {  useState } from 'react'

import AddAddress from '../../../assets/img/AddAddress.svg'
import AddressEdit from '../../../assets/img/AddressEdit.svg'
import masterCard from '../../../assets/img/masterCard.svg'
import visaCard from '../../../assets/img/visaCard.svg'
import otherCard from '../../../assets/img/otherCard.svg'
import AddressDelete from '../../../assets/img/AddressDelete.svg'
import DropDownArrow from '../../../assets/img/DropDownArrow.svg'
import close from '../../../assets/img/close.svg'
import { useLocation } from 'react-router-dom'
import NewCreditCard from '../../Modals/NewCreditCard'
import { MainContext, useContext } from '../../Context'
function MyCardInfo() {
  
    const [cardModal, setCardModal] = useState(false)
    const [cardDropDown, setCardDropDown] = useState(false)
    const { editCreditCardModal, setEditCreditCardModal, setCardDetails, } = useContext(MainContext)
    const setModal = (element) => {
        setEditCreditCardModal(!editCreditCardModal)
        setCardDetails({
            num: element.num,
            cvv: element.cvv,
            exp: element.ex_Date

        })
    }
    const cardJson = [
        {
            slug: 1,
            ex_Date: '10/29',
            cvv: '125',
            type: 'MasterCard',
            num: '4444 4444 4444 4444'
        },
        {
            slug: 2,
            ex_Date: '04/27',
            type: 'Visa',
            cvv: '134',
            num: '7483 2922 4234 9080'
        },
        {
            slug: 3,
            type: 'Other',
            ex_Date: '01/33',
            cvv: '444',
            num: '4169 3119 7073 5643'
        },
        {
            slug: 4,
            type: 'Other',
            ex_Date: '11/28',
            cvv: '552',
            num: '3872 7892 9831 0090'
        },
        {
            slug: 5,
            type: 'Visa',
            ex_Date: '12/23',
            cvv: '017',
            num: '1212 8288 9000 0912'
        },
    ]


    return (


        <div className='col-lg-6 col-md-7 offset-md-1 offset-sm-0 offset-lg-1 col-sm-12 col-12 mt-5 mt-sm-0 mt-lg-0 mt-md-0 myBook'>
            {cardModal ? <div>
                <div className='modal'>
                    <section className='col-md-8 col-lg-5 col-sm-8  col-10 --nsads'>
                        <div className='close d-flex justify-content-center mt-3'><div onClick={() => { setCardModal(!cardModal) }}><img alt='' src={close} /></div></div>
                        <NewCreditCard />
                    </section>
                </div>
            </div> : ''}
            <div className="d-flex col-12 mt-3 justify-content-center">
                <input type="text" disabled value={'Kart məlumatlarım'} className="col-12 mt-3" />
            </div>

            <div className="col-12 mt-3 ">
                {cardJson.map(element => {
                    return (
                        <div key={element.slug} className='-prop '>
                            <div className='card-info '>
                                <div className='card-img-bg col-1'>
                                    <img alt="" src={element.type === 'MasterCard' ? masterCard : element.type === 'Visa' ? visaCard : otherCard} className='card-img' />
                                </div>
                                <div className='card-props col-8'>
                                    <div className='cardType'> {element.type}</div>
                                    <div className='card-number'>
                                        {element.num.split(" ")[0]}  &#9679;&#9679;&#9679;&#9679;  &#9679;&#9679;&#9679;&#9679;  &#9679;&#9679;&#9679;&#9679;
                                    </div>
                                    <div className='maq'></div>
                                </div>
                                <div className='card-actions  d-flex align-items-center '>
                                    <div className="card-action-edit d-flex align-items-center justify-content-center" onClick={() => {
                                        setModal(element)
                                    }}><img src={AddressEdit} alt='Edit' /></div>
                                    <div className="card-action-delete d-flex align-items-center justify-content-center"><img src={AddressDelete} alt='Delete' /></div>
                                </div>
                                <div className={`card-actions-btn`}>
                                    <img alt="" src={DropDownArrow} onClick={() => {
                                        if (cardDropDown === `dp-${element.slug}`) {
                                            return setCardDropDown(false)
                                        }
                                        setCardDropDown(`dp-${element.slug}`)

                                    }} />

                                </div>


                            </div>
                            <div className={cardDropDown === `dp-${element.slug}` ? `col-12 card-sm-actions create-ed  align-items-center justify-content-center pb-2 pt-3 ` : 'col-12 card-sm-actions  align-items-center justify-content-center pb-2 pt-3 '} >
                                <div onClick={() => {
                                    setModal(element)
                                }} className="card-action-edit-text d-flex align-items-center justify-content-center">Düzəlt</div>
                                <div className="card-action-delete-text d-flex align-items-center justify-content-center">Sil</div>
                            </div>
                        </div>
                    )
                })}



            </div>
            <div className='col-12 mt-4'>
                <div className="col-12 d-flex align-items-center  justify-content-center --add-n-address" onClick={() => {
                    setCardModal(!cardModal)
                }}>
                    <img src={AddAddress} className='pr-3' alt='add address' /><div>Yeni kart əlavə et</div>

                </div>
            </div>
            <div className="d-flex col-12 mt-3 pb-3 justify-content-center">
                <button className="--contact-save-btn btn btn-success col-12">Dəyişiklikləri yadda saxla</button>
            </div>
        </div>
    )
}

export default MyCardInfo
