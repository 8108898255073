import React, { useState, useRef, useEffect } from 'react'
import Footer from '../../Partials/Footer';
import NavbarWhite from './../MainPage/NavbarWhite/NavbarWhite';
import Dropdown from '../Custom/Dropdown';
import { MainContext, useContext } from '../Context';
import TopOrder from '../MainPage/TopOrder/TopOrder';
import Authservice from '../../api/auth/index'
function ProductsAll() {
  const [productsArray ,setProductsArray] = useState([])
    const [filtered ,setFiltered] = useState([])
    const { productAction, setProductAction ,pageLanguage} = useContext(MainContext)
    
    const data = {
        headers:{"X-Language":pageLanguage}
    }
    useEffect(()=>{
        Authservice.getAllProducts(data).then(response=>{
            setProductsArray(response.data.data)
            setFiltered(response.data.data)
            
        })
    },[pageLanguage])
    
    useEffect(()=>{
            
            
        switch (productAction) {
            case "All":
                setFiltered(productsArray)
                break;
            case "Carbonated":
                const filter = productsArray.filter(r=>{
                    return r.category_id === 1
                })
                setFiltered(filter)
                break;
            case "Gasless":
                const filter2 = productsArray.filter(r=>{
                    return r.category_id === 2
                })
                setFiltered(filter2)
                break;
            case "Lemonade":
                const f3 = productsArray.filter(r=>{
                    return r.category_id === 4
                })
                setFiltered(f3)
                break;
            case "Bidon":
                const f4 = productsArray.filter(r=>{
                    return r.category_id === 3
                })
                setFiltered(f4)
                break;
            default:
                setFiltered(productsArray);
                break;
        }
    },[productAction])
    
    const productsItem = [
        {
            slug: 1,
            text: 'Bütün sular',
            name: 'All'
        },
        {
            slug: 2,
            text: 'Qazlı',
            name: 'Carbonated'
        },
        {
            slug: 3,
            text: 'Qazsız',
            name: 'Gasless'
        },
        {
            slug: 4,
            text: 'Bidon',
            name: 'Bidon'
        },
        {
            slug: 5,
            text: 'Limonad',
            name: 'Lemonade'
        }
    ]
    return (
        <section style={{ backgroundColor: '#fafafa' }}>
            <NavbarWhite />
            <div className='text-center d-sm-block d-none mt-5'>
                <span className='products-header-text pt-5'>Saf, təbii və sağlam Slavyanka suları</span>
            </div>
            <div className='row mt-5'>
                <div className='dropdown-sm-n'>
                    <Dropdown pageAction={productsItem} page='Products' />
                </div>
                <div className='products-actions col-12 d-none d-sm-flex justify-content-center' >
                    <div className='col-lg-9 col-md-11 col-sm-12'>
                        <ul id='products-main'>
                            {productsItem.map(item => {
                                return (<li key={item.slug} onClick={() => {
                                    setProductAction(item.name)
                                }} className={item.name === productAction ? 'active products-actions-items' : ' products-actions-items'}>{item.text}</li>)
                            })}

                        </ul>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
               
                <div className='d-flex justify-content-around flex-wrap col-sm-11 col-md-10  '>
                    {filtered && filtered.map(info=>{
                        
                        return(
                            <div key={info} className='col'>
                        <TopOrder name={info.name} props={info}  price={info.price} short_description={info.short_description} id ={info.id} imgurl={info.image} />
                    </div>
                        )
                    })}
                </div>
            </div>
            {/* <div className='col-12 d-flex justify-content-center'>
                <button onClick={() => {
                    addMoreInfo()
                }} className='addMoreInfo'>Daha çox</button>

            </div> */}
            <Footer />
        </section>
    )
}

export default ProductsAll