import React, { useEffect, useMemo, useState } from 'react'
import Pages from './Pages';
import { MainContext } from './Components/Context';
import Authservice from './api/auth/index'
import Signup from './Components/Modals/Signup';
import AddBasketModal from './Components/Modals/AddBasketModal';
import Order from './Components/Modals/Order';
import BookReceived from './Components/Modals/BookRecieved';
import EmailSent from './Components/Modals/EmailSent';
import ChangePassword from './Components/Modals/ChangePassword';
import ForgotEmail from './Components/Modals/ForgotEmail';
import Login from './Components/Modals/Login';
import MpBasketModal from './Components/Modals/mpBasketModal/MpBasketModal';
import PhoneInp from './Components/Modals/PhoneInp';
import CallOrderModal from './Components/Modals/CallOrderModal';
import SuccessMessageModal from './Components/Modals/SuccessMessageModal';
import ChooseAddres from './Components/Modals/ChooseAddres';
import GlobalSuccessMessage from './Components/Modals/GlobalSuccessMessage';
import NewAddress from './Components/Modals/NewAddress';
import IMAGES from './helpers/images/images';
import DirectGlobalMsg from './Components/Modals/DirectGlobalMsg';
import SidePopUpModal from './Components/Modals/sidePopUpModalComponent/SidePopUpModal';
import axios from 'axios';
function App() {
  document.addEventListener("click", (e => {
    if (e.target.classList[0] !== 'bas-itm') {
      return setBasketModal(false)
    }
  }));
  const [login, setLogin] = useState(false)
  const [basketLength, setBasketLength] = useState(0)
  const [orderDetails, setOrderDetails] = useState(null)
  const [register, setRegister] = useState(false)
  const [forgotEmail, setForgotEmail] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [bookReceived, setBookReceived] = useState(false)
  const [cardDetails, setCardDetails] = useState(false)
  const [addressDetails, setAddressDetails] = useState(false)
  const [giveOrder, setGiveOrder] = useState(false)
  const [editCreditCardModal, setEditCreditCardModal] = useState(false)
  const [editAddressModal, setEditAddressModal] = useState(false)
  const [completeBook, setCompleteBook] = useState(false)
  const [productAction, setProductAction] = useState('All')
  const [accessToken, setAccessToken] = useState(null)
  const [addressModal, setAddressModal] = useState(false)
  const [refreshToken, setRefreshToken] = useState(null)
  const [cabinetAction, setCabinetAction] = useState('MyInfo')
  const [addBasketModal, setAddBasketModal] = useState(false)
  const [pageLanguage, setPageLanguage] = useState('AZE')
  const [dash, setDash] = useState(null)
  const [cash, setCash] = useState(false)
  const [basketModal, setBasketModal] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [cookie, setCookie] = useState(null)
  const [readdress, setreaddress] = useState(false)
  const [adresses, setAdresses] = useState([])
  const [basketProps, setBasketProps] = useState([])
  const [phoneInp, setPhoneInp] = useState(false)
  const [registerPostData, setRegisterPostData] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const [successMessage, setSuccessMessage] = useState(false)
  const [callOrderModal, setCallOrderModal] = useState(false)
  const [basketTotalPrice, setBasketTotalPrice] = useState(0)
  const [chooseAddress, setChooseAddress] = useState(false)
  const [userShippingAddress, setUserShippingAddress] = useState("")
  const [shippingProps, setShippingProps] = useState('')
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState(false)
  const [globalmsg, setGlobalMsg] = useState(false)
  const [changeFetch, setChangeFetch] = useState(5)
  const [directGlobalMsg, setDirectGlobalMsg] = useState(false)
  const [globalState, setGlobalState] = useState(false)
  const [sidePopUp,setSidePopUp] = useState(false)
  const [sidePopUpMsg,setSidePopUpMsg] = useState("")
  const [sidePopUpState, setSidePopUpState] = useState(null)
  const [pageLangData,setPageLangData] = useState({})
  const [basketFetch,setBasketFetch] = useState("")
  const [billPage,setBillPage]  = useState("Cari sifarişlərim")
  const { close } = IMAGES
  useEffect(()=>{
    const cookieResponse = Authservice.checkCookie();
    if (!cookieResponse) {
      setDash(null)
      if(window.location.href.split('/')[3] === '/dashboard'){
          window.location.href="/"
      }
    }
    const cookies = Authservice.getCookie()
    const access = cookies[0]
    if (access !== undefined && access !== null) {
      setDash(true)
      setCookie(access)
      axios.get("https://slavyanka.az/api/order-service/cart",{
        headers:{
          Authorization:`Bearer ${access}`
        }
      }).then(r=>{}).catch(e=>{})
      Authservice.setUserSession({
        session_id: Authservice.getSessionId(),
      })
    } else {
      setDash(null)
    }
  Authservice.setSessionID()
    
  },[])
  const data = {billPage,setBillPage,
    basketFetch,setBasketFetch,
   sidePopUp,setSidePopUp,sidePopUpMsg,setSidePopUpMsg,sidePopUpState, setSidePopUpState,
    pageLangData,setPageLangData,
    globalState, setGlobalState,
    directGlobalMsg, setDirectGlobalMsg,
    cash, setCash, basketLength, setBasketLength,
    shippingProps, setShippingProps, globalSuccessMessage, setGlobalSuccessMessage, changeFetch, setChangeFetch, orderDetails, setOrderDetails,
    callOrderModal, setCallOrderModal, chooseAddress, setChooseAddress,
    readdress, setreaddress, basketProps, setBasketProps, successMessage, setSuccessMessage,
    addressModal, setAddressModal, setOrderData, orderData, basketTotalPrice, setBasketTotalPrice, setUserShippingAddress, userShippingAddress,
    login,
    register,
    loginError,
    pageLanguage,
    forgotEmail,
    changePassword,
    emailSent,
    bookReceived,
    addressDetails,
    giveOrder,
    productAction,
    cabinetAction,
    editCreditCardModal,
    cardDetails,
    editAddressModal,
    completeBook,
    addBasketModal,
    accessToken,
    refreshToken,
    dash,
    basketModal,
    cookie,
    adresses,
    setCookie,
    setLoginError,
    setDash,
    setPageLanguage,
    setAccessToken,
    setRefreshToken,
    setAddBasketModal,
    setLogin,
    setRegister,
    setForgotEmail,
    setChangePassword,
    setEmailSent,
    setBookReceived,
    setGiveOrder,
    setProductAction,
    setCabinetAction,
    setEditCreditCardModal,
    setCardDetails,
    setAddressDetails,
    setEditAddressModal,
    setCompleteBook,
    setBasketModal, setAdresses, phoneInp, setPhoneInp, setRegisterPostData, registerPostData, globalmsg, setGlobalMsg
  };
  
 
  return (
    <div>
      <MainContext.Provider value={data}>

        {
          basketModal ? <MpBasketModal></MpBasketModal > :
            addBasketModal ? <AddBasketModal /> : addressModal ? <div className='modal'>
              <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                <div onClick={() => {
                  setAddressModal(!addressModal);
                }} className='close d-flex justify-content-center mt-3'><div><img alt="" src={close} /></div></div>
                <NewAddress />
              </section>
            </div>:
              sidePopUp ? <SidePopUpModal state={sidePopUpState} msg={sidePopUpMsg}/> : directGlobalMsg ? <DirectGlobalMsg msg={directGlobalMsg} state={globalState} /> : globalSuccessMessage ? <GlobalSuccessMessage msg={globalmsg} /> : chooseAddress ? <ChooseAddres /> : successMessage ? <SuccessMessageModal /> : callOrderModal ? <CallOrderModal /> : phoneInp ? <PhoneInp /> : addBasketModal ? <AddBasketModal /> : giveOrder ? <Order /> : bookReceived ? <BookReceived /> : emailSent ? <EmailSent /> : changePassword ? <ChangePassword /> : forgotEmail ? <ForgotEmail /> : login ? <Login /> : register ? <Signup /> : ""
        }
        <Pages />
      </MainContext.Provider>
    </div>
  )
}

export default App

