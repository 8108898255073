import React, { useEffect, useState } from "react";
import "./src/css/style.css";
import ShoppingLight from "../../../assets/img/ShoppingLight.svg";
import { MainContext, useContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import Authservice from "../../../api/auth/index";
export default function OneProduct() {
  useEffect(() => {
    console.log(basketLength);
  }, []);
  const path = window.location.href;
  const id = path.split("/")[4];
  const [productPrice, setProductPrice] = useState(0);
  const [oldProductPrice, setOldProductPrice] = useState(0);
  const [is_on_sale, setIsOnSale] = useState("");
  const {
    setBasketProps,
    setAddBasketModal,
    pageLanguage,
    addBasketModal,
    basketLength,
    changeFetch,
  } = useContext(MainContext);
  const [props, setProps] = useState("");
  const [oImg, setOImg] = useState();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    Authservice.getOneProductDetails(
      id,
      pageLanguage.slice(0, -1).toLowerCase()
    )
      .then((resp) => {
        const response = resp.data.data;
        setProductPrice((response.price / 100).toFixed(2));
        setOldProductPrice((response.old_price / 100).toFixed(2));
        setIsOnSale((response.is_on_sale));
        setOImg(response.image);
        setProps(response);
      })

      .catch((e) => e);
  }, [changeFetch, pageLanguage]);

  const addToBasket = () => {
    setBasketProps(props);

    setAddBasketModal(!addBasketModal);
  };
  useEffect(() => {
    //
  }, [props.description]);
  const navigate = useNavigate();
  return (
    <div className="border rounded   oneProduct row">
      <div
        className="border col-md d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "#fafafa" }}
      >
        <img src={oImg} alt="" className="" height={500}></img>
      </div>
      <div className="border pt-5 pb-4 px-2 col-md">
        <h2 className="font-weight-bold">{props.name}</h2>
        <p className="colort2 ">{props.category}</p>
        <p className="colort1">{props.description && props.description}</p>
        {props.category_id !== 4 ? (
          <div className="div of the makro elements d-flex flex-wrap  ">
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              {" "}
              CA2+ - &lt;32mq/l{" "}
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              {" "}
              Na+-&lt;25mq/l
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              {" "}
              HCO-3 - &lt;125mq/l{" "}
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              J- - &lt;0.011mq/l
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              pH-7.10-7.70{" "}
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              {" "}
              SO42- - &lt;30mq/l{" "}
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              {" "}
              K+-&lt;1mq/l{" "}
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              {" "}
              Cl--&lt;5mq/l{" "}
            </div>
            <div className="colort4 m-2 flex-fill flex-md-grow-0">
              Mg2+-&lt;7mq/l
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="d-flex flex-md-column  mt-3 mb-1 justify-content-between mx-2">
          <div className="d-flex">
            <p className="colort1">Məhsulun qiyməti: </p>
            <p className="font-weight-bold ml-1" style={{ color: "#000" }}>
              {productPrice} <span style={{ paddingLeft: 5 }}>AZN</span>
            </p>
            {
              is_on_sale === true && (
                <p className="font-weight-bold ms-3" style={{ color: "red", textDecoration: "line-through", display: "flex", }}>
                  {oldProductPrice} <span style={{ paddingLeft: 5 }}>Azn</span>
                </p>
              )}
          </div>

          {/* <div className='d-flex align-items-center mb-3 ml-sm-0 ml-2 '>

            <div onClick={() => setopPrice(opPrice > 0 ? opPrice - 1 : opPrice)} className='btn  opPriceBox  '>
              <img src={minusIcon} alt=""></img>
            </div>

            <div className='mx-3 opPrice'>{opPrice}</div>

            <div onClick={() => setopPrice(opPrice + 1)} className='btn opPriceBox '>
              <img src={plusIcon} alt=""></img>
            </div>
          </div> */}
        </div>

        <div className="d-flex flex-wrap mt-2  flex-md-row flex-column mx-2">
          <div className="d-flex flex-fill flex-sm-grow-0 mb-3  ">
            <div
              onClick={() => addToBasket()}
              className="btn colorb3 py-3 px-3 text-light flex-grow-3 flex-md-grow-0 mr-3 mr-sm-0 "
            >
              <img src={ShoppingLight} alt="shop"></img>
            </div>

            <div
              className={
                basketLength > 0
                  ? "btn mx-sm-3 colorb3 py-3 text-light flex-grow-1 flex-md-grow-0"
                  : "disabled-btn btn mx-sm-3 colorb3 py-3 text-light flex-grow-1 flex-md-grow-0"
              }
              onClick={() => (basketLength > 0 ? navigate("/basket") : "")}
            >
              Sifarişi tamamla
            </div>
          </div>
          <div className="d-flex flex-fill flex-sm-grow-0 mb-3  ">
            <div
              className=" colort3 py-3 colorbb3 flex-fill flex-md-grow-0"
              onClick={() => navigate("/products")}
            >
              {" "}
              Aliş-verişə davam et
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
