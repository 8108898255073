import { MainContext, useContext } from '../Context';
import IMAGES from '../../helpers/images/images';
function BookReceived({ signed }) {
    const{close,success} =IMAGES
    const {  setBookReceived } = useContext(MainContext)

    return <div>
        <div className='modal'>
            <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                <div className='close d-flex justify-content-center mt-3'><div onClick={() => {
                    setBookReceived(false)
                }}><img alt='' src={close} /></div></div>
                <div className='col-12 p-0 m-0 d-flex justify-content-center'>
                    <div className='col-12 pb-5 '>
                        <div className='d-flex col-12 justify-content-center pt-3 pb-3'>
                            <div className='--bg-success d-flex col-12 justify-content-center'><img alt='' src={success} /></div>
                        </div>
                        <div className='mb-4'>
                            <div className='na-text col-12 pt-3 d-flex justify-content-center pt-2'>Sifariş qəbul olundu!</div>
                            <div className='na-sub-text col-12 pt-3  d-flex justify-content-center pt-2'>Əməkdaşlarımız sizinlə ən qısa zamanda əlaqə saxlayacaq</div>
                            <div className='d-flex justify-content-center align-items-center pt-3 mt-2'>
                                <div className='na-sub-text col-sm-10 col-md-9 col-12  --received-bg  d-flex justify-content-center '>Sifariş nömrəsi: 12345678890</div>

                            </div>
                            {signed === false
                                ?
                                <div className='d-flex justify-content-center align-items-center pt-1 mt-1'>
                                    <div className=' col-sm-10 col-md-9 col-12  --wSup  d-flex justify-content-center '>Mövcud məlumatlarla hesab yaradın</div>

                                </div> : ""
                            }

                        </div>
                    </div>
                </div>

            </section>


        </div>;
    </div>;
}

export default BookReceived;
