import React from 'react'
import Footer from '../../Partials/Footer'
import NavbarWhite from '../MainPage/NavbarWhite/NavbarWhite'
import TopOrderSlider from '../MainPage/TopOrderSlider/TopOrderSlider'
import OneProduct from './OneProduct/OneProduct'

export default function OneProductPage() {
  return (
    <>
        <NavbarWhite/>
        <OneProduct/>
        <TopOrderSlider/>
        <Footer/>
    </>
  )
}
