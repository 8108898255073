import instance from "../../api";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
const backendApi = process.env.REACT_APP_BACKEND_API;
const cookies = new Cookies();
const getAllProducts = (data) => {
  return instance.get(backendApi + `/product-service/products`, data);
};
const getAllCategories = (data) => {
  return instance.get(`${backendApi}/product-service/categories`, data);
};
const getAppData = (data) => {
  return instance.get(`${backendApi}/product-service/app-data`, data);
};
const getProductDetails = (data, id) => {
  return instance.get(`${backendApi}/product-service/product/${id}`, data);
};
const getHomepageData = (data) => {
  return instance.get(`${backendApi}/product-service/home-page-data`, {
    headers: {
      "X-Language": `${data}`,
    },
  });
};
const getUserAddress = (cookie) => {
  return instance.get(`${backendApi}/user-service/shipping-addresses`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};

const signUp = (data) => {
  return instance.post(`${backendApi}/auth/users/register`, data);
};
const logIn = (data) => {
  return instance.post(`${backendApi}/auth/token`, data);
};
const orderByPhone = (data) => {
  return instance.post(`${backendApi}/user-service/ordering-by-phone`, data);
};
const setUserSession = (data) => {
  return instance.put(`${backendApi}/order-service/cart/set-user`, data);
};
const createCoookie = (refresh, access) => {
  cookies.set("#aS_a+cHT!lM", access, {
    sameSite: "strict",
    path: "/",
    expires: new Date(new Date().getTime() + 1500000 * 1000),
  });
  cookies.set("[_s!cd-if)", refresh, {
    sameSite: "strict",
    path: "/",
    expires: new Date(new Date().getTime() + 400000 * 1000),
  });
};
const getCookie = () => {
  const slavyankaAccess = cookies.get("#aS_a+cHT!lM");
  const slavyankaRefresh = cookies.get("[_s!cd-if)");
  return [slavyankaAccess, slavyankaRefresh];
};
const deleteDbCookie = (data) => {
  return instance.post(
    "",
    {},
    {
      headers: {
        Authorization: `Bearer ${data}`,
      },
    }
  );
};

const deleteCookie = () => {
  cookies.remove("#aS_a+cHT!lM");
  cookies.remove("[_s!cd-if)");
  // cookies.remove('_ssID+pr!f')
};
const setSessionID = () => {
  const haveSession = cookies.get("_ssID+pr!f");
  if (haveSession === undefined)
    return cookies.set("_ssID+pr!f", uuidv4().split("-")[0], {
      expires: new Date(new Date().getTime() + 1500000 * 1000),
    });
  else return "";
};
const deleteAllFromBasket = (session_id, cart_id) => {
  return instance.delete(`${backendApi}/order-service/cart/remove`, {
    data: {
      session_id: session_id,
      cart_id: cart_id,
    },
  });
};
const addProductsToBasket = (session_id, productId, cookie) => {
  if (cookie !== null) {
    return instance
      .post(
        `${backendApi}/order-service/cart/add`,
        {
          session_id: session_id,
          product_id: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${cookie}`,
          },
        }
      )
      .then((r) => {})
      .catch((e) => {
        return e;
      });
  } else {
    return instance
      .post(`${backendApi}/order-service/cart/add`, {
        session_id: session_id,
        product_id: productId,
      })
      .then((r) => {
        return r;
      })
      .catch((e) => {
        return e;
      });
  }
};
const showProducts = (session_id, cookie) => {
  return instance.get(
    `${backendApi}/order-service/cart?session_id=${session_id}`,
    {
      headers: {
        authorization: `Bearer ${cookie}`,
      },
    }
  );
};
const getAuthorizedUserProducts = (cookie) => {
  return instance.get(`${backendApi}/order-service/cart`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};
const decodeToken = (token) =>
  token !== undefined
    ? JSON.parse(Buffer.from(token.split(".")[1], "base64").toString("binary"))
    : false;
const checkCookie = () => {
  const cookies = getCookie();
  const refreshToken = cookies[0];
  const accessToken = cookies[1];
  if (refreshToken === undefined || accessToken === undefined) {
    return false;
  } else {
    const refreshExpTime = decodeToken(refreshToken).exp * 1000;
    const accessExpTime = decodeToken(accessToken).exp * 1000;
    const todayTimeStamp = new Date().getTime();
    if (todayTimeStamp >= refreshExpTime) {
      return false;
    } else if (todayTimeStamp >= accessExpTime) {
      refresh(refreshToken)
        .then((response) => {
          createCoookie(response.data.refreshToken, response.data.accessToken);
          return true;
        })
        .catch((err) => {
          return false;
        });
    } else {
      return true;
    }
  }
};
const refresh = (token) => {
  return instance.post(`${backendApi}/auth/refresh-token`, {
    refreshToken: token,
    rememberMe: true,
  });
};
const deleteFromBasket = (session_id, id) => {
  return instance.delete(`${backendApi}/order-service/cart/remove`, {
    data: {
      session_id: session_id,
      cart_id: id,
    },
  });
};
const createOnlineOrderAuthorized = (data, cookie) => {
  return instance.post(
    `${backendApi}/order-service/payment/create-online-order`,
    data,
    {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    }
  );
};
const createCashOrderAuthorized = (data, cookie) => {
  return instance.post(
    `${backendApi}/order-service/payment/create-cash-order`,
    data,
    {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    }
  );
};
const saveChanges = (token, data) => {
  return instance.put(`${backendApi}/user-service/user-profile`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const resetPass = (token, data) => {
  return instance.put(`${backendApi}/auth/users/change-password`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getSessionId = () => {
  return cookies.get("_ssID+pr!f");
};
const deleteSessionId = () => {
  return cookies.remove("_ssID+pr!f");
};
const getAllAddresses = (cookie) => {
  return instance.get(`${backendApi}/user-service/shipping-addresses`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};
const editAddress = (id, data, cookie) => {
  return instance.put(
    `${backendApi}/user-service/shipping-addresses/${id}/update`,
    data,
    {
      headers: `Bearer ${cookie}`,
    }
  );
};

const newAddress = (data, cookie) => {
  return instance.post(
    `${backendApi}/user-service/shipping-addresses/add`,
    data,
    {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    }
  );
};


const addsuggest = (data) => {

  return instance.post(`${backendApi}/user-service/customer-comment`, data);
  
  
};


const getAllOrders = (cookie) => {
  return instance.get(`${backendApi}/user-service/orders`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};

const getOrderDetail = (id, cookie) => {
  return instance.get(`${backendApi}/user-service/orders/${id}`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};
const showAllBasketProducts = (session_id) => {
  return instance.get(
    `${backendApi}/order-service/cart?session_id=${session_id}`
  );
};
const getBasketProducts = (session_id) => {
  return instance.get(
    `${backendApi}/order-service/cart?session_id=${session_id}`,
    {
      headers: {
        Authorization: `Bearer ${getCookie()[0]}`,
      },
    }
  );
};
const deleteOneTypeProduct = (session_id , cart_id) => {
  return instance.delete(`${backendApi}/order-service/cart/remove`, {
    data: {
      session_id: session_id,
      cart_id: cart_id,
    },
  });
};
const getUserCurrentOrders = (cookie) => {
  return instance.get(`${backendApi}/user-service/orders`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};
const getUserCurrentOrderDetail = (cookie,orderId) => {
  return instance.get(`${backendApi}/user-service/orders/${orderId}` , {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};
const getUserOldOrders = (cookie) => {
  return instance.get(`${backendApi}/user-service/orders`, {
    headers: {
      Authorization: `Bearer ${cookie}`,
    },
  });
};
const deleteUserAddress = (id, cookie) => {
  return instance.delete(
    `${backendApi}/user-service/shipping-addresses/${id}/remove`,
    {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    }
  );
};
const getLangData = (lang) => {
  return instance.get(`${backendApi}/product-service/dictionary`, {
    headers: {
      "X-Language": lang,
    },
  });
};
const getOneProductDetails = (id, lang) => {
  return instance.get(`${backendApi}/product-service/product/${id}`, {
    headers: {
      "X-Language": lang,
    },
  });
};
const resetUserPass = (token) => {
  return instance.get(
    `${backendApi}/auth/users/password/check-password-reset-token?token=${token}`
  );
};
const createCashOrder = (config) => {
  return instance.post(
    `${backendApi}/order-service/payment/create-cash-order`,
    config
  );
};
const createOnlineOrder = (config) => {
  return instance.post(
    `${backendApi}/order-service/payment/create-online-order`,
    config
  );
};
const setPaymentStatus = (cookie, uuid, requestStatus) => {
  return instance.put(
    `${backendApi}/order-service/payment/set-status`,
    {
      uuid: uuid,
      status: requestStatus,
    },
    {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    }
  );
};
const resetEmailedPass = (token, config) => {
  return instance.put(
    `${backendApi}/auth/users/password/reset-password/${token}`,
    config
  );
};
const editUserAddress = (config, address_id, cookie) => {
  return instance.put(
    `${backendApi}/user-service/shipping-addresses/${address_id}/update`,
    config,
    {
      headers: { Authorization: `Bearer ${cookie}` },
    }
  );
};
const ForgotEmail = (config) => {
  return instance.post(
    `${backendApi}/auth/users/password/password-reset-token`,
    {
      email: config,
    }
  );
};
const NewAddress = (cookie, config) => {
  return instance.post(
    `${backendApi}/user-service/shipping-addresses/add`,
    config,
    {
      headers: {
        Authorization: `Bearer ${cookie}`,
      },
    }
  );
};

export default {
  resetEmailedPass,
  editUserAddress,
  ForgotEmail,
  NewAddress,
  createCashOrderAuthorized,
  setPaymentStatus,
  createOnlineOrder,
  createCashOrder,
  deleteUserAddress,
  getUserCurrentOrders,
  getUserCurrentOrderDetail,
  getUserOldOrders,
  getLangData,
  getOneProductDetails,
  resetUserPass,
  saveChanges,
  resetPass,
  checkCookie,
  getAuthorizedUserProducts,
  getUserAddress,
  deleteOneTypeProduct,
  getBasketProducts,
  deleteAllFromBasket,
  decodeToken,
  refresh,
  showAllBasketProducts,
  signUp,
  editAddress,
  showProducts,
  deleteFromBasket,
  setSessionID,
  getAllAddresses,
  addsuggest,
  getAllOrders,
  getOrderDetail,
  logIn,
  newAddress,
  deleteDbCookie,
  addProductsToBasket,
  getSessionId,
  orderByPhone,
  createOnlineOrderAuthorized,
  createCoookie,
  getCookie,
  deleteSessionId,
  getAllCategories,
  getAllProducts,
  getProductDetails,
  getHomepageData,
  getAppData,
  deleteCookie,
  setUserSession,
};
