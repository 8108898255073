import React, { useEffect, useRef, useState } from 'react'
import { MainContext, useContext } from '../Context'
import IMAGES from '../../helpers/images/images'
import AuthService from '../../api/auth/index'
import axios from 'axios'
function EditAddress() {
    const close = IMAGES.close
    const { addressDetails, setSidePopUp, setSidePopUpMsg, setSidePopUpState, cookie, editAddressModal, setEditAddressModal, readdress, setreaddress } = useContext(MainContext)
    const [er, setEr] = useState("")
    const reName = useRef()
    const reSurname = useRef()
    const rephone = useRef()
    const reaprops = useRef()
    const readdress_title = useRef()
    const { address_name, address_surname, address_phone, address_props, address_id, address_title } = addressDetails
    const giveAlert = (msg) => {
        setSidePopUpMsg(msg)
        setSidePopUpState(0)
        setSidePopUp(true)
    }

    const editDatas = (e) => {
        const symbols = ["0","1","2","3","4","5","6","7","8","9"];
        for(let c of rephone.current.value.trim()){
            if(symbols.indexOf(c) === -1){
                giveAlert("Telefon nömrəsi sadəcə rəqəmlərdən ibarət olmalıdır.")
                return false
            }
        }
        let config = {
            name: reName.current.value.trim(),
            surname: reSurname.current.value.trim(),
            zone_id: 2,
            address: reaprops.current.value.trim(),
            phone: `994${rephone.current.value.trim()}`,
            title: readdress_title.current.value.trim()
        }
        if (config.name === "") {
            giveAlert("Adınızı daxil edin.")
            return false
        } else if (config.surname === "") {
            giveAlert("Soyadınızı daxil edin.")
            return false

        }
        else if (config.phone === "994" || config.phone === "") {
            giveAlert("Telefon nömrənizi daxil edin.")
            return false
        } else if (config.title === "") {
            giveAlert("Adres başlığını əlavə edin.")
            return false
        }
        else if (config.address === "") {
            giveAlert("Adresinizi daxil edin.")
            return false
        }

        if (config.phone.indexOf('+') !== -1) {
            config.phone = rephone.current.value.split('+')[1]
        }
        AuthService.editUserAddress(config,address_id,cookie).then(resp => {

            setEditAddressModal(!editAddressModal)
            setreaddress(!readdress)
        }).catch(err => {

            // setEr("Bütün məlumatların düzgünlüyünü yoxlayın.Telefon nömrəsi 12 rəqəmdən ibarət olmalıdır.")
        })
    }

    return (
        <div className='modal'>
            <section className='col-md-8 col-lg-5 col-sm-8  col-10 --nsads'>
                <div onClick={() => setEditAddressModal(!editAddressModal)} className='close d-flex justify-content-center mt-3'><div  ><img alt="" src={close} /></div></div>
                <form className='mt-5'>
                    <div className='na-text p-3'>Mövcüd Adres-ə Düzəliş edin.</div>
                    <div className='na-sub-text pl-3 pt-1' style={{ color: "#ed2939" }}>{er}</div>
                    <div className='mt-3'>
                        <div className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                            <input autoComplete='off' type="text" maxLength={20} ref={readdress_title} defaultValue={address_title} id="inp" className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                            <span className="label">Adres Başlığı</span>
                        </label></div>
                        <div className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                            <input autoComplete='off' type="text" maxLength={20} ref={reName} defaultValue={address_name} id="inp" className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                            <span className="label">Ad</span>
                        </label></div>
                        <div className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                            <input autoComplete='off' type="text" maxLength={20} ref={reSurname} defaultValue={address_surname} id="inp" className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                            <span className="label">Soyad</span>
                        </label></div>
                        <div className='col-12 d-flex justify-content-center align-items-center pb-2'>
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>
                                <span className='const' style={{ marginTop: 1 }}>+994</span>
                                <input ref={rephone} maxLength={9} type='tel' placeholder='xxxx xxx xxx' style={{ outline: 'none', border: 'none', height: 65, width: '100%', padding: 0, paddingLeft: 5, margin: 0 }}></input>

                            </div>

                        </div>
                        <div className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                            <input autoComplete='off' type="text" maxLength={50} ref={reaprops} defaultValue={address_props} id="inp" className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                            <span className="label">Bina, ev və mənzil nömrəsi</span>
                        </label></div>

                        <div className="d-flex col-12 mt-3 pb-3 justify-content-center align-items-center">
                            <button type='button' className="--naddress-edit-btn btn col-10 btn-success" onClick={(e) => { editDatas(e) }}>Təsdiqlə</button>
                        </div>
                    </div>
                </form>
            </section>
        </div>

    )
}

export default EditAddress