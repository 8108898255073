import React, { useEffect } from 'react'
import {useNavigate, userNavigate} from 'react-router-dom';
import { useState } from "react";
import { MainContext,useContext } from '../../Context';
import Authservice from "../../../api/auth/index"
function DashBoardModal(props) {
    const navigate = useNavigate()
    const [Open, setOpen] = useState(true);
    const {cookie} = useContext(MainContext)
    const uuid  =window.location.href.split('?')[1].split('=')[1]
    const status = window.location.pathname.split('/')[2].split('-')[1]
    let requestStatus = ''
 
    if(status === 'cancel'){
        requestStatus = (0)
    }else if(status==='decline'){
        requestStatus = (-1)
    }
    else if(status === 'approve'){
        requestStatus = (1)
    }
    useEffect(()=>{
       Authservice.setPaymentStatus(cookie,uuid,requestStatus)
    },[requestStatus])
    return <div className={Open ? 'modal' : 'd-none'}>
        <div className='modal'>
            <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                <div className='close d-flex justify-content-center mt-3  '><div onClick={() => {
                    setOpen(!Open)
                    navigate('/')
                }}><img alt='foto' src={props.imgX} /></div></div>
                <div className='col-12 p-0 m-0 d-flex justify-content-center'>
                    <div className='col-12 pb-5 '>
                        <div className='d-flex col-12 justify-content-center pt-3 pb-3'>
                            <div className='  d-flex col-12 justify-content-center'><img alt='foto' width={40} src={props.img} /></div>
                        </div>
                        <div className='mb-4'>
                            <div className='na-text col-12 px-0 d-flex justify-content-center pt-2'>{props.msj}</div>
                            
                        </div>
                    </div>
                </div>

            </section>
        </div>;
    </div>;
}

export default DashBoardModal