import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style.css'
import { MainContext, useContext } from '../Context';
import axios from 'axios';
import auth from '../../api/auth';
function NewAddress() {
    const { addressModal, setAddressModal, cookie, setSidePopUp, setSidePopUpMsg, setSidePopUpState, setreaddress } = useContext(MainContext)
    const giveAlert = (msg) => {
        setErrorResp(msg);
    }
    let ph_num = true
    const [erroresp, setErrorResp] = useState("")
    const addres_title = useRef()
    const address_name = useRef()
    const address_surname = useRef()
    const address_phone = useRef()
    const address_props = useRef()
    const symbols = ["0","1","2","3","4","5","6","7","8","9"];
    const addAddress = (e) => {
        e.preventDefault();
        
        let config = {
            "name": address_name.current.value.trim(),
            "surname": address_surname.current.value.trim(),
            "zone_id": 2,
            "address": address_props.current.value.trim(),
            "phone": `994${address_phone.current.value.trim()}`,
            "title": addres_title.current.value.trim()
        }
        for(let c of address_phone.current.value.trim()){
            if(symbols.indexOf(c) === -1){
                giveAlert("Telefon nömrəsi sadəcə rəqəmlərdən ibarət olmalıdır.")
                return false
            }
        }
        if (config.title === "") {
            giveAlert("Adres başlığını əlavə edin.")
            return false

        }
        else if (config.name === "") {
            giveAlert("Adınızı daxil edin.")
            return false
        } else if (config.surname === "") {
            giveAlert("Soyadınızı daxil edin.")
            return false

        } else if (config.phone === "994" || config.phone === "") {
            giveAlert("Telefon nömrənizi daxil edin.")
            return false

        } else if (config.name === "") {
            giveAlert("Adresinizi daxil edin.")
            return false

        }
        if (address_phone.current.value.length !== 9) {
            setErrorResp(("Telefon Nömrəsi 13 simvoldan və sadəcə rəqəmlərdən ibarət olmalıdır."))
            ph_num = false
        } else {
            ph_num = true;
        }
        if (config.phone.indexOf('+') !== -1) {
            config.phone = address_phone.current.value.split('+')[1]
        }

       auth.NewAddress(cookie,config).then(resp => {
                setErrorResp("")
                setAddressModal(!addressModal);
                auth.getUserAddress(cookie)
                setreaddress(true)

            }).catch(err => {
                
                if (ph_num) {
                    setErrorResp("Xəta baş verdi,məlumatların düzgünlüyünü yoxlayın!")
                }
            })
    }

    const adressesx = [
        {
            slug: 4,
            ref: addres_title,
            placeholder: 'Ünvan başlığı',
            a_length: 20
        },
        {
            slug: 1,
            ref: address_name,
            placeholder: "Ad",
            a_length: 20
        },
        {
            slug: 2,
            ref: address_surname,
            placeholder: "Soyad",
            a_length: 10
        },
        {
            slug: 3,
            ref: address_phone,
            placeholder: "+994xxxxxxxxx",
            a_length: 13
        },

        {
            slug: 5,
            ref: address_props,
            placeholder: "Bina, ev və mənzil nömrəsi"
        },
    ]
    return (
        <form className='mt-5'>
            <div className='na-text p-3'>Yeni ünvan əlavə et</div>
            <div className='na-sub-text p-3' >Müvafiq xanalara məlumatları daxil edin</div>
            <div className='na-sub-text pl-3 pt-1' style={{ color: "#ed2939" }}>{erroresp}</div>
            <div className='mt-3'>
                {adressesx.map(address =>
                    address.slug === 3 ?
                        <div className='col-12 d-flex justify-content-center align-items-center pb-2'>
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>
                                <span className='const' style={{ marginTop: 1 }}>+994</span>
                                <input maxLength={9} type='tel' onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}  ref={address_phone} placeholder='xxxx xxx xxx' style={{ outline: 'none', border: 'none', height: 65, width: '100%', padding: 0, paddingLeft: 5, margin: 0 }}></input>

                            </div>

                        </div> :
                        (
                            <div key={address.slug} className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                                <input autoComplete={'off'} maxLength={address.a_length} type="text" ref={address.ref} id="inp" className="form-input inp-n_add col-12" placeholder="&nbsp;" />
                                <span className="label">{address.placeholder}</span>
                            </label>
                            </div>
                        )
                )}
                <div className="d-flex col-12 mt-3 pb-3 justify-content-center align-items-center">
                    <button className="--naddress-edit-btn btn col-10 btn-success" onClick={(e) => {
                        addAddress(e)
                    }}>Təsdiqlə</button>
                </div>
            </div>
        </form>
    )

}

export default NewAddress;
