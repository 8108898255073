import React, { useEffect } from "react"; 
import "./src/css/style.css"
import navBrand from "./src/img/LogoDark.svg";
import toggleBlack from "./src/img/toggleblack.svg";
import personLight from "./src/img/PersonDark.svg";
import phoneLight from "./src/img/PhoneLight.svg";
import shopLight from "./src/img/ShoppingDark.svg";
import mobNavX from './src/img/mobNavX.svg'
import { useState } from "react";
import {Link} from 'react-router-dom'

import { MainContext,useContext } from "../../Context";
import Authservice from '../../../api/auth/index'


function NavbarWhite() {
  const langs = ['AZE','ENG','RUS']
  const [userAuth,setUserAuth] = useState(null)
  const [navIsActive, setNavIsActive] = useState(false);
  const {login,setLogin,pageLangData,setPageLangData,dash,basketModal,callOrderModal,basketLength,setBasketLength,setCallOrderModal,pageLanguage,setPageLanguage,setBasketModal} = useContext(MainContext)
  const navMenu = [
    {
      id: 1,
      name:  pageLangData.home ,
      slug: "home",
    },
    {
      id: 2,
      name: pageLangData.products,
      slug: "products",
    },
    {
      id: 3,
      name: pageLangData["about-us"],
      slug: "about",
    },
    {
      id: 4,
      name: pageLangData.contact,
      slug: "contact",
    },

  ]; 
  const getLangData=()=>{
    Authservice.getLangData(pageLanguage.slice(0, -1).toLowerCase() )
    .then(r=>{setPageLangData(r.data)
    })
    .catch(err=>{})
  }

  useEffect(()=>{
    const isAuth =Authservice.checkCookie();
    setUserAuth(isAuth);
    getLangData()
  },[])
  useEffect(() => {
    getLangData()
  }, [pageLanguage])
  
  let counter = 0
  if(userAuth && counter === 0){
    try {
      navMenu.push({
        id: 5,
        name: pageLangData.dashboard || "Dashboard",
        slug: "dashboard",
      })
     } catch (error) {
       
     }
     finally{
       counter=counter+1
     }
  }
  useEffect(()=>{
    const sessionId = Authservice.getSessionId()
    Authservice.showAllBasketProducts(sessionId).then(r=>setBasketLength(r.data.length))
  })
  const callOrder=()=>{

    setCallOrderModal(!callOrderModal);
   
} 
  return (
    <nav
      
    >
      <div className="border-bottom d-flex align-items-stretch justify-content-around text-light ">
        <Link to="/home">
          <img
            src={navBrand}
            alt="Logo"
            className="border-right my-3 pl-lg-2 pr-sm-5 pr-3"
            height="72px"
          />
        </Link>

        <div
          className="d-none d-lg-flex align-items-center text-dark"
          style={{ fontSize: "15px" }}
        >
          Sifariş üçün:{" "}
          <p className="font-weight-bold m-0 pl-1" style={{ fontSize: "20px" }}>
            *2121
          </p>
        </div>

        <ul className=" d-none d-lg-flex list-unstyled m-0 align-items-stretch ">
          {navMenu.map((item) => (
            <li key={item.slug}
              className="mx-2 btn  py-2 p-0 navhover d-flex align-items-center "
              style={{ fontSize: "15px" }}
            >
              <Link  className="text-decoration-none text-dark h-100 d-flex align-items-center " to={"/" + item.slug}>{item.name}</Link>
            </li>
          ))}
        </ul>

        <div className="d-flex  my-auto ">
            <div
              className={`dropdown mr-4 d-lg-block ${!navIsActive && 'd-none'}`}
              id="dropdownMenuLink"
            >
              <a
                className="btn border  font-weight-bold dropdown-toggle"
                
                data-toggle="dropdown"
                aria-expanded="false"
                defaultValue={'AZE'}
                style={{color:'#000'}}
              >
                {pageLanguage}
              </a>

              <div
              className="dropdown-menu "
              aria-labelledby="dropdownMenuLink"
            >
              {langs.map(language=>{
                return (
                  <a key={language} className="dropdown-item"  onClick={()=>{
                    setPageLanguage(language) 
                  }}>
                    {language}
              </a>
                )
              })}
            </div>
            </div>

          {userAuth ? "" :
          <img
          className="border px-2 mr-2 btn d-none d-lg-block "
          src={personLight}
          alt=""
          onClick={()=>{
            setLogin(!login)
          }}
        />}
          {!navIsActive && (
            
            <div style={{
              position:'relative'
            }}>
              <div className="el-center" style={{
                background:'#ED2939',
                borderRadius:999,
                color:"#FFF",
                width:15,
                height:15,
                position:'absolute',
                top:0,
                right:0,
                fontSize:'8px'
                
              }}>{basketLength}</div>
              <img 
              className="bas-itm border px-2 btn"
              src={shopLight}
              alt=""
              id="shopLink"
              onClick={()=>{ 
                setBasketModal(!basketModal)
              }}
            />
            </div>
          )}
          <img
            className={`border rounded px-2 mr-2 ${ navIsActive ? "px-3" : "px-2"} btn d-block d-lg-none  mx-2`}
            src={navIsActive ? mobNavX : toggleBlack}
            alt=""
            id="miniNav"
            onClick={(e) => setNavIsActive(!navIsActive)}
          />
        </div>

        <div
          className="d-none d-lg-flex  btn align-items-center py-2 my-auto "
          style={{ backgroundColor: "#BB221A" }}
          onClick={()=>{
            callOrder()
          }}
        >
          <img
            className="px-3 py-1  rounded "
            src={phoneLight}
            style={{ backgroundColor: "#A8221B" }}
            alt=""
          />
          <p className="text-light m-0 px-2" >Zəng sifarişi</p>
        </div>
      </div>
      <div></div>

      <div
        id="navDrop"
        className={`mx-4 px-1 mt-1 d-lg-none ${!navIsActive && "d-none"}`}
        style={{ minHeight: "calc(100vh - 72px)" }}
      >
        <div className=" mx-2 mt-3 py-2 d-flex   justify-content-between text-dark">
          <div className="bas-itm border btn py-2 d-flex col mr-2 text-center" onClick={()=>{ 
              setBasketModal(!basketModal)
            }}>
            <img
              className="bas-itm px-2 py-1  rounded "
              src={shopLight}
              witdh={20}
              alt=""
            />
            <div className="d-flex flex-fill  justify-content-center algin-items-center">
              <div className="text-dark m-0  "> Səbətim</div>
            </div>
          </div>
          {userAuth ? "" :
          <div className="border btn py-2 d-flex col text-center" onClick={()=>{
            setLogin(!login)
          }}>
          <img
            className="px-2 py-1  rounded "
            src={personLight}
            witdh={20}
            alt=""
          />
          <div className="d-flex flex-fill  justify-content-center algin-items-center">
            <div className="text-dark m-0 ">Daxil ol </div>
          </div>
        </div>}
        </div>
        <div
          className="  d-flex btn align-items-center  py-2  text-center mx-2 my-3"
          style={{ backgroundColor: "#BB221A" }}
        >
          <img
            className="px-3 py-1 btn  "
            style={{ backgroundColor: "#A8221B" }}
            src={phoneLight}
            alt=""
          />
          <div className="d-flex flex-fill  justify-content-center" onClick={()=>{
            callOrder()
          }}>
            <div className="text-light m-0 px-2 " >Zəng sifarişi et</div>
          </div>
        </div>

        <ul className=" d-flex flex-column justify-content-end align-items-start list-unstyled mx-2 mt-4 ">
          {navMenu.map(item=>(
              <li className=" btn text-dark py-2 p-0 " key={item.id}>
                  <Link className="text-decoration-none text-dark" to={'/' + item.slug}>{item.name}</Link>
              </li>
          ))}
        
        </ul>

        <div className="text-dark mt-5 pt-5 pb-2 mx-2 py-2 d-flex align-items-center border-top">
          Sifariş üçün:
          <p className="ml-2 my-0 text-danger font-weight-bold h4">*2121</p>
        </div>
      </div>

      
    </nav>
  );
}

export default NavbarWhite;
