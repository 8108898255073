import { useEffect, useRef, useState } from 'react'
import Logo from "./images/logo.svg"
import BGM from "./images/BGM.png"
import SIGN from "./images/SIGN.png"
import ReactToPrint from 'react-to-print';
import AuthService from '../../api/auth/index'
import { MainContext, useContext } from '../Context';
import { Link } from 'react-router-dom';
function Bill() {
    const companyArr = [
        {
            slug: 0,
            text: " Şirkət Adı:",
            subText: "Gədəbəy Mineral Suları MMC",
        },
        {
            slug: 1,
            text: "Ünvan:",
            subText: "Bakı şəhəri, N.Nərimanov rayonu, Ziya Bünyadov prospekti 20.61",
        },
        {
            slug: 2,
            text: "Telefon nömrəsi:",
            subText: "*2121",
        },
        {
            slug: 3,
            text: "Email:",
            subText: "info@slavyanka.az",
        },
        {
            slug: 4,
            text: "Email:",
            subText: "info@gedebeywater.az",
        },
    ]
    const {  orderDetails, setOrderDetails, setDash,billPage } = useContext(MainContext)
    const pdfComponent = useRef();
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const cookieResp = AuthService.checkCookie()
        if (!cookieResp) {
            window.location.href = '/'
            setDash(null)
            return ;
        }
        const path = window.location.href;
        const id = path.split('/')[5]
        const cookie = path.split('/')[4]
        setLoading(true)
        AuthService.getOrderDetail(id, cookie).then(
            r => {
                const response = r.data.data
                
                setOrderDetails(response)
                setLoading(false)
                return response
            }
        )
            .catch(e => {
                window.location.href = '/'
                setLoading(true)
            })
    }, [])
   
    return (
        <div style={{ position: 'relative' }}>
            <div className='link-mpgb'>
            <Link to={'/'} className="link-mpg">Ana səhifə</Link>
            &gt;
            <Link to={'/dashboard'} className="link-mpg">Hesab</Link>
            &gt;
            <Link to={'/dashboard'} className="link-mpg">{billPage}</Link>

            &gt;
            <span className='link-mpg'>Qəbz</span>


            </div>

            <div ref={pdfComponent}>
                <nav className='voh'>
                
                    <div className="info">

                        <div><b> Sifariş Tarixi: </b>{orderDetails && orderDetails.order_date.split('T')[0]}</div>
                        <div><b>Sifariş nömrəsi: </b> {orderDetails && orderDetails.order_no}</div>
                    </div>
                    <img src={BGM} alt="" className="bg-mf" />

                    <img className='voh-logo' src={Logo} alt="logo" />
                </nav>
                <div className='sub-bg'> </div>
                <div className="subnav">

                </div>
                <div className="invoice-text mt-3">Qəbz</div>
                <div className="company">
                    {companyArr.map(info => {
                        return (
                            <div key={info.slug} className="d-flex">
                                <div className="mt">{info.text}</div>
                                <div className="st">{info.subText}</div>



                            </div>
                        )
                    })}

                </div>
                <div className="invoice-text">
                    Çatacaq
                </div>
                <div className="company">

                    <div className={`normal-text pt-2`}><b>Ad Soyad: </b>{orderDetails && orderDetails.name_surname}</div>
                    <div className={`normal-text pt-2`}><b>Ünvan: </b>{orderDetails && orderDetails.shipping.address}</div>
                    <div className={`normal-text pt-2`}><b>Telefon Nömrəsi: </b>{orderDetails && orderDetails.shipping.phone}</div>
                </div>
                <div className="invoice-text">Məhsullar</div>

                {orderDetails ? <div className='row d-flex justify-content-between company'>
                    <div className='text-bold col-5'>
                        {orderDetails && orderDetails.order_products.map(product => {
                            return (
                                <>
                                    <div className={`normal-text pt-2`}><b>Məhsul Adı: </b>{product.name}</div>
                                    <div className={`normal-text pt-2`}><b>Məhsul Qiyməti: </b>{product.price.price}</div>
                                    <div className={`normal-text pt-2`}><b>Məhsul Qiymət Endirimi: </b>{product.price.is_discount}</div>
                                    <div className={`normal-text pt-2`}><b>Məhsul Endirimsiz Qiyməti: </b>{product.price.price_before_discount}</div>
                                    <div className={`normal-text pt-2`}><b>Məhsul :  </b><img width={105} src={product.image.image_small} /></div>
                                </>
                            )
                        })}
                    </div>


                </div> : ""}
                <img src={BGM} alt="" className="bg-m" />
                <img src={SIGN} alt="" className="sign" />
            </div>
            <div className='paymentbill-props'>
                <ReactToPrint
                    trigger={() => <button className='btn dl-bill btn-danger'><span>Endir PDF</span></button>}
                    content={() => pdfComponent.current}
                />
            </div>


        </div>
    )
}

export default Bill