import React from 'react'
import { MainContext, useContext } from '../Context/index'
import IMAGES from '../../helpers/images/images'
function CompleteBook() {
  const { InfoSym, close } = IMAGES
  const { completeBook, setCompleteBook,setRegister,setLogin } = useContext(MainContext)
  const buttonsGroup = [
    {
      text: 'Mövcud hesaba daxil olun',
      subtext: 'Slavyanka.az saytında hesabınız varsa, hesabınıza daxil olun.',
      click:function(){
        setCompleteBook(false)
        return setLogin(true)
      }
    },
    {
      text: 'Qeydiyyatdan keçin',
      subtext: 'Qeydiyyatdan keçin və sifariş məlumatlarınızı yadda saxlayın.',
      click:function(){
        setCompleteBook(false)
       return setRegister(true)
      //  return window.location.href='/'
      }
    },
    {
      text: 'Qeydiyyatsız davam edin',
      subtext: 'Qeydiyyatsız davam edəcəyiniz halda sifariş məlumatlarınız növbəti sifariş üçün aktiv olmayacaq',
      click:function(){
       return window.location.href='/payment'
      }
    },
  ]
  return (
    <>
      <label class="modal-background"></label>
      <div class="modal-container">
        <section className='col-md-8 col-sm-8 col-lg-5 pt-5   col-10 col-sm-9 --nsads'>
          <div className='close' onClick={() => {
            setCompleteBook(!completeBook)
          }}> <img src={close} alt='close' /></div>
          <div>
            <h3 className='text-center'><b>Sifarişi tamamlamaq üçün</b></h3>
            <div className='choices'>
              {
                buttonsGroup.map(buttonItems => {
                  return (
                    <div key={buttonItems.text} className='btn-txt'>
                      <div className='el-center'>
                        <button className="choice-btn col-sm-8 col-11 col-md-9 col-lg-7 btn --bg-green" onClick={()=>{
                          buttonItems.click()
                        }}>
                          <div className='small-solorized'>{buttonItems.text}</div>
                        </button>
                      </div>
                      <div className='el-center'>
                        <div className='col-sm-8 col-11 col-md-9 col-lg-7'>
                          <p><img src={InfoSym} alt="infoSymbol" /> {buttonItems.subtext}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }



            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default CompleteBook