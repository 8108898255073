import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import TopOrder from '../TopOrder/TopOrder';
import Authservice from '../../../api/auth/index';
import { MainContext, useContext } from '../../Context';
export default function TopOrderSlider() {
  const [mainPgProducts, setMainPgProducts] = useState([])

  const { pageLanguage, pageLangData } = useContext(MainContext)
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <div>
      <div className="SampleNextArrow rounded-circle pl-3 pr-3 py-2 d-flex justify-content-center  ">
        <div>
          <svg className='' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.853 0L0 1.853L6.018 7.884L0 13.915L1.853 15.768L9.737 7.884L1.853 0Z" fill="#051934" />
          </svg>
        </div>
      </div>
    </div>,
    prevArrow: <div>
      <div className="SampleNextArrow rounded-circle  pl-3 pr-3 py-2 d-flex justify-content-center  ">
        <div>
          <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.147 16L10 14.147L3.982 8.116L10 2.085L8.147 0.232L0.263 8.116L8.147 16Z" fill="#051934" />
          </svg>
        </div>
      </div>
    </div>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // nextArrow:false,
          // prevArrow:false
        }
      }
    ]
  };

  const data = {
    headers: { "X-Language": pageLanguage }
  }
  useEffect(() => {
    Authservice.getAllProducts(data).then(response => {

      const allProds = response.data.data
      var prodsID = []
      let prodsArray = []
      allProds.map(prods => {

        if (prodsID.indexOf(prods.id) === -1) {
          prodsID.push(prods.id);
          prodsArray.push(prods)
        } else {
          return false
        }
      })
      setMainPgProducts(prodsArray)
      // setFiltered(prodsArray)

    })
  }, [pageLanguage])

  const products = [1, 2, 3, 4, 5, 6, 7, 8]
  return (
    <div>
      <h2 className="text-center" style={{ fontSize: "25px" }}>{"Ən çox sifariş olunan məhsullar"}</h2>
      <Slider {...settings} className='mx-sm-5 px-sm-3 mx-0 px-0 my-3' >

        {mainPgProducts.length === 0 ? products.map((e) => <TopOrder key={e.toString()} id={Math.random()} price={0} slug={''} props={{
          short_description :" ",
          
        }} imgurl={""}></TopOrder>)
        :
          mainPgProducts.map(
            (e) => {
              if(e.is_bestseller){
              return <TopOrder key={e.id} id={e.id} props={e} name={e.name} slug={e.slug} price={e.price} imgurl={e.image} ></TopOrder>

              }
            }
          )
        }

      </Slider>
    </div>
  );
}


