import React,{useRef,useEffect} from 'react'
import helpers from '../../helpers/middleware/formatValues';
import IMAGES from '../../helpers/images/images';
import { MainContext,useContext } from '../Context';
const { close } = IMAGES
function EditCreditCard() {
    const CardNum = useRef()
    const CardExp = useRef()
    const CardCvv = useRef()
    const format = helpers.format
    const {editCreditCardModal,setEditCreditCardModal,cardDetails} = useContext(MainContext)
    const {num,cvv,exp}=(cardDetails)
    useEffect(()=>{
        CardNum.current.value = num
        CardExp.current.value = exp
        CardCvv.current.value = cvv

    })
    return (
        <div>
            <div className='modal'>
                <section className='col-md-8 col-lg-5 col-sm-8  col-10 --nsads'>
                    <div className='close d-flex justify-content-center mt-3'><div onClick={()=>setEditCreditCardModal(!editCreditCardModal)} ><img alt='' src={close}  /></div></div>
                    <form className='mt-3'>
                        <div className='na-text p-3'>Mövcud kart-a düzəliş edin!</div>
                        <div className='mt-3'>
                            <div className='col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                                <input onChange={(e) => {
                                    format(e.target, 4)
                                }} type="tel" maxLength={19}  ref={CardNum} id="inp" className="form-input inp-n_add col-10" placeholder="&nbsp;" />
                                <span className="label">Kartın 16 rəqəmi</span>
                            </label></div>
                            <div className='d-sm-flex d-md-flex d-lg-flex d-block justify-content-between'>
                                <div className='col-md-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                                    <input type="tel" ref={CardExp} onChange={(e) => {
                                        format(e.target, 2)
                                    }} id="inp" maxLength={5} className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                                    <span className="label">Son istifadə tarixi</span>
                                </label></div>
                                <div className='col-md-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-center'><label htmlFor="inp" className="inp inp-ci">
                                    <input onChange={(e) => {
                                        format(e.target, 0)
                                    }} ref={CardCvv} type="tel" id="inp" maxLength={3} className="form-input inp-n_add col-7" placeholder="&nbsp;" />
                                    <span className="label">CVV</span>
                                </label></div>
                            </div>
                            <div className="d-flex mt-3 pb-5 col-12 justify-content-center">
                                <button className="--naddress-edit-btn col-10 btn btn-success">Təsdiqlə</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default EditCreditCard




