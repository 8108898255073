import React, { useRef } from 'react';
import { MainContext, useContext } from '../Context';
import close from '../../assets/img/close.svg'
import Authservice from "../../api/auth/index"
function ForgotEmail() {
    const { forgotEmail, setForgotEmail,emailSent, setEmailSent } = useContext(MainContext);
    const forgot_email = useRef()
    const sendCode =() =>{
            const config = forgot_email.current.value
           Authservice.ForgotEmail(config)
            .then(resp=>{
                if(resp.status === 200){
                    setEmailSent(!emailSent)
            }})
            .catch(resp=>(resp));
          
    }
    return <div>
        <div className='modal'>
            <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                <div onClick={() => { setForgotEmail(!forgotEmail) }} className='close d-flex justify-content-center mt-3'><div><img alt='' src={close} /></div></div>
                <div className='na-text pt-5  p-3'>Şifrənizi bərpa edin</div>
                <div className='na-sub-text  p-3'>E-mail adresinizə şifrənin bərpası üçün uyğun link göndəriləcək</div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <label htmlFor="inp" className="inp inp-ci">
                        <input autoComplete='off' ref={forgot_email} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                        <span className="label">info@slavyanka.az</span>
                    </label>
                </div>
                <div className="d-flex mt-3 pb-4  col-12 ">
                    <button className="--naddress-edit-btn col-12  btn btn-success" onClick={()=>{
                        sendCode()
                        // setEmailSent(!emailSentdmlkmasd
                    }}>Göndər</button>
                </div>
            </section>


        </div>;
    </div>;
}

export default ForgotEmail;
