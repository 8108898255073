import { useEffect, useState } from "react";
import { MainContext, useContext } from "../../Context"
import Dropdown from "../../Custom/Dropdown";
import Authservice from '../../../api/auth/index'
import Slider from "react-slick";
import TopOrder from "../TopOrder/TopOrder";
function MPProducts() {
  const { productAction, setProductAction, pageLanguage, pageLangData } = useContext(MainContext)

  const [mainPgProducts, setMainPgProducts] = useState([])

  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <div>
      <div className="SampleNextArrow rounded-circle pl-3 pr-3 py-2 d-flex justify-content-center  ">
        <div>
          <svg className='' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.853 0L0 1.853L6.018 7.884L0 13.915L1.853 15.768L9.737 7.884L1.853 0Z" fill="#051934" />
          </svg>
        </div>
      </div>
    </div>,
    prevArrow: <div>
      <div className="SampleNextArrow rounded-circle  pl-3 pr-3 py-2 d-flex justify-content-center  ">
        <div>
          <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.147 16L10 14.147L3.982 8.116L10 2.085L8.147 0.232L0.263 8.116L8.147 16Z" fill="#051934" />
          </svg>
        </div>
      </div>
    </div>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // nextArrow:false,
          // prevArrow:false
        }
      }
    ]
  };

 
  useEffect(() => {
    Authservice.getAllProducts(data).then(response => {

      const allProds = response.data.data
      var prodsID = []
      let prodsArray = []
      allProds.map(prods => {

        if (prodsID.indexOf(prods.id) === -1) {
          prodsID.push(prods.id);
          prodsArray.push(prods)
        } else {
          return false
        }
      })
      setMainPgProducts(prodsArray)
      // setFiltered(prodsArray)

    })
  }, [pageLanguage])


  const products = [1, 2, 3, 4, 5, 6, 7, 8]

  const productsItem = [
    {
      slug: 1,
      text: pageLangData["all-products"],
      name: 'All'
    },
    {
      slug: 2,
      text: pageLangData["sparkling"],
      name: 'Carbonated'
    },
    {
      slug: 3,
      text: pageLangData["still"],
      name: 'Gasless'
    },
    {
      slug: 4,
      text: pageLangData.carboy,
      name: 'Bidon'
    },
    {
      slug: 5,
      text: pageLangData.lemonade,
      name: 'Lemonade'
    }
  ]
  const [productsArray, setProductsArray] = useState([])
  const [filtered, setFiltered] = useState([])
  const data = {
    headers: { "X-Language": pageLanguage.slice(0, -1).toLowerCase() }
  }
  useEffect(() => {
    Authservice.getAllProducts(data).then(response => {
      const allProds = response.data.data
      var prodsID = []
      let prodsArray = []
      allProds.map(prods => {

        if (prodsID.indexOf(prods.id) === -1) {

          prodsID.push(prods.id);
          prodsArray.push(prods)

        } else {
          return false
        }
      })
      setProductsArray(prodsArray)
      setFiltered(prodsArray)
    })

  }, [pageLanguage])

  useEffect(() => {


    switch (productAction) {
      case "All":
        setFiltered(productsArray)
        break;
      case "Carbonated":
        const filter = productsArray.filter(r => {
          return r.category_id === 1
        })
        setFiltered(filter)
        break;
      case "Gasless":
        const filter2 = productsArray.filter(r => {
          return r.category_id === 2
        })
        setFiltered(filter2)
        break;
      case "Lemonade":
        const f3 = productsArray.filter(r => {
          return r.category_id === 4
        })
        setFiltered(f3)
        break;
      case "Bidon":
        const f4 = productsArray.filter(r => {
          return r.category_id === 3
        })
        setFiltered(f4)
        break;
      default:
        setFiltered(productsArray);
        break;
    }
  }, [productAction])

  return (
    <>
      <h2 className="text-center m-5" style={{ fontSize: "25px" }}>{pageLangData !== {} ? pageLangData["products"] : "Məhsullar"}</h2>
      <div className='row mt-5'>
        <div className='dropdown-sm-n'>
          <Dropdown pageAction={productsItem} page='Products' />
        </div>
        <div className='products-actions col-12 d-none d-sm-flex justify-content-center' >
          <div className='col-lg-9 col-md-11 col-sm-12'>
            <ul id='products-main'>
              {productsItem.map(item => {
                return (<li key={item.slug} onClick={() => {
                  setProductAction(item.name)
                }} className={item.name === productAction ? 'active products-actions-items' : ' products-actions-items'}>{item.text}</li>)
              })}

            </ul>
          </div>
        </div>
      </div>
      <div className='container'>
      <Slider {...settings} className='mx-sm-5 px-sm-3 mx-0 px-0 my-3' >
      

        {filtered.length === 0 ? products.map((e) => <TopOrder key={e.toString()} id={Math.random()} price={0} old_price={0} slug={''} props={{
          short_description :" ",
          
        }} imgurl={""}></TopOrder>)
        :
          filtered.map(
            (e) => {
              return <TopOrder key={e.id} id={e.id} props={e} name={e.name} slug={e.slug} price={e.price} old_price={e.old_price} is_on_sale={e.is_on_sale} imgurl={e.image} ></TopOrder>
            }
          )
        }

      </Slider>
    </div>
      {/*  return <TopOrder key={e.id} id={e.id} props={e} name={e.name} slug={e.slug} price={e.price} imgurl={e.image} ></TopOrder> */}

    </>
  )
}

export default MPProducts