import React, { useEffect, useState } from 'react'
import FacebookDark from '../assets/img/FacebookDark.svg'
import InstagramDark from '../assets/img/InstagramDark.svg'
import TwitterDark from '../assets/img/TwitterDark.svg'
import YoutubeDark from '../assets/img/YoutubeDark.svg'
import LogoDark from "../assets/img/LogoDark.svg"
import PhoneDark from "../assets/img/PhoneDark.svg"
import AuthService from '../api/auth/index'
import { MainContext, useContext } from '../Components/Context'
import { Link } from 'react-router-dom'
function Footer() {
    const { pageLangData } = useContext(MainContext)
    const footerRoutes = [
        {
            slug: 1,
            text: '© "Gədəbəy Mineral Suları" MMC',
            className: 'mt-3 mx-3 fw-bold',
            link: ''
        },
        {
            slug: 2,
            text: pageLangData !== {} ? pageLangData.home : "Ana Səhifə",
            className: 'mt-3 mx-3',
            link: '/'
        },
        {
            slug: 3,
            text: pageLangData !== {} ? pageLangData.products : 'Məhsullar',
            className: 'mt-3 mx-3',
            link: '/products'
        },
        {
            slug: 4,
            text: pageLangData !== {} ? pageLangData["about-us"] : 'Haqqımızda',
            className: 'mt-3 mx-3',
            link: '/about'
        },
        {
            slug: 5,
            text: pageLangData !== {} ? pageLangData.contact : 'Əlaqə',
            className: 'mt-3 mx-3',
            link: '/contact'
        },
    ]
    const [socialLinks, setSocialLinks] = useState([
        {
            name: "youtube",
            link: "https://www.youtube.com/channel/UCLoW-Oks7nWlC863-IYxabw"
        },
        {
            name: "twitter",
            link: "https://twitter.com/Slavyanka_az"
        },
        {
            name: "instagram",
            link: "https://www.instagram.com/slavyanka_az/"
        },
        {
            name: "facebook",
            link: "https://www.facebook.com/Slavyanka.az"
        }])
    const [website, setWebsite] = useState("slavyanka.az");
    const [wNum, setWNum] = useState("+994551234567")
    const [callCenter, setCallCenter] = useState("*2121")
    const [email, setEmail] = useState("info@slavyanka.az")
    useEffect(() => {
        AuthService.getAppData().then(res => {
            const youtube = res.data.data.youtube
            const twitter = res.data.data.twitter
            const instagram = res.data.data.instagram
            const facebook = res.data.data.facebook
            const social_links = [
                {
                    "link": youtube,
                    "name": "youtube"
                },
                {
                    "link": twitter,
                    "name": "twitter"
                },
                {
                    "link": instagram,
                    "name": "instagram"
                },
                {
                    "link": facebook,
                    "name": "facebook"
                },
            ]
            setSocialLinks(social_links)
            setCallCenter(res.data.data.call_center)
            setEmail(res.data.data.email)
            setWNum(res.data.data.whatsapp)
            setWebsite(res.data.data.website)
        })
    }, []);
    return (
        <footer className='px-md-5'>
            <div className="d-flex  justify-content-center align-items-center ics">
                <div className="footer-logo">
                    <img src={LogoDark} alt="" />
                </div>
            </div>
            <div className="  mt-5  d-flex justify-content-center ">

                <ul className="d-flex px-0 mx-0  justify-content-sm-around flex-md-row  flex-column  fborder w-75">
                    <li className="activity-links my-3 fe-border p-md-0 pb-3 ">{website && website}</li>
                    <li className="activity-links my-3 fe-border p-md-0 pb-3  "><img src={PhoneDark} className="mr-2" alt="phone-icon" /> {pageLangData["order-2121"] !== undefined ? pageLangData["order-2121"].split("*")[0] : "Sifariş üçün"} <b className='contactNum'>{callCenter && callCenter}</b></li>
                    <li className="activity-links my-3 fe-border p-md-0 pb-3  ">{email && email}</li>
                </ul>

            </div>
            <div className="  mt-4 ">

                <ul className=" d-flex flex-md-row  flex-column   justify-content-center align-items-center p-0 py-1 mb-3" >
                    {footerRoutes.map(routes => {
                        return (
                            <li className={routes.className} onClick={() => window.location.pathname = routes.link} key={routes.slug}>{routes.text}</li>
                        )
                    })}
                </ul>

            </div>
            <div className="d-flex justify-content-center  my-4">
                {
                    socialLinks && socialLinks.map(links => {
                        return (<React.Fragment key ={links.link}>
                            <div  style={{ cursor: 'pointer' }} key={Math.random() * new Date(Date.now())} className=" m-2 " onClick={() => window.location.href = links.link}><img width={20} height={20} src={links.name === 'facebook' ? FacebookDark : links.name === 'instagram' ? InstagramDark : links.name === 'youtube' ? YoutubeDark : TwitterDark} alt={links.name} /></div>
                        </React.Fragment>)
                    })
                }


            </div>

        </footer>
    )
}

export default Footer
