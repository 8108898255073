import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/css/style.css";
import { Link } from "react-router-dom";
import AuthService from "../../../api/auth";
import slide1 from "./src/img/slide1.png"
import kuryer1 from "./src/img/kuryer1.png"
import { MainContext, useContext } from "../../Context";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../helpers/middleware/GetWidth";
function Slider() {
  const [webImg ,setWebImg] = useState([])
  const [mobImg,setMobImg] = useState([])
  const [dG,setDG] = useState([])
  useEffect(() => {
    AuthService.getHomepageData("az")
      .then(response => {
        const webs = []
        const mobs = []
        const dgs = []
        response.data.data.slider.map(item=>{
         if(item.text!=="<p>Saf, təbii və sağlam Slavyanka suları<br></p>"){
            webs.push(item.web_image)
            mobs.push(item.mobile_image)
         }else{
          dgs.push(item.web_image)
          dgs.push(item.mobile_image)
         }
         
        })
        setWebImg(webs)
        setDG(dgs)
        setMobImg(mobs)
      })
      .catch(err =>err);

    

  }, [])
  const {width} = useWindowDimensions()
  const { pageLangData } = useContext(MainContext)
  const changeSLide = ()=>{
    if(dG.length === 0 ){
      if(width<577){
        return slide1
      }else{
       return kuryer1
      }
    }
    if(width<577){
      return dG[1]
    }else{
     return dG[0]
    }
  }
  return (
      <div id="carouselExampleIndicators" className="carousel slide"  data-ride="carousel">
        <ol className="carousel-indicators justify-content-start">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item  active" data-interval={6000} >
          <h1 style={{color:"white"}} className='d-sm-none d-block pl-4 ml-4 font-weight-bold  mb-sm-5 pb-sm-5 pt-3 pt-sm-0'>{pageLangData !== {} ? pageLangData["pure-natural-and-healthy-slavyanka-water"] : "Saf, təbii və sağlam Slavyanka sular"}</h1>

            <div className='d-flex  pt-4 mt-4 pt-sm-0 mt-sm-0 justify-content-sm-around mainCOn  pl-sm-5 pl-0' >
              <div className='infos d-sm-flex flex-column pl-5  my-auto text-light'>
                <h1 className='max-width d-sm-block d-none  mb-sm-4 pb-sm-4 pt-4 pt-sm-0'>{pageLangData !== {} ? pageLangData["pure-natural-and-healthy-slavyanka-water"] : "Saf, təbii və sağlam Slavyanka sular"}</h1>
                <p className='max-width mb-sm-4 pb-sm-4 pt-2 pt-sm-0' style={{ opacity: "0.7" }}>{pageLangData !== {} ? pageLangData["order-slavyanka-we-will-deliver-to-your-address-in-just-24-hours"] : "Slavyanka sifariş edin, biz cəmi 24 saat ərzində ünvanınıza çatdırırıq"}</p>
                <div className='mb-5 ml-0  pb-5'>
                  <Link to={'/products'}><button className='btn  text-light px-4 py-2  mb-3 mb-sm-0 mr-3' style={{ backgroundColor: "#3FC964", fontSize: "14px" }}>{pageLangData !== {} ? pageLangData["order"] : "Sifariş et"}</button></Link>
                  <Link to={'/about'}><button className='btn  text-light px-4 py-2 ' style={{ background: " rgba(255, 255, 255, 0.1)", fontSize: "14px" }}>{pageLangData !== {} ? pageLangData["why-slavyanka"] : "Niyə Slavyanka?"}</button></Link>
                </div>
              </div>
              <img className="d-xl-block  deliveryguy-caousel-item img-fluid" style={{objectFit:'contain'}} src={changeSLide()} alt="First slide" />
            </div>
          </div>
          {
           width <577 ? mobImg.map(img=>{
            return <div className="carousel-item carousel-it-cont-sm" data-interval={6000} >
           <div className='d-flex justify-content-around  ' >
             <img  className="d-block w-100 img-fluid" src={img} alt="Lim slide" />
           </div>
         </div>
           }) :  webImg.map(img=><div className="carousel-item   " data-interval={6000} >
            <div className='d-flex  justify-content-around  ' >
              <img  className="d-xl-block w-100   deliveryguy-caousel-item" src={img} alt="Lim slide" />
            </div>
          </div>)
          }
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <div className='carouselcontrol px-2 py-2 rounded-circle d-flex justify-content-center align-items-center hover--arr'>
            <span className="carousel-control-prev-icon " style={{ height: "30px" }} aria-hidden="true"></span>
          </div>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <div className='carouselcontrol px-2 py-2 rounded-circle d-flex justify-content-center align-items-center hover--arr  '>
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
          </div>
          <span className="sr-only">Next</span>
        </a>
      </div>
  );
}
export default Slider;
