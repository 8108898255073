import { useEffect, useState, useRef } from 'react'
import "./css/style.css"
import mbBasketMinus from './img/mbBasketMinus.svg'
import mbBasketPlus from './img/mbBasketPlus.svg'
import mbBasketX from './img/mbBasketX.svg'
import mbBasketBrandX from './img/mbBasketBrandX.svg'
import navBrand from "../../MainPage/NavbarWhite/src/img/LogoDark.svg";
import AuthService from '../../../api/auth/index'
import { MainContext, useContext } from '../../Context'
export default function MpBasketModal() {


    const [loading, setLoading] = useState(false)
    const [basketIng, setBasketIng] = useState([]);
    const { basketModal,setBasketFetch, setBasketModal, basketLength, setBasketLength, cookie, readdress, basketTotalPrice, setBasketTotalPrice } = useContext(MainContext);
    const sessionId = AuthService.getSessionId();
    const [generalArr, setGeneralArr] = useState([])
    const wrapperRef = useRef(null);
    const [cartIDSJSON, setCartIDSJSON] = useState({})
    const showAllBasketProducts = () => {
        setLoading(!loading)
        AuthService.showAllBasketProducts(sessionId)
            .then(resp => {
                const response = resp.data
                setGeneralArr(resp.data)
                return response

            })
            .then(response => {

                let price = 0
                response.map(prods => {
                    price += (prods.price / 100)
                    return price.toFixed(2)
                })
                setBasketTotalPrice(price)

                const counted = response.reduce((acc, pro) => {
                    if (acc['product_id_' + pro.product.product_id] === undefined) {
                        acc['product_id_' + pro.product.product_id] = 1
                    } else {
                        acc['product_id_' + pro.product.product_id] += 1
                    }

                    return acc
                }, {})
                let clearedArr = []
                let proIDS = []
                let cart_IDS = {}
                response.map(product => {
                    if (cart_IDS[`product_cart_id_${product.product.product_id}`] === undefined) {
                        cart_IDS[`product_cart_id_${product.product.product_id}`] = [product.cart_id]
                    } else {
                        cart_IDS[`product_cart_id_${product.product.product_id}`].push(product.cart_id)
                    }
                    // delete product['cart_id']
                    if (proIDS.indexOf(product.product.product_id) === -1) {
                        proIDS.push(product.product.product_id)
                        // 
                        // 


                        product["count"] = counted[`product_id_${product.product.product_id}`]
                        clearedArr.push(product)
                        // 
                        // clearedArr.push(product)

                        // 
                    }


                })

                setBasketIng(clearedArr)
                setCartIDSJSON(cart_IDS)

                setLoading(false);
                setBasketFetch(clearedArr)
            })
            .catch(err => err)
    }



    useEffect(() => {
        showAllBasketProducts()
    }, [basketModal]);

    const decrease = (id) => {
        const cart_ids_Array = cartIDSJSON[`product_cart_id_${id}`]
        try {
            deleteFromBasket(cart_ids_Array[0]);
            setCartIDSJSON(cart_ids_Array.splice(0, 1));
        } catch (er) {
            return er
        }
    }
    const increase = (id) => {
        AuthService.addProductsToBasket(sessionId, id, cookie).then(data => showAllBasketProducts()).catch(err =>(err.response))

    }
    const deleteFromBasket = (id) => {
        // AuthService.deleteFromBasket(sessionId,id,cookie);
        setLoading(!loading)
        AuthService.deleteFromBasket(sessionId,id).then(r => {
            showAllBasketProducts()
        })
    }
    const deleteOneTypeProduct = (props) => {
        const product_id = props.product_id;
        const cart_ids = cartIDSJSON[`product_cart_id_${product_id}`];
        cart_ids.map(cart_id => {
            AuthService.deleteOneTypeProduct(sessionId,cart_id).then(resp => {
                showAllBasketProducts()

            })
                .catch(err => (err))
        })
        // 
    }
    const deleteAllProducts = (e) => {
        generalArr.map(product => {
            // 
            AuthService.deleteAllFromBasket(sessionId,product.cart_id)
                .catch(e => {
                    alert(e.response.data.data[0].errors)
                    alert(JSON.stringify(e.response))

                })

                .then(r => {

                    showAllBasketProducts()
                })


            // AuthService.deleteAllFromBasket(sessionId,product.cart_id,cookie).then(response=>showAllBasketProducts());
        })
    }
    return (

        <div ref={wrapperRef} className={`bas-itm mpBasketModal`} style={basketLength === 0 ? { height: 500 } : { height: 630 }} >
            {loading ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : ""}
            <div className='bas-itm d-flex align-items-center justify-content-between mb-5 d-block d-sm-none'>
                <img className='bas-itm' src={navBrand} alt="foto"></img>
                <img className='bas-itm' src={mbBasketBrandX} onClick={() => {
                    setBasketModal(!basketModal)
                }} alt="foto"></img>
            </div>
            <div className='bas-itm mpBasketControl'>
                <div style={{ fontWeight: "bold", fontSize: 18 }}>Məhsul Sayı : {basketLength}</div>

                {basketIng.map((tempItem) => {

                    return (
                        <div key={tempItem.cart_id} className='bas-itm d-flex mb-3 '>
                            <img src={tempItem.product.main_image.url} alt='foto' className="bas-itm mr-4 img324324" style={{ borderRadius: "5px" }} ></img>
                            <div className='bas-itm '>
                                <div className='bas-itm d-flex align-items-center'>
                                    <div className='bas-itm mr-3 mt-1 font-weight-bold' style={{ fontSize: "15px" }}>{tempItem.product.name}</div>
                                    <img className='bas-itm' src={mbBasketX} alt='foto' onClick={() => {
                                        deleteOneTypeProduct(tempItem.product)
                                    }} width={"28px"} height={"28px"} style={{ cursor: 'pointer' }}></img>
                                </div>
                                <div className='bas-itm mb-4' style={{ color: "#929CAA", fontSize: "15px" }}>Qazsız</div>
                                <div className='bas-itm d-flex align-items-center'>
                                    <div onClick={() => { return loading ? "" : decrease(tempItem.product.product_id) }} className='bas-itm pm-ops d-flex align-items-center justify-content-center ' style={{ backgroundColor: "#fafafa", width: "30px", height: "30px", borderRadius: "5px" }} >
                                        <img className='bas-itm pm-ops' src={mbBasketMinus} alt="foto" ></img>
                                    </div>

                                    <div className='bas-itm mx-3 font-weight-bold' style={{ fontSize: "14px" }}>{tempItem.count}</div>

                                    <div onClick={() => { return loading ? "" : increase(tempItem.product.product_id) }} className='bas-itm pm-ops text-light d-flex align-items-center justify-content-center' style={{ width: "30px", height: "30px", borderRadius: "5px", backgroundColor: "#BB221A" }}>
                                        <img className='bas-itm pm-ops' src={mbBasketPlus} alt="foto" width={"10px"} height={"10px"} ></img>
                                    </div>

                                    <div className='bas-itm ml-4 font-weight-bold' style={{ fontSize: "15px" }} >{Number.parseFloat(tempItem.price * tempItem.count / 100).toFixed(2)} AZN</div>

                                </div>
                            </div>

                        </div>
                    );

                })}

            </div>
            <div className='bas-itm mpBasketDown '>
                <div className='bas-itm py-3 mb-1 d-flex justify-content-center align-items-center border' style={{ borderRadius: "10px", backgroundColor: "#fafafa" }}>
                    <div className='bas-itm mr-2' style={{ fontSize: "14px" }}>Cəmi məbləğ:</div>
                    <div className='bas-itm font-weight-bold' style={{ fontSize: "15px" }}>{Number.parseFloat(basketTotalPrice).toFixed(2)} AZN</div>
                </div>
                {
                    basketTotalPrice !== 0 ? <>
                        <div className='bas-itm mt-2 py-3 d-flex justify-content-center align-items-center bg-red' onClick={(e) => deleteAllProducts(e)} style={{ borderRadius: "10px", cursor: "pointer" }} >
                            Səbəti Təmizlə
                        </div>
                        <a href={'/basket'} className='bas-itm text-light ' style={{ textDecoration: "none", }}>
                            <div className='bas-itm mt-2  py-3 d-flex justify-content-center align-items-center text-light ' style={{ borderRadius: "10px", backgroundColor: "#3FC964", cursor: "pointer" }} >
                                Sifariş et
                            </div>
                        </a>
                    </> : ""
                }

            </div>


        </div>
    )
}
