import React, { useEffect, useRef, useState } from 'react';
import IMAGES from '../../helpers/images/images';
import { MainContext, useContext } from '../Context';
import AuthService from '../../api/auth/index';
import axios from 'axios';
function ChooseAddres() {
    const { chooseAddress, shippingProps, setSuccessMessage, cash,setChooseAddress, cookie, setUserShippingAddress, userShippingAddress } = useContext(MainContext);
    const [adress, setAddress] = useState([])
    useEffect(()=>{
        AuthService.getAllAddresses(cookie)
        .then(r => setAddress(r.data.data))
        .catch(e => {
            window.location.href ="/";
            AuthService.deleteCookie();
            
        })
    },[])
    const { close } = IMAGES;
    const addresses = useRef();
    const continues = (e) => {
        e.preventDefault();
        setUserShippingAddress(addresses.current.value)
        shippingProps["shipping_address_id"] = addresses.current.value
        if(!cash){
            AuthService.createOnlineOrderAuthorized(shippingProps,cookie)
            .then(r => { window.location.href = r.data.data.payment_page })
            .catch(e => { return e })
        }else{
            AuthService.createCashOrderAuthorized(shippingProps,cookie)
            .then(r => {
                setChooseAddress(!chooseAddress)
                setSuccessMessage(true)})
            .catch(e => { return e })
        }

    }
    return (
        <>
            <div className='modal'>
                <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                    <div className='close d-flex justify-content-center mt-3' onClick={() => { setChooseAddress(!chooseAddress) }}><div>
                        <img alt='close' src={close} /></div></div>
                    <div className='d-flex col-12 pt-3 justify-content-center'>
                        <select ref={addresses} name="" className='col-12' id="arra" >Nahardan əvvəl, 09:00-13:00
                            {adress && adress.map(el => {
                                return (
                                    <option key={el.id} value={el.id}>{el.name}</option>
                                )
                            })

                            }

                        </select>

                    </div>
                    <div className="d-flex mt-3 pb-3  col-12 justify-content-center">
                        <button className="--naddress-edit-btn col-10 btn btn-success" onClick={(e) => {
                            continues(e)
                        }}>Davam et!</button>
                    </div>
                </section>

            </div>

        </>
    )
}

export default ChooseAddres