import React, { useEffect, useState } from 'react'
import IMAGES from '../../helpers/images/images'
import { MainContext, useContext } from '../Context/index'
import NavbarWhite from '../MainPage/NavbarWhite/NavbarWhite'
import CompleteModal from '../Modals/CompleteBook';
import AuthService from '../../api/auth/index';
import Footer from '../../Partials/Footer'
import { useNavigate } from 'react-router-dom';


function Basket() {
    const navigate = useNavigate()

    const getCurrentDate = (separator = '-') => {

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }
    const getTime = (seperator = ':') => {
        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        return `${hours < 10 ? `0${hours}` : `${hours}`}${seperator}${minutes < 10 ? `0${minutes}` : `${minutes}`}`
    }
    const { TrashBox } = IMAGES
    const { completeBook, basketFetch, setShippingProps, basketTotalPrice, setBasketTotalPrice, setCash, dash, setCompleteBook, addressModal, setAddressModal, chooseAddress, setChooseAddress } = useContext(MainContext);
    const [basketProducts, setBasketProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [cartIDSJSON, setCartIDSJSON] = useState({})
    const [userAddress, setUserAddress] = useState(false)
    const sessionId = AuthService.getSessionId()

    useEffect(() => {
        getUserAddress()
    }, [addressModal])
    useEffect(() => {
        showAllBasketProducts()

        getUserAddress()

    }, [])
    useEffect(() => {
        showAllBasketProducts()
    }, [basketFetch])
    const cookie = AuthService.getCookie()[0]

    const CashComplete = () => {

        if (!dash) {
            setCash(true)
            navigate('/payment')

        } else {
            let cart_id_arr = []
            Object.values(cartIDSJSON).map(elem => {
                elem.map(x => cart_id_arr.push(x))
            })
            let config = {
                "cart": cart_id_arr,
                "shipping_date": getCurrentDate(),
                "shipping_time": getTime(),
            };
            if (userAddress.length === 0) {

                setAddressModal(true)
            } else {
                setCash(true)
                setShippingProps(config)
                setChooseAddress(!chooseAddress)
            }
        }
    }
    const getUserAddress = () => {

        AuthService.getUserAddress(cookie)
            .then(resp => {
                setUserAddress(resp.data.data)
            })

    }








    const showAllBasketProducts = () => {
        setLoading(!loading)
        AuthService.showAllBasketProducts(sessionId)
            .then(resp => {
                const response = resp.data
                return response
            })
            .then(response => {
                let price = 0
                response.map(prods => {
                    price += (prods.price / 100)
                    return price.toFixed(2)
                })
                setBasketTotalPrice(price)
                const counted = response.reduce((acc, pro) => {
                    if (acc['product_id_' + pro.product.product_id] === undefined) {
                        acc['product_id_' + pro.product.product_id] = 1
                    } else {
                        acc['product_id_' + pro.product.product_id] += 1
                    }

                    return acc
                }, {})
                let clearedArr = []
                let proIDS = []
                let cart_IDS = {}
                response.map(product => {
                    if (cart_IDS[`product_cart_id_${product.product.product_id}`] === undefined) {
                        cart_IDS[`product_cart_id_${product.product.product_id}`] = [product.cart_id]
                    } else {
                        cart_IDS[`product_cart_id_${product.product.product_id}`].push(product.cart_id)
                    }
                    // delete product['cart_id']
                    if (proIDS.indexOf(product.product.product_id) === -1) {
                        proIDS.push(product.product.product_id)
                        // 
                        // 


                        product["count"] = counted[`product_id_${product.product.product_id}`]
                        clearedArr.push(product)
                        // 
                        // clearedArr.push(product)

                        // 
                    }


                })

                setBasketProducts(clearedArr)
                setCartIDSJSON(cart_IDS)
                setLoading(false);
            })
            .catch(err => err)
    }


    const decrease = (id) => {
        const cart_ids_Array = cartIDSJSON[`product_cart_id_${id}`]
        try {
            deleteFromBasket(cart_ids_Array[0])
            setCartIDSJSON(cart_ids_Array.splice(0, 1))


        } catch (error) {
        }
    }
    const increase = (id) => {
        AuthService.addProductsToBasket(sessionId, id, cookie).then(data => showAllBasketProducts()).catch(err => err)
    }
    const deleteFromBasket = (id) => {
        AuthService.deleteFromBasket(sessionId, id).then(r => {
            let price = 0

            r.data.map(prods => {
                price += prods.price / 100
                return price
            })
            showAllBasketProducts()
            setLoading(false)
        })
    }
    const deleteOneTypeProduct = (props) => {
        // 
        const product_id = props.product_id;
        const cart_ids = cartIDSJSON[`product_cart_id_${product_id}`]

        // 
        setLoading(true)
        cart_ids.map(cart_id => {
            AuthService.deleteOneTypeProduct(sessionId, cart_id).then(resp => {

                showAllBasketProducts()
            })
                .catch(err => err)
        })
        // 
    }
    const CompleteMethod = () => {
        if (!dash) {
            setCompleteBook(!completeBook);
            setCash(false)
        } else {
            let cart_id_arr = []
            Object.values(cartIDSJSON).map(elem => {
                elem.map(x =>
                    cart_id_arr.push(x)
                )
            })
            let config = {
                "cart": cart_id_arr,
                "shipping_date": getCurrentDate(),
                "shipping_time": getTime(),
            };

            if (userAddress.length === 0) {

                setAddressModal(true)
            } else {
                setCash(false)
                setShippingProps(config)
                setChooseAddress(!chooseAddress)
            }

        }
    };
    return (
        <>
            {completeBook ? <CompleteModal /> : <></>}

            <NavbarWhite />

            <div id='BasketContainer' className='mt-3 w-100'>
                <div>
                    <main className="d-md-flex d-block justify-content-center">
                        <div className='col-md-6 m-0 p-0 col-lg-6 basket-content '>
                            {loading ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : ""}

                            <h1>Səbət</h1>
                            <div className=" d-flex justify-content-center">
                                <div className='basket mt-2 w-100 m-0 p-0'>
                                    {
                                        basketProducts.map(product => {
                                            return (
                                                <div key={product.cart_id} className="basket-product d-sm-flex d-block justify-content-between">
                                                    <div className='d-flex p-0 m-0 col-6'>
                                                        <div className="basket-product-img">
                                                            <img width='80px' src={product.product.main_image.url} alt="PeoductImg" />
                                                        </div>
                                                        <div className="basket-product-details">
                                                            <div className="product-name">{product.productName}</div>
                                                            <div className="product-count"><span className="solorized">Say: </span>{product.count}</div>
                                                            <div className="product-total-amount"><span className="solorized">Ümumi məbləğ:</span>{Number.parseFloat((product.price / 100) * product.count).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="buttons">
                                                        <div className="pmcounter d-flex justify-content-between align-items-center">
                                                            <div className='d-flex eMS justify-content-sm-between justify-content-none align-items-center'>
                                                                <div onClick={() => { decrease(product.product.product_id) }} className="counter el-center">-</div>
                                                                <div className="counter-num">{product.count}</div>
                                                                <div onClick={() => { increase(product.product.product_id) }} className="counter el-center">+</div>
                                                            </div>
                                                        </div>
                                                        <div className="delete-from-basket" onClick={() => {
                                                            deleteOneTypeProduct(product.product)
                                                        }}>
                                                            <div className="icon"><img src={TrashBox} alt="" /></div>
                                                            <span className='pl-2 cpointer'>Səbətdən sil</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                        <div id="basketPrice" className=' col-lg-4 m-0 p-0 '>
                            <div className="basket-amount mt-3">
                                <h1>Yekun</h1>
                                <div className="d-flex justify-content-center">
                                    <div className="total mt-2 p-0 m-0 col-12 d-flex justify-content-center">
                                        <div className="col-12">
                                            <div className="mt-5">
                                                <b>Promotion kod</b>

                                            </div>
                                            <div className="d-flex mt-2">

                                                <input type="text" placeholder='Nümunə:Sj9F7yT' className="form-control basket-promo-code" />
                                                <button className="btn --bg-green">Daxil et</button>
                                            </div>
                                            <div className="--pborder solorized">
                                                Əgər promokod varsa, zəhmət olmasa daxil edin
                                            </div>
                                            <div className="subtotal mt-5 ">
                                                <div>Subtotal</div>
                                                <div>{basketTotalPrice && Number.parseFloat(basketTotalPrice).toFixed(2)}</div>
                                            </div>
                                            <div className="promo-code-discount">
                                                <div>Promo kod endirimi</div>
                                                <div>0.00AZN</div>
                                            </div>
                                            <div className="cargo">
                                                <div>Çatdırılma</div>
                                                <div>0.00AZN</div>
                                            </div>
                                            <div className="edv">
                                                <div>ƏDV</div>
                                                <div>0.00AZN</div>
                                            </div>
                                            <div className="total">
                                                <div>Total: </div>
                                                <div>{Number.parseFloat(basketTotalPrice).toFixed(2)}</div>
                                            </div>
                                            {basketTotalPrice > 0 ?
                                                <>
                                                    <button onClick={() => {
                                                        CompleteMethod()
                                                    }} className="book-btn btn w-100 mt-4 --bg-green">
                                                        Sifarişi online  tamamla
                                                    </button>
                                                    <button onClick={() => {
                                                        CashComplete()
                                                    }} className="book-btn btn w-100 mt-4 --bg-green">
                                                        Sifarişi nəğd tamamla
                                                    </button> </> : ""}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </main>
                </div>
            </div><Footer /></>
    )

}

export default Basket