import React, { useRef, useState } from 'react';
import { MainContext, useContext } from '../Context';
import close from '../../assets/img/close.svg'
function Order() {
    const { giveOrder, setGiveOrder, setBookReceived } = useContext(MainContext)
    const order_name = useRef()
    const order_surname = useRef()
    const order_phone = useRef()
    const order_address = useRef()
    const order_date = useRef()
    const order_clock = useRef()
    return <div className='modal'>
        <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
            <div className='close d-flex justify-content-center mt-3'><div onClick={() => { setGiveOrder(!giveOrder) }}><img alt='' src={close} /></div></div>
            <div className='na-text pt-5  p-3'>Sifariş forması</div>
            <div className='na-sub-text  p-3'>Məlumatlarınızı daxil edərək sifarişi rəsmiləşdirin.</div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={order_name} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">Ad</span>
                </label>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={order_surname} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">Soyad</span>
                </label>
            </div>
            <div className="d-flex col-12 pt-3 justify-content-center">
                <label htmlFor="inp" className="inp inp-ci">
                    <input autoComplete='off' ref={order_phone} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                    <span className="label">Mobil nömrə</span>
                </label>
            </div>
            <div className='d-flex col-12 pt-3 justify-content-center'>
                <select ref={order_address} name="" className='col-12' id="arra" >
                    <option value="1">Adres1</option>
                    <option value="2">Adres2</option>
                    <option value="2">Adres3</option>
                </select>
            </div>
            <div className="d-sm-flex d-block col-12 pt-3 justify-content-center">
                <div className="d-flex ch-inp ">
                    <label for="inp" class=" alt-inp inp inp-ci m-0 p-0">
                        <input autoComplete='off' type="date" id="inp" maxLength={10} placeholder="&nbsp;" />
                        <span class="label">Tarix</span>
                    </label>
                </div>
                <div className="d-flex ch-inp mt-sm-0 mt-3">
                    <label for="inp" class=" alt-inp inp inp-ci m-0 p-0">
                        <input autoComplete='off' type="time" className='sample-de' id="inp" maxLength={5} placeholder="&nbsp;" />
                        <span class="label">Saat</span>
                    </label>

                </div>

            </div>
            <div className="d-flex mt-3 pb-5  col-12 justify-content-center">
                <button className="--naddress-edit-btn col-10 btn btn-success" onClick={() => { setBookReceived(true) }}>Sifarişi təsdiqlə</button>
            </div>


        </section>


    </div>;
}

export default Order;
