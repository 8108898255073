import { MainContext,useContext } from '../../Context'
import "./css/style.css"
function SidePopUpModal({state,msg}) {
  const {setSidePopUp} = useContext(MainContext)
  return (
    <div onClick={()=>{
    setSidePopUp(false)}}>
      <div  className={state === 1 ? "sbg popUpContainer" : "ebg popUpContainer"}>
        <div className="text">{msg}</div>
      </div>

        
    </div>
  )
}

export default SidePopUpModal