import React from 'react';
import { MainContext,useContext } from '../../Context';
import Login from '../../Modals/Login';
import Signup from './../../Modals/Signup';
import MyInfo from './MyInfo';
import ForgotEmail from './../../Modals/ForgotEmail';
import ChangePassword from '../../Modals/ChangePassword';
import EmailSent from '../../Modals/EmailSent';
import BookReceived from './../../Modals/BookRecieved';
function MI_index() {
  const  {register,login,forgotEmail,changePassword,emailSent,bookReceived} =  useContext(MainContext)
  return (
      <>
        <>
        { bookReceived ? <><BookReceived/><MyInfo/></>:emailSent ? <><EmailSent/><MyInfo/></> :changePassword ? <><ChangePassword/><MyInfo/></> : forgotEmail ? <><ForgotEmail/><MyInfo/></>:login ? <> <Login/><MyInfo/></> : register ? <><Signup/><MyInfo/></> : <MyInfo/>}
      </>
      </>
  )
}

export default MI_index;