import React, { useState } from 'react'
import IMAGES from '../../helpers/images/images'
import { MainContext, useContext } from '../Context'
import Authservice from '../../api/auth/index'

function AddBasketModal() {
  
  const { addBasketModal, setAddBasketModal, basketProps, cookie } = useContext(MainContext)
  const sessionId = Authservice.getSessionId()
  const { simpleProduct, close } = IMAGES
  const [productTotalPrice,setProductPriceTotal] = useState(basketProps.price/100)
  let [count, setCount] = useState(1)

  const increase =()=>{
     setCount(count += 1) ;
     setProductPriceTotal(Number.parseFloat(basketProps.price/100*count).toFixed(2))
  }
  const decreas=()=>{
    if(count>1){
      setCount(count-=1)
    }
    setProductPriceTotal(Number.parseFloat(basketProps.price/100*count).toFixed(2))
  }
  const addToBasket = () => {
    for(let c= 0;c<count;c++){
      Authservice.addProductsToBasket(sessionId, basketProps.id, cookie)
    }
    setAddBasketModal(false)
       
  }
  return (
    <>
      <label className="modal-background"></label>
      <div className="modal-container">
        <section className='col-md-8 col-sm-8 col-lg-5 pt-5   col-10 col-sm-9 --nsads'>
          <div className='close' onClick={() => {
            setAddBasketModal(!addBasketModal)
          }}> <img src={close} alt='close' /></div>
          <div className='add-basket-modal-container'>
            <div className='d-sm-flex d-block'>
              <div className='adm-img h-100'>
                <img src={basketProps.image} width={180} className='h-100' alt="product" />
              </div>
              <div className='adm-info w-100 ml-sm-3 ml-0'>
                <div className="adm-product-name p-2"><b>{ basketProps && basketProps.short_description}</b></div>
                <div className="adm-product-type p-2">{ basketProps && basketProps.category}</div>
                <div className="adm-buttons col-12 p-0 m-0 d-flex p-2 mt-4 justify-content-between">
                  <div className='d-flex align-items-center  justify-content-between'>
                  <div className="adm-minus el-center" onClick={() => decreas()}>-</div>

                    <div className="adm-count el-center"><b>{count}</b></div>
                    <div className="adm-plus el-center" onClick={() => increase()}>+</div>

                  </div>
                  <div className="adm-price el-center"><b>{ basketProps && Number.parseFloat(productTotalPrice).toFixed(2)} AZN</b></div>
                </div>
                <button className="adm-add-btn mt-4 mb-3 btn --bg-green w-100" onClick={(e) => {
                  addToBasket(e)
                }}>
                  Səbətə at
                </button>
              </div>
            </div>

          </div>
        </section>


      </div>




    </>
  )
}

export default AddBasketModal