import React from 'react';
import { MainContext,useContext } from '../../Context';
import Login from '../../Modals/Login';
import Signup from './../../Modals/Signup';
import MyBooks from './MyBooks';
import ForgotEmail from './../../Modals/ForgotEmail';
import ChangePassword from '../../Modals/ChangePassword';
import EmailSent from '../../Modals/EmailSent';
import BookReceived from './../../Modals/BookRecieved';
function MB_index() {
  const  {register,login,forgotEmail,changePassword,emailSent,bookReceived} =  useContext(MainContext)
  return (
      <>
        { bookReceived ? <><BookReceived/><MyBooks/></>:emailSent ? <><EmailSent/><MyBooks/></> :changePassword ? <><ChangePassword/><MyBooks/></> : forgotEmail ? <><ForgotEmail/><MyBooks/></>:login ? <> <Login/><MyBooks/></> : register ? <><Signup/><MyBooks/></> : <MyBooks/>}
      </>
  )
}

export default MB_index;