import React, { useRef, useState } from 'react'
import { MainContext,useContext } from '../Context'
import IMAGES from '../../helpers/images/images'
import AuthService from '../../api/auth/index'
function CallOrderModal() {
    const {close} = IMAGES
    const name = useRef()
    const surname = useRef()
    const mobile = useRef() 
    const [err,setERR] = useState("")
    const {setCallOrderModal,callOrderModal,successMessage,setSuccessMessage} = useContext(MainContext)
    const userCallOrder=(e)=>{
        e.preventDefault();
        const data ={
            "name": name.current.value,
            "surname": surname.current.value,
            "mobile": `994${mobile.current.value}`
        }
       
        AuthService.orderByPhone(data).then(r=>{
            setCallOrderModal(!callOrderModal);
            setSuccessMessage(!successMessage);
        }).catch(Error=>setERR("Məlumatların düzgün olduğuna əmin olun!"));
    }
    const orderTagData =[
        {
            label:"Adınız",
            ref :name,
            type:"text",

        },
        {
            label:"Soyadınız",
            ref:surname,
            type:"text",
        },
        {
            label:"Telefon",
            ref:mobile,
            type:"tel",
        }
    ]
    return <div className='modal'>
    <section className='col-md-8 col-sm-8 col-lg-5  col-11 mt-4  col-sm-9 --nsads'>
        
        <div className='close d-flex justify-content-center mt-3' onClick={() => { setCallOrderModal(!callOrderModal) }}><div ><img alt='' src={close} /></div></div>
        <div className='na-text pt-5  p-2'>Müvafiq xanalara məlumatları daxil edin</div>
        {err ? <div className='na-sub-text   p-2' style={{fontSize:18,color:'#ed2939'}}>{err}</div> :""}
        <form>
            {orderTagData.map(element => 
                element.type === 'tel' ? 
                <div className='col-12 d-flex justify-content-center align-items-center pb-2'>
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>
                                <span className='const' style={{ marginTop: 1 }}>+994</span>
                                <input ref={element.ref} maxLength={9} onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}  type='tel' placeholder='xx xxx xx xx' style={{ outline: 'none', border: 'none', height: 65, width: '100%', padding: 0, paddingLeft: 5, margin: 0 }}></input>

                            </div>

                        </div> :
                
                
                 (
                    <div key={Math.random() * Date.now()} className="d-flex col-12 pt-3 justify-content-center">
                        <label htmlFor="inp" className="inp inp-ci">
                            <input autoComplete='off' ref={element.ref} type={element.type} id="inp" className="form-input col-12" placeholder="&nbsp;" />
                            <span className="label">{element.label}</span>
                        </label>
                    </div>
                )
            )}
            <div className="d-flex mt-1 pb-3 mb-2   col-12 justify-content-center">
                <button className="--naddress-edit-btn  col-10 btn btn-success" onClick={(e) => {
                    userCallOrder(e)
                }}>Sifariş et!</button>
            </div>
        </form>


    </section>


</div>;
}

export default CallOrderModal