import React, { useRef, useState } from 'react'
import NavbarWhite from '../MainPage/NavbarWhite/NavbarWhite'
import { useEffect } from 'react'
import { MainContext, useContext } from '../Context'
import AuthService from '../../api/auth/index';
import { useNavigate } from 'react-router-dom'
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Calendar from "../../assets/img/Calendar.svg"
import Clock from "../../assets/img/Clock.svg"

function Payment() {
    const checkFields = (name, surname, mobile, email, address, ) => {  // u_date, u_time --- api not response
        const symbols = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (let c of (mobile.value.trim())) {
            if (symbols.indexOf(c) === -1) {
                return "Telefon nömrəsi sadəcə rəqəmlərdən ibarət olmalıdır"
            }
        }
        if (name.value.trim() === "") {
            return "Ad doğru deyil"

        }
        else if (name.value.trim().length < 3) {
            return "Ad minimum 3 simvoldan ibarət olmalıdır "
        }
        else if (surname.value.trim().length < 3) {
            return "Soyad minimum 3 simvoldan ibarət olmalıdır "
        }
        else if (surname.value.trim() === "") {
            return "Soyad doğru deyil"
        }
        else if (mobile.value.trim() === "" || mobile.value.trim().length < 9) {
            return ("Telefon nömrəsi doğru deyil");
        }
        else if (email.value.trim() === "") {
            return ("Email doğru deyil");
        }
        else if (address.value.trim() === "") {
            return ("Address doğru deyil");
        } else if (address.value.length < 11) {
            return ("Address minimum 10 simvoldan ibarət olmalıdır")
        }
        // else if (u_date === "") {
            
        //     return "Tarix doğru deyil"
        // }
        // else if (u_time === "") {
            
        //     return "Saat doğru deyil"
        // }
        else {
            return "AllRight"
        }
    };
    const { setSuccessMessage, setSidePopUp, setSidePopUpMsg, setSidePopUpState, cash, } = useContext(MainContext)
    const [prPrice, setPrPrice] = useState(0)
    const [prod, setProd] = useState([])
    const [loading, setLoading] = useState(false)
    const [cartIDSJSON, setCartIDSJSON] = useState({})
    const session_id = AuthService.getSessionId();
    // const today = Date.now();
    // const today_state_date = new Date(today).toJSON().split('T')[0].split("-")
    // const result_tstate = today_state_date[0] + "-" + today_state_date[1] + "-" + today_state_date[2]
    const errorOccured = () => {
        setSidePopUpMsg("Xəta baş verdi.Məlumatların düzgünlüyünü yoxlayın");
        setSidePopUpState(0)
        setSidePopUp(true)
    }
    const recieveBook = () => {
        const response = checkFields(u_name.current, u_uname.current, u_phone.current, u_email.current, u_address.current, ); // u_date, u_time ---- api not response
        if (response === 'AllRight') {

            let cart_id_arr = []
            Object.values(cartIDSJSON).map(elem => {
                elem.map(id => {
                    cart_id_arr.push(id);
                })
            })
            const config = {
                "session_id": session_id,
                "cart": cart_id_arr,
                // "shipping_date": u_date,
                // "shipping_time": u_time,
                "customer": {
                    "name": u_name.current.value,
                    "surname": u_uname.current.value,
                    "mobile": `994${u_phone.current.value}`,
                    "email": u_email.current.value,
                    "shipping_zone_id": 2,
                    "address": u_address.current.value
                }
            }
            if (cash) {
                AuthService.createCashOrder(config).then(r => {
                    setSuccessMessage(true)
                })
                    .catch(e => {
                        let result;
                        try {
                            result = (e.response.data.data[0].errors[0])
                        } catch (error) {
                            errorOccured()
                        }
                        if (result === "The customer.email must be a valid email address.") {
                            setSidePopUpMsg("Email doğru deyil");
                            setSidePopUpState(0)
                            setSidePopUp(true)
                        } else {
                            errorOccured()
                        }
                    })
            }
            else {
               AuthService.createOnlineOrder(config).then(r => {
                    window.location.href = r.data.data.payment_page
                })
                    .catch(e => {

                        let result;
                        try {
                            result = (e.response.data.data[0].errors[0])
                        } catch (error) {
                            errorOccured()
                        }
                        if (result === "The customer.email must be a valid email address.") {
                            setSidePopUpMsg("Email doğru deyil");
                            setSidePopUpState(0)
                            setSidePopUp(true)
                        } else {
                            errorOccured()
                        }

                    })


            }

        }
        else {
            setSidePopUpMsg(response);
            setSidePopUpState(0)
            setSidePopUp(true)
        }


    }
    const navigate = useNavigate()
    useEffect(() => {
       AuthService.showAllBasketProducts(session_id)
            .then(resp => {
                const response = resp.data
                return response
            })
            .then(response => {
                let price = 0
                response.map(prods => {
                    price += (prods.price / 100)
                    return price.toFixed(2)
                })
                setPrPrice(price)
                const counted = response.reduce((acc, pro) => {
                    if (acc['product_id_' + pro.product.product_id] === undefined) {
                        acc['product_id_' + pro.product.product_id] = 1
                    } else {
                        acc['product_id_' + pro.product.product_id] += 1
                    }

                    return acc
                }, {})
                let clearedArr = []
                let proIDS = []
                let cart_IDS = {}
                response.map(product => {
                    if (cart_IDS[`product_cart_id_${product.product.product_id}`] === undefined) {
                        cart_IDS[`product_cart_id_${product.product.product_id}`] = [product.cart_id]
                    } else {
                        cart_IDS[`product_cart_id_${product.product.product_id}`].push(product.cart_id)
                    }
                    if (proIDS.indexOf(product.product.product_id) === -1) {
                        proIDS.push(product.product.product_id)


                        product["count"] = counted[`product_id_${product.product.product_id}`]
                        clearedArr.push(product)

                    }


                })

                setProd(clearedArr)
                setCartIDSJSON(cart_IDS)
                setLoading(false);
            })
            .catch(err => err)
    }, [])
    const u_name = useRef()
    const u_uname = useRef();
    const u_phone = useRef()
    // const [u_date, setU_Date] = useState(result_tstate)
    // const [u_time, setU_Time] = useState("15:00")
    const u_notes = useRef()
    const u_email = useRef()
    const u_address = useRef()
    // const fp_date = useRef(null);
    // const fp_time = useRef(null);
    const userInfo = [
        {
            text: 'Ad',
            slug: 1,
            ref: u_name
        },
        {
            text: 'Soyad',
            slug: 2,
            ref: u_uname
        },
        {
            text: 'Mobil nömrə',
            slug: 3,
            ref: u_phone
        },
        {
            text: 'Email',
            slug: 4,
            ref: u_email
        },
        {
            text: 'Adres - rayon, qəsəbə, küçə, bina, blok, ev',
            slug: 5,
            ref: u_address
        },
    ]
    return (
        <>
            <NavbarWhite />
            <section className='mt-4 d-flex m-0 p-0 justify-content-center' id='payment-content'>
                <div className='container d-lg-flex d-md-flex d-block justify-content-center m-0 p-0'>
                    <div className='basket-content m-0 p-0 col'>
                        <h1>Səbət</h1>
                        <div className=" d-flex justify-content-center">
                            <div className='basket mt-2 col-12'>
                                {
                                    prod && prod.map(product => {
                                        return (
                                            <div key={product.product.main_image.url} className="basket-product d-flex">
                                                <div className="basket-product-img">
                                                    <img src={product.product.main_image.url} width='50' alt="PeoductImg" />
                                                </div>
                                                <div className="basket-product-details">
                                                    <div className="product-name">{product.product.name}</div>
                                                    <div className="product-count"><span className="solorized">Say: </span>{product.count}</div>
                                                    <div className="product-total-amount"><span className="solorized mr-2">Ümumi məbləğ:</span>{((product.price / 100) * product.count).toFixed(2)}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="basket-amount mt-3">
                            <h1>Yekun</h1>
                            <div className="d-flex justify-content-center">
                                <div className="total mt-2 col-12 d-flex justify-content-center">
                                    <div className="col-12">
                                        <div className="subtotal">
                                            <div>Subtotal</div>
                                            <div>{prPrice.toFixed(2)}AZN</div>
                                        </div>
                                        <div className="promo-code-discount">
                                            <div>Promo kod endirimi</div>
                                            <div>0.00AZN</div>
                                        </div>
                                        <div className="cargo">
                                            <div>Çatdırılma</div>
                                            <div>0.00AZN</div>
                                        </div>
                                        <div className="edv">
                                            <div>ƏDV</div>
                                            <div>0.00AZN</div>
                                        </div>
                                        <div className="total">
                                            <div>Total: </div>
                                            <div>{prPrice.toFixed(2)}AZN</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='payment-details col  mt-3'>
                        <h1 className='p-0 m-0'>Ödəniş və çatdırılma</h1>
                        <div className="d-flex justify-content-center">
                            <div className="user-info  col-12 ">
                                <p className='user-info-header'>
                                    Şəxsi məlumatlar
                                </p>
                                <p className='user-info-subheader'>
                                    Məlumatlarınızı daxil edərək sifarişi rəsmiləşdirin
                                </p>
                                {
                                    userInfo.map(placeholder =>
                                        placeholder.slug === 3 ?
                                            <div className='col-12 d-flex justify-content-center align-items-center pb-2'>
                                                <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>
                                                    <span className='const' style={{ marginTop: 1 }}>+994</span>
                                                    <input autoComplete='off' ref={u_phone} maxLength={9} type='tel' placeholder='xx xxx xx xx' style={{ outline: 'none', border: 'none', height: 65, width: '100%', padding: 0, paddingLeft: 5, margin: 0 }}></input>

                                                </div>

                                            </div> :
                                            (
                                                <div key={placeholder.slug} className="d-flex col-12 pt-3 justify-content-center">
                                                    <label htmlFor="inp" className="inp inp-ci">
                                                        <input autoComplete='off' ref={placeholder.ref} type="text" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                                                        <span className="label">{placeholder.text}</span>
                                                    </label>
                                                </div>
                                            )
                                    )
                                }
                                {/* <div className="d-sm-flex d-block col-12 pt-3 justify-content-center">
                                    <div className="d-flex ch-inp ">
                                        <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>

                                            <Flatpickr ref={fp_date} onChange={([date]) => {
                                                setU_Date(date.toJSON().split("T")[0])
                                            }} style={{ border: "none", outline: 0 }} options={{ dateFormat: 'd.m.y', minDate: today, defaultDate: today }} />
                                            <img style={{cursor:'pointer'}} src={Calendar} alt="tarix" onClick={() => {
                                                fp_date.current.flatpickr.open();
                                            }} />
                                        </div>
                                    </div>
                                    <div className="d-flex ch-inp mt-sm-0 mt-3">
                                        <div style={{ border: '1px solid rgba(0, 0, 0, 0.04)', height: 70, borderRadius: 8 }} className='col-12 d-flex justify-content-center align-items-center'>

                                            <Flatpickr ref={fp_time} onChange={([hour]) => {
                                                const time = hour.toString().split(" ")[4].split(":")
                                                const real_hour = time[0];
                                                const minute = time[1]
                                                const result = real_hour + ":" + minute
                                                setU_Time(result)
                                            }} style={{ border: "none", outline: 0 }} options={{ defaultDate: '12:00', dateFormat: "H:i", enableTime: true, time_24hr: true, noCalendar: true }} />
                                            <img style={{cursor:'pointer'}} src={Clock} alt="saat"  onClick={()=>{
                                                fp_time.current.flatpickr.open()
                                            }}/>

                                        </div>
                                    </div>
                                </div> */}
                                <div className="d-flex col-12 pt-3 justify-content-center">
                                    <label htmlFor="inp" className="inp inp-ci">
                                        <input autoComplete='off' type="text" ref={u_notes} id="inp" className="form-input col-12" placeholder="&nbsp;" />
                                        <span className="label">Əlavə qeydlər</span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 p-0 m-0 d-flex justify-content-center align-items-center">
                            <button className="btn col-12 p-0 save-payment" onClick={() => {
                                recieveBook()
                            }}>
                                Sifarişi təsdiqlə
                            </button>
                        </div>
                        <div className="col-12 p-0 m-0 d-flex justify-content-center align-items-center">
                            <button className="btn col-12 save-payment p-0 sub-py-ss" onClick={() => {
                                navigate('/basket')
                            }}>
                                Əvvəlki səhifəyə qayıdın
                            </button>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Payment