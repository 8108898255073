import React from 'react'
import IMAGES from '../../helpers/images/images';
import { MainContext, useContext } from '../Context';
function DirectGlobalMsg({msg,state}) {
    const{close,success,Cancel} =IMAGES
    const { directGlobalMsg,setDirectGlobalMsg } = useContext(MainContext)
  return (
    <div>
        <div className='modal'>
            <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                <div className='close d-flex justify-content-center mt-3' onClick={() => {
                    setDirectGlobalMsg(!directGlobalMsg)
                }}><div><img alt='' src={close} /></div></div>
                <div className='col-12 p-0 m-0 d-flex justify-content-center'>
                    <div className='col-12 pb-5 '>
                        <div className='d-flex col-12 justify-content-center pt-3 pb-3'>
                            <div className='--bg-success d-flex col-12 justify-content-center'><img alt='' src={state === 1 ? success : Cancel} /></div>
                        </div>
                        <div className='mb-4'>
                            <div className='na-text col-12 pt-3 d-flex justify-content-center pt-2'>{msg && msg || 'Isteyiniz qəbul olundu!'}</div>
                        </div>
                    </div>
                </div>

            </section>


        </div>
    </div>
  );
}

export default DirectGlobalMsg