import React, { useState } from "react";
import IMAGES from "../../../helpers/images/images";

function Modal({ setModalOpen, orderId ,loading}) {
  const [orderDetail, setorderDetail] = useState(orderId);
  const { close } = IMAGES;

  return (
    <div>
      <div
        className="modal"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <section className="col-md-8 col-sm-8 col-lg-5 p-3  col-10 col-sm-9 --nsads">
          <div className="close d-flex justify-content-center">
            <div
              onClick={() => {
                setModalOpen(false);
              }}
            >
              <img alt="" src={close} />
            </div>
          </div>
              <div className="d-flex justify-content-center mb-3">
                <h4 className=" bold-text">Sifariş Detalları</h4>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr className="text-center">
                    <th scope="col">Şəkil</th>
                    <th scope="col">Məhsul adı</th>
                    <th scope="col">Məhsul sayı</th>
                    <th scope="col">Toplam qiymət</th>
                  </tr>
                </thead>
                <tbody key={orderDetail}>
                  {orderDetail &&
                    orderDetail.map((products) => {
                      return (
                        <>
                          <tr className="text-center">
                            <th scope="row">
                              <img
                                src={products.product_image}
                                style={{
                                  width: "120px",
                                  height: "110px",
                                }}
                              />
                            </th>
                            <td className="pt-5">{products.product_name}</td>
                            <td className="pt-5">{products.product_count}</td>
                            <td className="pt-5" style={{ color: "red", fontWeight: "bold" }}>{products.total_price} Azn</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              {/* <div className="mb-3">
                <div className="na-text pt-3 pt-2">
                  <div key={orderDetail} className="pt-2 pb-2 ">
                    <div className="w-100">
                      {orderDetail &&
                        orderDetail.map((products) => {
                          return (
                            <>
                              <div key={products.id} className="pt-2">
                                <div className="row">
                                  <div className="col-md-12 d-flex  justify-content-around align-items-center">
                                    <div className="books-img mt-1">
                                      <img
                                        src={products.product_image}
                                        style={{
                                          width: "130px",
                                          height: "120px",
                                        }}
                                      />
                                    </div>
                                    <div className="books-name mt-1 text-center">
                                      <span className="in-cl mr-2">
                                        {products.product_name}
                                      </span>
                                    </div>
                                    <div className="books-count mt-1 text-center">
                                      <span className="in-cl mr-2">
                                        {products.product_count}
                                      </span>
                                    </div>
                                    <div className="books-price mt-1 text-center">
                                      <span
                                        className="in-cl mr-2"
                                        style={{ color: "red" }}
                                      >
                                        {products.total_price} Azn
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div> */}
        </section>
      </div>
    </div>
  );
}

export default Modal;
