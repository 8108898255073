import React, { useEffect, useState } from 'react'
import AddressEdit from '../../../assets/img/AddressEdit.svg'
import AddressDelete from '../../../assets/img/AddressDelete.svg'
import AddAddress from '../../../assets/img/AddAddress.svg'
import DropDownArrow from '../../../assets/img/DropDownArrow.svg'
import close from '../../../assets/img/close.svg'
import NewAddress from '../../Modals/NewAddress'
import { MainContext ,useContext} from '../../Context'
import Authservice from '../../../api/auth/index'
function MyAddress() {
    const {addressModal, setAddressModal,readdress} = useContext(MainContext)
    const [dropdownActive,setDropdownActive] = useState(false)
    const getAllAddresses=()=>{
        Authservice.getAllAddresses(cookie).then(r=>setAdresses(r.data.data))
    }
    const {editAddressModal,setAdresses,setEditAddressModal,setAddressDetails,adresses,cookie} = useContext(MainContext)
    const addnewAddress = () => {
        setAddressModal(!addressModal)
    }
    const deleteAddress=(id)=>{
        
        Authservice.deleteUserAddress(id,cookie).then(re=>{
             const x =getAllAddresses()
             

        }).catch(er=>{
        })
    }
   useEffect(()=>{
    
    getAllAddresses()
   },[])
   useEffect(()=>{
       getAllAddresses()
   },[readdress])
   useEffect(()=>{
    
   },[adresses])
   const addModal =(info)=>{
       
        setEditAddressModal(!editAddressModal)
        const addressData = {
            address_name:info.name,
            address_surname : info.surname,
            address_phone:info.phone,
            address_props : info.address,
            address_id : info.id,
            address_title : info.title
        }
        setAddressDetails(addressData)
    }
    return (
        <>
            <div className=' col-md-8   col-12   ml-0 ml-md-5   myAddress _b-radius'>
                <div className="d-flex col-12 mt-3 justify-content-center">
                    <input type="text" disabled value={'Ünvanlarım'} className="col-12 mt-3" />
                </div>
                {addressModal ? <div className='modal'>
                    <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                        <div onClick={() => { setAddressModal(!addressModal) }} className='close d-flex justify-content-center mt-3'><div><img alt="" src={close} /></div></div>
                        <NewAddress />
                    </section>
                </div> : ''}
{/*                 
                */}
                <div className='col-12 mt-3'>
                    {adresses.map(addressInfo=>{
                        return (
                            <div key={addressInfo.id} className='col-12 address-container  bordered'>
                            <section>
                                <div className='address-info'>
                                    <div className='address-type  pt-1'>{addressInfo.title}</div>
                                    <div className='address-name pt-1'>{addressInfo.address}</div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img alt='' src={DropDownArrow} className='dropDownAddressImg' onClick={()=>{
                                       
                                       if(dropdownActive === `dropdown-${addressInfo.id}`){
                                           return setDropdownActive(false)
                                       }
                                       setDropdownActive(`dropdown-${addressInfo.id}`)

                                       
                                       
                                       }} />
                                    <div className='address-actions  d-flex align-items-center '>
                                <div onClick={()=>{
                                    addModal(addressInfo)
                                }} className="address-action-edit d-flex align-items-center justify-content-center"><img src={AddressEdit} alt='Edit' /></div>
                                <div className="address-action-delete d-flex align-items-center justify-content-center" onClick={()=>{
                                    deleteAddress(addressInfo.id)
                                }}><img src={AddressDelete} alt='Delete' /></div>
                            </div>
                                    
                                </div> 
                            </section>
                            <div className={ dropdownActive===`dropdown-${addressInfo.id}` ?  ' col-12 d-flex  align-items-center justify-content-center pb-2 pt-3 ': 'address-sm-actions'} >
                                <div onClick={()=>{
                                    addModal(addressInfo)  
                                }} className="card-action-edit-text d-flex align-items-center justify-content-center">Düzəlt</div>
                                <div className="card-action-delete-text d-flex align-items-center justify-content-center" onClick={()=>{
                                    deleteAddress(addressInfo.id)
                                }}>Sil</div>
                            </div>
                    </div>
                        )
                    })}
                </div>
                        


                <div className='col-12 mt-4'>
                    <div className="col-12 d-flex align-items-center  justify-content-center --add-n-address" onClick={addnewAddress}>
                        <img  src={AddAddress} className='pr-3' alt='add address' /><div>Yeni ünvan əlavə et</div>

                    </div>
                </div>
                <div className="d-flex col-12 mt-3 pb-3 justify-content-center">
                    <button className="--address-edit-btn btn btn-success col-12">Dəyişiklikləri yadda saxla</button>
                </div>
            </div>


        </>
    )
}

export default MyAddress
