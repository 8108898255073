import React, { useRef, useState } from 'react';
import { MainContext, useContext } from '../Context';
import close from '../../assets/img/close.svg'
import AuthService from "../../api/auth/index"
function ChangePassword({token}) {
    const { changePassword, setChangePassword } = useContext(MainContext);
    const new_pass = useRef();
    const re_new_pass = useRef();
   const [msg,setMSG] = useState("")
   const styleJS= {
       fontSize:20,
       color:"#ed2939",
       fontWeight:'bold',
       fontFamily:'Arial,Times New Roman',
       fontStyle:'italic'
   }
    const changePass =()=>{
        if(new_pass.current.value.trim()==="" || re_new_pass.current.value.trim()==="" ){
            setMSG("Bütün xanaların doldurulduğundan əmin olun.");
            return false
        };

        if(new_pass.current.value.trim() !== re_new_pass.current.value.trim() ){
            setMSG("Şifrələr eyni deyil!");
            return false
        }
        if(new_pass.current.value.trim()<8){
            setMSG("Şifrə minimum 8 simvoldan ibarət olmalı.");
            return false
        }
        const config = {
            "password": new_pass.current.value.trim(),
            "confirmPassword": re_new_pass.current.value.trim()
        }
        AuthService.resetEmailedPass(token,config).then(resp=>{
              if(resp.status===200){
                
                setMSG('Şifrə Dəyişdirildi.')

                window.location.href='/'
              }
          }).catch(er=>{
              setMSG('Xəta baş verdi.')
          })
    }
    return <div>
        <div className='modal'>
            <section className='col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads'>
                <div className='close d-flex justify-content-center mt-3'><div onClick={() => { 
                    if(!token){
                        setChangePassword(!changePassword) 
                    }
                    else{
                        window.location.href ='/'
                    }
                    }
                    
                }><img alt='' src={close} /></div></div>
                <div className='na-text pt-5  p-3'>Şəxsi kabinetə giriş</div>
                <div className='na-sub-text  p-3'>E-mail adresinizə şifrənin bərpası üçün uyğun link göndəriləcək</div>
                <div className='text-center' style={styleJS}>{msg}</div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <label htmlFor="inp" className="inp inp-ci">
                        <input ref={new_pass} autoComplete='new-password' type="password" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                        <span className="label">Şifrəniz</span>
                    </label>
                </div>
                <div className="d-flex col-12 pt-3 justify-content-center">
                    <label htmlFor="inp" className="inp inp-ci">
                        <input ref={re_new_pass} autoComplete='new-password' type="password" id="inp" className="form-input col-12" placeholder="&nbsp;" />
                        <span className="label">Şifrənin təkrarı</span>
                    </label>
                </div>
                <div className="d-flex mt-3 pb-4  col-12 justify-content-center">
                    <button className="--naddress-edit-btn col-10  btn btn-success" onClick={() => { changePass() }}>Daxil Ol</button>
                </div>
            </section>


        </div>
    </div>
}

export default ChangePassword;
