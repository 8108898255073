import greenShop from './src/img/greenShop.png'
import shopLight from "../Navbar/src/img/ShoppingLight.svg";
import React, {  useState } from 'react'
import './src/css/style.css'
import { MainContext,useContext } from '../../Context';
import {  useNavigate } from 'react-router-dom';
function TopOrder({props,name,imgurl,id,short_description,price,old_price,is_on_sale}){
    const {setAddBasketModal,addBasketModal,setBasketProps,setChangeFetch} = useContext(MainContext)
    const [img, setImg] = useState(greenShop);
    const navigate =useNavigate()
    const mystyle ={
        height:"60px",
        width:"60px",
        }
    return(
        <div className="d-flex justify-content-center my-5" >
           <div className="product-img p-2 " style={{ borderRadius:8,outline:"none",border:"none",height:"408px",maxWidth:"264px",background:"#fff"}}>
               <img  onClick={()=>{
                   setChangeFetch(id)
                   navigate('/oneProduct/'+id)
               }} src={imgurl} style={{cursor:'pointer'}}  width={250} height={250} className="mb-2 bskmgmn p-4" alt="foto"></img>
               <div className='bskmgnf pl-2'>
               <p style={{fontSize:"14px"}}>{name}</p>
               <p className='text-danger  mt-1' style={{fontSize:"14px"}}>{props.short_description}</p>
               </div>
               <div className="d-flex justify-content-between align-items-end pl-2">
                   <p className='font-weight-bold  mb-1' style={{fontSize:"19px"}}>{Number.parseFloat(price/100).toFixed(2)} AZN</p>
                    {
                        is_on_sale === true && (
                            <del className='mb-1 mt-1 text-danger' style={{fontWeight:"500", textDecoration: "line-through"}} >{Number.parseFloat(old_price/100).toFixed(2)} Azn</del>
                        )
                    }
                   <div
                   onMouseOver={()=>{
                        setImg(shopLight);
                       }}
                    onMouseOut={()=>{
                        setImg(greenShop);
                       }}
                    onClick={()=>{
                        setBasketProps(props)

                        setAddBasketModal(!addBasketModal)
                    }}
                    className="btn d-flex justify-content-center align-items-center rounded-circle mr-2  orderBg-0122" style={mystyle}  >
                       <img src={img}  width={21} height={21} alt="foto" className=""></img>
                   </div>
               </div>
           </div>
       </div>
    )
}
export default TopOrder