const IMAGES = {
    close :require('../../assets/img/close.svg').default,
    simpleProduct :require('../../assets/img/Rectangle 33.svg').default,
    success:require('../../assets/img/success.svg').default,
    showOnMap : require('../../assets/img/showonmap.svg').default,
    DateTime :require('../../assets/img/DateTime.svg').default,
    Calendar :require ('../../assets/img/Calendar.svg').default,
    Location:require('../../assets/img/Location.svg').default,
    Phone:require('../../assets/img/PhoneCircle.svg').default,
    Letter:require('../../assets/img/Letter.svg').default,
    TrashBox:require('../../assets/img/TrashBox.svg').default,
    InfoSym:require('../../assets/img/InfoSym.svg').default,
    Cancel:require('../../assets/img/icons8-cancel.svg').default
}
export default IMAGES