import React, { useEffect } from "react";
import navBrand from "./src/img/LogoLight.svg";
import toggleWhite from "./src/img/togglewhite.svg";
import personLight from "./src/img/PersonLight.svg";
import phoneLight from "./src/img/PhoneLight.svg";
import shopLight from "./src/img/ShoppingLight.svg";
import mobNavX1 from './src/img/mobNavX1.svg'
import { useState } from "react";
import { MainContext, useContext } from "../../Context";
import { Link } from 'react-router-dom';
import Authservice from '../../../api/auth/index'
import "./src/css/style.css"
function Navbar() {
  const langs = ['AZE', 'ENG', 'RUS']
 
  const [navIsActive, setNavIsActive] = useState(false);
  const [userAuth,setUserAuth] = useState(null)
  const { callOrderModal, pageLangData,setPageLangData,setCallOrderModal, login, setLogin,basketLength,setBasketLength, pageLanguage, setPageLanguage, dash, basketModal, setBasketModal } = useContext(MainContext)
  const navMenu = [
    {
      id: 1,
      name:  pageLangData.home ,
      slug: "home",
    },
    {
      id: 2,
      name: pageLangData.products,
      slug: "products",
    },
    {
      id: 3,
      name: pageLangData["about-us"],
      slug: "about",
    },
    {
      id: 4,
      name: pageLangData.contact,
      slug: "contact",
    },

  ]; 
  
  useEffect(()=>{
    const isAuth = Authservice.checkCookie()
    setUserAuth(isAuth)
  },[])
  let min = 0
  if (userAuth && min===0) {
   try {
    navMenu.push({
      id: 5,
      name: pageLangData.dashboard || "Dashboard",
      slug: "dashboard",
    })
   } catch (error) {
     
     
   }
   finally{
     min=min+1
   }
  }
  const getLangData=()=>{
    Authservice.getLangData(pageLanguage.slice(0, -1).toLowerCase() )
    .then(r=>{setPageLangData(r.data)
    })
    .catch(err=>{})
  }
  useEffect(()=>{
    getLangData()
  },[pageLanguage])
  useEffect(()=>{
    getLangData()
  },[])
  useEffect(()=>{
    const sessionId = Authservice.getSessionId()
    Authservice.showAllBasketProducts(sessionId).then(r=>setBasketLength(r.data.length))

  })
  const callOrder = () => {

    setCallOrderModal(!callOrderModal);

  }

  return (
    <nav
      className="">
      <div className="navborder d-flex align-items-strech justify-content-around text-light ">
        <Link to="/home">
          <img
            src={navBrand}
            alt="Logo"
            className="navlogoborder my-3 pl-lg-2 pr-sm-4 pr-3 "
            height="72px"
          />
        </Link>

        <div
          className="d-none d-lg-flex align-items-center"
          style={{ fontSize: "15px" }}
        >
          {pageLangData["order-2121"] !== undefined ? pageLangData["order-2121"].split("*")[0]  : " " }{" "}
          
          <h6 className=" m-0 pl-2" style={{ fontSize: "20px" }}>
              *2121
          </h6>
        </div>

        <ul className=" d-none d-lg-flex list-unstyled m-0 align-items-stretch  ">
          {navMenu.map((item) => (
            <li key={item.slug}
              className="mx-2 btn hoverNav   p-0 navhover d-flex align-items-center"
              style={{ fontSize: "15px" }}
            >
              <h5 style={{ fontSize: "15px" }}>
                <Link className="text-decoration-none hoverNav  d-flex align-items-center h-100  " to={"/" + item.slug}>
                  {item.name}
                </Link>
              </h5>
            </li>
          ))}
        </ul>

        <div className="d-flex my-auto ">
          <div
            className={`dropdown mr-5 d-lg-block ${!navIsActive && 'd-none'}`}
            id="dropdownMenuLink"
          >
            <a
              className="btn border text-light p-3 dropdown-toggle d-flex justify-content-center align-items-center"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
              defaultValue={'AZE'}
            >
              <h6 className="langtext">
              {pageLanguage}
              </h6>
            </a>

            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuLink"
            >
              {langs.map(language => {
                return (
                  <a key={language} className="dropdown-item" href="#" onClick={() => {
                    setPageLanguage(language)
                  }}>
                    {language}
                  </a>
                )
              })}
            </div>
          </div>

          <div className="p-2 px-3 border px-2 mr-2 btn d-none d-lg-block">
          {
            userAuth ? "" : <img
              className="mt-2"
              src={personLight}
              alt=""
              width={18}
              height={18}
              onClick={() => {
                setLogin(!login)
              }}
            />
          }
          </div>
          {!navIsActive && (
            <div style={{
              position:'relative'
            }}>
              <div className="el-center" style={{
                background:'#ED2939',
                borderRadius:999,
                color:"#FFF",
                width:15,
                height:15,
                position:'absolute',
                top:8,
                right:8,
                fontSize:'8px'
                
              }}>{basketLength}</div>
              <img 
              className="bas-itm border px-3 btn p-3"
              src={shopLight}
              alt=""
              id="shopLink"
              onClick={()=>{ 
                setBasketModal(!basketModal)
              }}
            />
            </div>
          )}
          <img
            className={`border rounded px-3 mr-2 ${navIsActive ? "px-3" : "px-2"} btn d-block d-lg-none  mx-2`}
            src={navIsActive ? mobNavX1 : toggleWhite}
            alt=""
            id="miniNav"
            onClick={(e) => setNavIsActive(!navIsActive)}
          />
        </div>

        <div
          className="d-none d-lg-flex  btn align-items-center py-2 my-auto"
          style={{ backgroundColor: "#BB221A", height: "49px" }}
          onClick={() => callOrder()}
        >
          <img
            className="px-3 py-1  rounded-3"
            src={phoneLight}
            style={{ backgroundColor: "#A8221B" }}
            alt=""
          />
          <h6 className="text-light m-0 px-2"  style={{ fontSize: "13px" }}> Zəng sifarişi</h6>
        </div>
      </div>
      <div></div>

      <div
        id="navDrop"
        className={`mx-4 px-1 mt-1 d-lg-none ${!navIsActive && "d-none"}`}
        style={{ minHeight: "calc(100vh - 72px)" }}
      >
        <div className=" mx-2 mt-3 py-2 d-flex   justify-content-between text-light">
          <div className="bas-itm border btn py-2 d-flex col mr-2 text-center" onClick={() => {
            setBasketModal(!basketModal)
          }}>
            <img
              className="bas-itm px-2 py-1 mmbasket  rounded"
              src={shopLight}
              witdh={20}
              alt=""
            />
            <div className="d-flex flex-fill  justify-content-center algin-items-center">
              <div className="text-light m-0  "> Səbətim</div>
            </div>
          </div>
          {userAuth ? "" : <div className="border btn py-2 d-flex col text-center" onClick={() => {
            setLogin(!login)
          }}>
            <img
              className="px-2 py-1  rounded "
              src={personLight}
              witdh={20}
              alt=""
            />
            <div className="d-flex flex-fill  justify-content-center algin-items-center">
              <div className="text-light m-0 ">Daxil ol </div>
            </div>
          </div>
          }
        </div>
        <div
          className="  d-flex btn align-items-center  py-2  text-center mx-2 my-3"
          style={{ backgroundColor: "#BB221A" }}
        >
          <img
            className="px-3 py-1 btn  "
            style={{ backgroundColor: "#A8221B" }}
            src={phoneLight}
            alt=""
          />
          <div className="d-flex flex-fill  justify-content-center" onClick={() => callOrder()}>
            <div className="text-light m-0 px-2 ">Zəng sifarişi et</div>
          </div>
        </div>

        <ul className=" d-flex flex-column justify-content-end align-items-start list-unstyled mx-2 mt-4 ">
          {navMenu.map(item => (
            <li className=" btn text-light py-2 p-0 " key={item.id}>
              <Link className="text-decoration-none text-light" to={'/' + item.slug}>{item.name}</Link>
            </li>
          ))}

        </ul>

        <div className="text-light mt-5 pt-5 pb-2 mx-2 py-2 d-flex align-items-center border-top">
          Sifariş üçün:
          <p className="ml-2 my-0 text-danger font-weight-bold h4">*2121</p>
        </div>
      </div>

      {/* <Slider props={navMenu} ></Slider> */}
    </nav>
  );
}

export default Navbar;
