import React, { useRef } from "react";
import { MainContext, useContext } from "../Context";
import close from "../../assets/img/close.svg";
import success from "../../assets/img/success.svg";
function EmailSent() {
  const { emailSent, setForgotEmail, setEmailSent } = useContext(MainContext);

  return (
    <div>
      <div className="modal">
        <section className="col-md-8 col-sm-8 col-lg-5  col-10 col-sm-9 --nsads">
          <div
            className="close d-flex justify-content-center mt-3"
            onClick={() => {
              setEmailSent(!emailSent);
              setForgotEmail(false);
            }}
          >
            <div>
              <img alt="" src={close} />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div className="col-12 pb-5 ">
              <div className="d-flex col-12 justify-content-center pt-3 pb-3">
                <div className="--bg-success d-flex col-12 justify-content-center">
                  <img alt="" src={success} />
                </div>
              </div>
              <div className="pb-4">
                <div className="na-text col-12 pt-3 d-flex justify-content-center pt-2">
                  Şifrənizin bərpası üçün link göndərildi
                </div>
                <div className="na-sub-text col-12 pt-3  d-flex justify-content-center pt-2">
                  E-mail adresinizə göndərilən müvafiq link vasitəsi ilə
                  şifrənizi yeniləyə bilərsiniz{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      ;
    </div>
  );
}

export default EmailSent;
