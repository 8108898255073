import React, { useEffect, useState } from 'react'
import DownloadInvoice from '../../../assets/img/DownloadInvoice.svg'
import { MainContext, useContext } from '../../Context'
import { useNavigate } from 'react-router-dom'
import AuthService from "../../../api/auth/index"
import Modal from "../MyBooks/BooksDetail"

function MyOldBooks() {
    const navigate =useNavigate()
    const [oldBooks, setOldBooks] = useState([])
    const [modalOpen, setModalOpen] = useState (false)
    const [orderId, setOrderId] = useState (false)
    const { setBillPage} = useContext(MainContext);
    const cookie = AuthService.getCookie()[0]
    const [loading,setLoading ] = useState(false)
    const [responseProducts, setresponseProducts] = useState (false)
    
    useEffect(() => {
        setLoading(true)
        AuthService.getUserCurrentOrders(cookie).then(r => {
            const response = r.data.data;
            return response
        })
            .then(response=>{
                const booksArr =response.filter(resp=>resp.order_status !== "Gözləyir")
                setOldBooks(booksArr)
                setLoading(false)

            })
        .catch(r=>{
            if(r.response.status === 401 ){
                AuthService.deleteCookie();
                window.location.href = '/'
            }
        })
    }, [])


    const arrange_products=(products)=>{
        const obj = {};
        products.forEach((item) => {
          if (!obj[item.id]) obj[item.id] = [];
          obj[item.id].push(item);
        });
  
        const result = [];
        for (const item in obj) {
          const product_count = obj[item].length;
          const product_name = obj[item][0].name;
          const product_image = obj[item][0].image.image_small;
          const total_price = obj[item].reduce(
            (total_price, elem) => total_price + elem.price.price,
            0
          );
          result.push({
            product_count,
            product_name,
            product_image,
            total_price,
          });
        }
        return result;
      }

      const getOrderDetail = async (e, id) => {
        e.preventDefault();
  
        setModalOpen(true);
        setresponseProducts([]);
        const response = await AuthService.getUserCurrentOrderDetail(cookie, id);
        const arranged_products = arrange_products(response?.data?.data.order_products);
        setresponseProducts(arranged_products);
        setModalOpen(false)
        setModalOpen(true);
      }; 
    
    const getBill=(e,id)=>{
        e.preventDefault();
        setBillPage("Əvvəlki sifarişlərim")
        navigate(`/bill/${cookie}/${id}`)

    }
    return (
        <div className='col-lg-8 col-md-8 ml-0 ml-md-5 col-sm-12 col-12  _b-radius  myBook'>
            <div className="d-flex col-12 mt-3 justify-content-center">
                <input type="text" disabled value={'Əvvəlki sifarişlərim'} className="col-12 mt-3" />
            </div>
            {loading  ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : ""}
            {
                oldBooks && oldBooks.map(books => {
                    return (
                        <div key={books.id} className="book-box mt-5 d-md-flex border pt-2 pb-2 ">
                        <div className="d-flex pb-1">
                        <div className="d-block">
                                <div className="books-info offset-1 w-100 pt-3">
                                    <div className="books-name"><span className="in-cl mr-2">Sifariş kodu:</span>{books.order_no}</div>
                                    <div className="books-date mt-1"><span className="in-cl mr-2">Sifaris tarixi: </span>{books.order_date.split('T')[0]}</div>
                                    <div className=" total-amount mt-1"><span className="in-cl mr-2">Status:</span>{books.order_status}</div>
                                    <div className="books-number mt-1"><span className="in-cl mr-2">Say:</span>{books.count}</div>
                                    <div className="d-sm-flex d-lg-flex d-md-flex d-block">
                                        <div className=" total-amount mt-1"><span className="in-cl mr-2">Ümumi məbləğ:</span><span  style={{color: "red"}}>{books.price} Azn</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center pb-3 mt-3 ml-3 pl-5" >
                            <div className='col-11 col-md-12 offset-md-12'>
                                <div className="books-state" style={{ cursor: 'pointer' }} 
                                    onClick={(e)=>{
                                        getOrderDetail(e,books.id); }}
                                >Sifariş Detalları</div>
                                {modalOpen  && <Modal setModalOpen={setModalOpen} orderId={responseProducts}/>}
                                <div onClick={(e)=>{
                                    getBill(e,books.id);
                                }} className="books-invoice mt-3 d-flex" style={{ cursor: 'pointer' }}><img src={DownloadInvoice} className='pr-2' alt="" /> Qəbzi yüklə</div>
                               
                            </div>
                        </div>
                    </div>
                    )
                })
            }
        </div>
    )
}

export default MyOldBooks